import { Link } from 'react-router-dom';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Text from '@/components/common/atoms/Text';
import { UsageData } from '@/types/common';

const StyledText = styled(Text)`
	font-size: 14px;
	color: ${props => props.theme['gray-9']};
`;

const StyledButton = styled(Button)`
	color: ${props => props.theme['link']};
`;

export const covertDataToTableData = (data: UsageData, userRowTitle = '사용자 수', useRowTitle = '열람 횟수') => {
	/* data가 빌 때는
    {
        "use": {},
        "user": {}
    }
    */
	const checkIsEmptyObject = (item: object) => Object.keys(item).length === 0;
	if (data.user && data.use && !checkIsEmptyObject(data.use) && !checkIsEmptyObject(data.user)) {
		return [
			{
				xAxisTitle: userRowTitle,
				...data.user,
			},
			{
				xAxisTitle: useRowTitle,
				...data.use,
			},
		];
	} else {
		// loading시에 배경으로 보여줄 레이아웃
		// return new Array(2).fill({});
		return [];
	}
};

export const createCheckDetailTableColumns = () => [
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
		render: (value: string) => <StyledText>{value}</StyledText>,
	},
	{
		title: 'To',
		dataIndex: 'to',
		key: 'to',
		// TODO : float: right보다 더 좋은게 있을지 체크
		render: (to: string) => (
			<Link to={to} style={{ float: 'right' }}>
				<StyledButton type={'link'}>조회하기</StyledButton>
			</Link>
		),
	},
];
