import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const StyledText = styled(Text)`
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
	color: ${props => props.theme['gray-9']};
`;
