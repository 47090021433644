import { useQuery } from '@tanstack/react-query';
import {
	JumpUpCampListDataQueryResult,
	JumpUpCampListDetailQueryResult,
	JumpUpCampSummaryQueryResult,
	JumpUpCampUserListDataQueryResult,
} from '@/types/eduCamp/jumpUp';
import {
	getJumpUpApplicantList,
	getJumpUpAttendanceAndAssignment,
	getJumpUpCampAssignment,
	getJumpUpCampList,
	getJumpUpCampSchedule,
	getJumpUpSatisfactionRequest,
	getJumpUpSummarySectionRequest,
} from '@/api/report/eduJumpUp';
import {
	AssignmentDataQueryResult,
	SatisfactionQueryResult,
	ScheduleDataQueryResult,
} from '@/types/eduCamp/campCommon';

export const useEduCampJumpUpSummaryQuery = (contractId: number) => {
	return useQuery<JumpUpCampSummaryQueryResult>(
		['edu', 'camp', 'JumpUp', 'summary', contractId],
		() => getJumpUpSummarySectionRequest({ contractNo: contractId }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampJumpUpSatisfactionQuery = (contractId: number, page: number) => {
	return useQuery<SatisfactionQueryResult>(
		['edu', 'camp', 'JumpUp', 'satisfaction', contractId],
		() => getJumpUpSatisfactionRequest({ contractNo: contractId, page }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampJumpUpUserListQuery = (contractId: number) => {
	return useQuery<JumpUpCampUserListDataQueryResult>(
		['edu', 'camp', 'JumpUp', 'user', contractId],
		() => getJumpUpApplicantList({ contractNo: contractId }),
		{
			onError: error => console.error(error),
			select: response => {
				response = {
					...response,
					data: {
						...response.data,
						list: response.data.list.map(data => {
							return {
								...data,
								state_detail: data.state_detail_custom ? data.state_detail_custom : data.state_detail,
							};
						}),
					},
				};
				return response;
			},
		},
	);
};

export const useEduCampJumpUpCampListQuery = (contractId: number) => {
	return useQuery<JumpUpCampListDataQueryResult>(
		['edu', 'camp', 'JumpUp', 'list', contractId],
		() => getJumpUpCampList({ contractNo: contractId }),
		{
			onError: error => console.error(error),
		},
	);
};

export const useEduCampJumpUpListDetailQuery = (applicantNo: number) => {
	return useQuery<JumpUpCampListDetailQueryResult>(
		['edu', 'camp', 'JumpUp', 'detail', 'applicant', applicantNo],
		() => getJumpUpAttendanceAndAssignment({ applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampJumpUpListScheduleQuery = (applicantNo: number) => {
	return useQuery<ScheduleDataQueryResult>(
		['edu', 'camp', 'JumpUp', 'detail', 'schedule', applicantNo],
		() => getJumpUpCampSchedule({ applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampJumpUpAssignmentQuery = (assignmentNo: number, applicantNo: number) => {
	return useQuery<AssignmentDataQueryResult>(
		['edu', 'camp', 'JumpUp', 'assignment', assignmentNo, applicantNo],
		() => getJumpUpCampAssignment({ assignment_no: assignmentNo, applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};
