import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { downloadCampAssignmentFile, downloadCampAssignmentReplyFile } from '@/api/report/eduCampCoupon';

const StyledAttachmentFileListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:not(:last-child) {
		margin-bottom: 12px;
	}
`;

const StyledAttachmentFileName = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-7']};
`;

const AttachmentFileItem = ({
	reply,
	fileName,
	fileNo,
}: {
	reply: undefined | null | boolean;
	fileName: string;
	fileNo: number;
	assignment: any;
}) => {
	const downloadAssignmentFile = async () => {
		const params = {
			file_no: fileNo,
		};
		const { data } = reply
			? await downloadCampAssignmentReplyFile(params)
			: await downloadCampAssignmentFile(params);
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${fileName}`); // or any other extension
		document.body.appendChild(link);
		link.click();
	};

	return (
		<StyledAttachmentFileListItem>
			<StyledAttachmentFileName>{fileName}</StyledAttachmentFileName>
			<DownloadOutlined
				style={{ fontSize: '20px', color: '#8C8C8C', cursor: 'pointer' }}
				onClick={downloadAssignmentFile}
			/>
		</StyledAttachmentFileListItem>
	);
};

export default AttachmentFileItem;
