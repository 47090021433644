import React from 'react';
import { Layout, Row, Col } from 'antd';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import SideMenu from '@/components/SideMenu';
import HeaderLayout from '@/layouts/HeaderLayout';
import DetailHeader from '@/components/Header/DetailHeader';
import ContentLayout from '@/layouts/ContentLayout';
import { mixin } from '@/styles/theme';
import { SIDE_MENU_WIDTH } from '@/utils/constants/common';

const StyledDetailHeaderLayout = styled(HeaderLayout)`
	background-color: white;
	${mixin.flexBox()};
	${mixin.justifyContent('space-between')};
	position: fixed;
	top: 0;
	width: calc(100% - ${SIDE_MENU_WIDTH});
	z-index: 10000;
`;

const DetailLayout = () => {
	return (
		<Layout style={{ minHeight: '100vh' }} hasSider>
			<SideMenu />
			<Layout style={{ marginLeft: `${SIDE_MENU_WIDTH}` }}>
				<StyledDetailHeaderLayout>
					<DetailHeader />
				</StyledDetailHeaderLayout>
				<ContentLayout>
					<Row>
						<Col span={22} offset={1}>
							<Outlet />
						</Col>
					</Row>
				</ContentLayout>
			</Layout>
		</Layout>
	);
};

export default DetailLayout;
