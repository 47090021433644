import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import ReportCampJumpUpSummarySection from '@/components/report/eduCampJumpUp/organisms/ReportCampJumpUpSummarySection';
import ReportCampJumpUpCheckDetailSection from '@/components/report/eduCampJumpUp/organisms/ReportCampJumpUpCheckDetailSection';
import ReportCampJumpUpSatisfactionSection from '@/components/report/eduCampJumpUp/organisms/ReportCampJumpUpSatisfactionSection';
import useContractId from '@/hooks/useContractId';
import { debounce } from 'lodash';
import {
	useEduCampJumpUpSatisfactionQuery,
	useEduCampJumpUpSummaryQuery,
} from '@/queries/report/eduCampJumpUp/useEduCampJumpUpQuery';
import {
	EDU_CAMP_JUMP_UP_SUMMARY_INITIAL_DATA,
	EDU_CAMP_SATISFACTION_INITIAL_DATA,
} from '@/utils/constants/eduCamp/eduCampCommon';

const EduCampJumpUpHome = () => {
	const [page, setPage] = useState<number>(1);
	const contractId = useContractId();
	const { data: JumpUpSummary, isLoading: JumpUpSummaryLoading } = useEduCampJumpUpSummaryQuery(contractId);
	const summaryData = JumpUpSummary?.data ?? EDU_CAMP_JUMP_UP_SUMMARY_INITIAL_DATA;
	const { data: JumpUpSatisfaction, isLoading: satisfactionLoading } = useEduCampJumpUpSatisfactionQuery(
		contractId,
		page,
	);
	const satisfactionData = JumpUpSatisfaction?.data ?? EDU_CAMP_SATISFACTION_INITIAL_DATA;
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();

	const changePage = useCallback(
		debounce((page: number) => {
			setPage(page);
		}, 3000),
		[],
	);

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportCampJumpUpSummarySection summaryData={summaryData} isLoading={JumpUpSummaryLoading} />
			<ReportCampJumpUpCheckDetailSection />
			<ReportCampJumpUpSatisfactionSection
				isLoading={satisfactionLoading}
				satisfactionData={satisfactionData}
				page={page}
				changePage={changePage}
			/>
		</DetailSectionContainer>
	);
};

export default EduCampJumpUpHome;
