import React from 'react';
import styled from 'styled-components';
import { nl2br } from '@/utils/string';
import { StyledComment } from '@/components/Mentoring/Log/Detail/MentoringLogDetailAnswer';

interface ContentBodyStyledProps {
	align?: 'left' | 'right';
	emphasized?: boolean;
}

interface ContentBodyProps extends ContentBodyStyledProps {
	contentBody: string;
}
const StyledContentBodyContainer = styled.div<ContentBodyStyledProps>`
	position: relative;
	margin-bottom: 16px;
	float: ${props => props.align || 'none'};
	font-weight: ${props => (props.emphasized ? 600 : 400)};
`;

const ContentBody = ({ align, emphasized, contentBody }: ContentBodyProps) => {
	return (
		<StyledContentBodyContainer emphasized={emphasized} align={align}>
			<StyledComment
				dangerouslySetInnerHTML={{
					__html: nl2br(contentBody),
				}}
			/>
		</StyledContentBodyContainer>
	);
};

export default ContentBody;
