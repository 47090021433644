import React from 'react';
import styled from 'styled-components';
import MentoringLogDetailQuestion from '@/components/Mentoring/Log/Detail/MentoringLogDetailQuestion';
import MentoringLogDetailAnswerCountInfo from '@/components/Mentoring/Log/Detail/MentoringLogDetailAnswerCountInfo';
import MentoringLogDetailAnswerList from '@/components/Mentoring/Log/Detail/MentoringLogDetailAnswerList';
import { MentoringLogDetailAnswerItem, MentoringLogDetailQuestionItem } from '@/types/mentoring/mentoring';

const StyledMentoringLogDetailQuestion = styled(MentoringLogDetailQuestion)`
	margin-bottom: 24px;
`;

interface MentoringDetailItem {
	question: MentoringLogDetailQuestionItem;
	answer: MentoringLogDetailAnswerItem[];
}

interface MentoringDetailQAProps {
	data: MentoringDetailItem;
	isLoading?: boolean;
}

const MentoringDetailQA = ({ data: { question, answer }, isLoading }: MentoringDetailQAProps) => {
	return (
		<>
			<StyledMentoringLogDetailQuestion question={question} isLoading={isLoading} />
			<MentoringLogDetailAnswerCountInfo answer={answer} isLoading={isLoading} />
			<MentoringLogDetailAnswerList answer={answer} isLoading={isLoading} type={question.type} />
		</>
	);
};

export default MentoringDetailQA;
