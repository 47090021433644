import { getCerficate } from '@/api/common';

export const openCertificate = async (applicantNo?: number) => {
	if (!applicantNo) {
		return;
	}

	const response = await getCerficate(applicantNo);
	const file = new Blob([response.data], { type: 'application/pdf' });
	window.open(URL.createObjectURL(file));
};
