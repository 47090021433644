import React, { ReactChild, ReactChildren, useContext } from 'react';
import styled from 'styled-components';
import { Button, Layout } from 'antd';
import { COMENTO_PATH } from '@/utils/constants/paths';
import { AuthContext } from '@/contexts/AuthProvider';
import { mixin } from '@/styles/theme';
import useHandleLogout from '@/hooks/useLogout';

interface childrenTypes {
	children: ReactChild | ReactChildren;
	className?: string;
	isGhostStyle?: boolean;
}

const HeaderLayout = ({ children, className, isGhostStyle = false }: childrenTypes) => {
	const { isLogin } = useContext(AuthContext);
	const handleLogout = useHandleLogout();

	return (
		<LayoutHeader className={className}>
			{children}
			<div style={{ display: 'flex' }}>
				<HeaderButton ghost={isGhostStyle}>
					<a href={`${COMENTO_PATH}`}>코멘토홈</a>
				</HeaderButton>
				{isLogin && (
					<HeaderButton ghost={isGhostStyle} onClick={handleLogout}>
						로그아웃
					</HeaderButton>
				)}
			</div>
		</LayoutHeader>
	);
};

export default HeaderLayout;

export const LayoutHeader = styled(Layout.Header)`
	padding: 0 24px;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
	${mixin.flexBox()}
`;

const HeaderButton = styled(Button)`
	margin: 16px 12px 16px auto;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-7']};
	&:last-child {
		margin-right: 0;
	}
`;
