import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import { useParams } from 'react-router-dom';
import { convertParamsIntoNumber } from '@/utils/params';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItem } from '@/components/common/organisms/InfoBoxWithTitle/types';
import { renderInfoContentWithPercent } from '@/components/common/organisms/InfoBoxWithTitle/utils';
import { useContentSummaryQuery } from '@/queries/report/contents/useContentsQuery';
import { CONTENT_SUMMARY_INITIAL_DATA } from '@/utils/constants/content';
import { ContentSummaryData } from '@/types/contents/contents';

const ReportContentSummarySection = () => {
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	const { data, isLoading } = useContentSummaryQuery(contractId);
	const summaryData: ContentSummaryData = data?.data ?? CONTENT_SUMMARY_INITIAL_DATA;

	const columnData = [
		{
			title: '등록자 수',
			tooltipContent: '코멘토 이용권을 등록한 사람 수 입니다.',
			key: 'registrantsUserCount',
		},
		{
			title: '사용자 수',
			tooltipContent: '등록자 수 중 코멘토 콘텐츠를 1회 이상 열람한 사용자의 총 인원 수 입니다.',
			key: 'usersCount',
		},
		{
			title: '총 열람 횟수',
			tooltipContent: '사용자가 열람한 모든 콘텐츠의 열람 횟수 입니다.',
			key: 'totalViewsCount',
		},
		{
			title: '사용률',
			tooltipContent: '등록자 수 대비 사용자 수의 비율입니다.',
			key: 'utilizationRate',
			render: renderInfoContentWithPercent,
		},
	];

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SUMMARY_SECTION_TITLE}</StyledSectionTitle>
			<StyledInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.FOUR_COLUMN}>
				{columnData &&
					columnData.map(({ title, tooltipContent, key, render }: InfoBoxWithTitleArrayItem) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={summaryData[key]}
							key={title}
							isLoading={isLoading}
							render={render}
						/>
					))}
			</StyledInfoBoxContainer>
		</section>
	);
};

export default ReportContentSummarySection;
