import React from 'react';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import { mixin } from '@/styles/theme';

const CAMP_PROGRESS = {
	PASS: 'blue-7',
	FAIL: 'volcano-6',
	SHOULD_SUBMIT: 'yellow600',
	NONE: 'gray-4',
};

const ProgressDot = styled.div<{ progress: string }>`
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: ${props => props.theme[props.progress]};
	&:not(:last-child) {
		margin-right: 4px;
	}
`;
const ProgressContainer = styled.div`
	${mixin.flexBox()};
	margin-left: 8px;
`;

const ProgressWrapper = styled.div`
	${flexAlignCenter}
`;

const StyledProgressText = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: ${props => props.theme['gray-7']};
`;

const CampProgressCard = ({
	isFreeSchedule,
	assignment,
	attendance,
	campWeek,
	isExternship,
	isPrivate,
	isCoupon,
	isJumpUp,
}: {
	assignment?: (boolean | number | null | undefined)[];
	attendance?: (boolean | number | null | undefined)[];
	campWeek?: number;
	isFreeSchedule?: boolean;
	isExternship?: boolean;
	isPrivate?: boolean;
	isCoupon?: boolean;
	isJumpUp?: boolean;
}) => {
	const dotDisplayCondition = (item: boolean | number | null | undefined, index: number) => {
		if (item === undefined || assignment === undefined) return;
		if (isFreeSchedule) {
			return index > 0;
		}
		return index < assignment?.length - 1;
	};

	const getProgress = (item: boolean | number | null | undefined): string => {
		const isPass = (item: boolean | number | null | undefined) => {
			if (isExternship) return Boolean(item);
			return Number(item) === 1;
		};
		const isFail = (item: boolean | number | null | undefined) => {
			return (isPrivate || isCoupon || isJumpUp) && Number(item) === 0;
		};

		const shouldAssignmentSubmit = (item: boolean | number | null | undefined) => {
			return isCoupon && Number(item) === 2;
		};
		if (shouldAssignmentSubmit(item)) return CAMP_PROGRESS.SHOULD_SUBMIT;
		if (isPass(item)) return CAMP_PROGRESS.PASS;
		if (isFail(item)) return CAMP_PROGRESS.FAIL;
		return CAMP_PROGRESS.NONE;
	};

	return campWeek ? (
		<div>
			<ProgressWrapper>
				<StyledProgressText>출석</StyledProgressText>
				<ProgressContainer>
					{isFreeSchedule ? (
						<ProgressDot progress={getProgress(!!attendance)} />
					) : (
						<>
							{Array.isArray(attendance) &&
								attendance.map((item, index) =>
									isJumpUp || (item !== undefined && index % 2 === 0) ? (
										<ProgressDot
											progress={getProgress(item)}
											key={`progress-attendance-${index}`}
										/>
									) : null,
								)}
						</>
					)}
				</ProgressContainer>
			</ProgressWrapper>
			<ProgressWrapper>
				<StyledProgressText>과제</StyledProgressText>
				<ProgressContainer>
					{assignment?.map((item, index) =>
						dotDisplayCondition(item, index) ? (
							<ProgressDot progress={getProgress(item)} key={`progress-attendance-${index}`} />
						) : null,
					)}
				</ProgressContainer>
			</ProgressWrapper>
		</div>
	) : null;
};

export default CampProgressCard;
