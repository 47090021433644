import { useUpdateHomeHeader } from '@/hooks/useUpdateHomeHeader';

import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import EduPathV2ReportCampCouponCheckDetailSection from '@/components/report/eduPathV2CampCoupon/organisms/ReportCampCouponCheckDetailSection';
import EduPathV2CampCouponSummarySection from '@/components/report/eduPathCampCoupon/organisms/EduPathV2CampCouponSummarySection';
import EduPathV2SatisfactionSection from '@/components/report/eduPathV2CampCoupon/organisms/EduPathV2SatisfactionSection';
import EduPathV2GraduateSection from '@/components/report/eduPathCampCoupon/organisms/EduPathV2GraduateSection';

const EduPathV2CampCouponHome = () => {
	// 계약 정보를 불러와서 Header update
	useUpdateHomeHeader();

	return (
		<DetailSectionContainer>
			<EduPathV2CampCouponSummarySection />
			<EduPathV2GraduateSection />
			<EduPathV2ReportCampCouponCheckDetailSection />
			<EduPathV2SatisfactionSection />
		</DetailSectionContainer>
	);
};

export default EduPathV2CampCouponHome;
