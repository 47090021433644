import React from 'react';
import styled from 'styled-components';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import DataTable from '@/components/common/organisms/DataTable';
import { PrivateApplicant, PrivateAssignment, PrivateAttendance } from '@/types/eduCamp/private';

const StyledText = styled.span`
	color: ${props => props.theme['gray-6']};
`;

const StyledTitle = styled.p`
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const StyledSection = styled.section`
	margin-bottom: 60px;
`;

const ReportCampExternshipDetailSummary = ({
	applicantData,
	isLoading,
}: {
	applicantData: PrivateApplicant;
	isLoading: boolean;
}) => {
	const getApplicantAttendance = (array: PrivateAssignment[] | PrivateAttendance[], week: number) => {
		if (!array) return '';
		const findWeek = [...array].some(item => item.week === week);
		return findWeek ? 'O' : 'X';
	};

	const data = [
		{
			xAxisTitle: '출석',
			'1차': getApplicantAttendance(applicantData?.attendance, 1),
			'2차': getApplicantAttendance(applicantData?.attendance, 2),
			'3차': getApplicantAttendance(applicantData?.attendance, 3),
		},
		{
			xAxisTitle: '과제',
			'1차': getApplicantAttendance(applicantData?.assignment, 1),
			'2차': getApplicantAttendance(applicantData?.assignment, 2),
			'3차': <StyledText>과제 없음</StyledText>,
		},
	];
	const keyList = Object.keys(data[0]);
	return (
		<StyledSection>
			<StyledTitleRowContainer>
				<StyledTitle>요약정보</StyledTitle>
			</StyledTitleRowContainer>
			<DataTable data={data} keyList={keyList} isLoading={isLoading} width={'178px'} />
		</StyledSection>
	);
};

export default ReportCampExternshipDetailSummary;
