import React from 'react';
import Title from '@/components/common/Typography/Title';
import { StyledCheckDetailTable } from '@/components/common/styledComponents';
import { createCheckDetailTableColumns } from '@/utils/table';
import { useNavigate, useParams } from 'react-router-dom';
import { dynamicRoutePath } from '@/utils/constants/paths';
import styled from 'styled-components';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';

interface DataSource {
	key: string;
	title: string;
	to: string;
}

export interface CheckDetailSectionProps {
	title: string;
	dataSource: Array<DataSource>;
}

const StyledSectionTitleContainer = styled.div`
	margin-bottom: 16px;
	${mixin.flexBox()}
	${mixin.flexDirection('column')};
	> * + * {
		margin-top: 4px;
	}
`;

const CheckDetailSection = ({ title, dataSource }: CheckDetailSectionProps) => {
	const navigate = useNavigate();
	const params = useParams();
	// TODO : record type 적기
	const handleClickRow = (record: any) => {
		return {
			onClick: () => {
				navigate(dynamicRoutePath(record.to, params));
			},
		};
	};
	return (
		<section>
			<StyledSectionTitleContainer>
				<Title>{title}</Title>
				<Text color={theme['gray-8']}>사용자의 상세한 사용이력을 조회하고 데이터를 다운받을 수 있습니다.</Text>
			</StyledSectionTitleContainer>
			<StyledCheckDetailTable
				dataSource={dataSource}
				columns={createCheckDetailTableColumns()}
				showHeader={false}
				pagination={false}
				bordered={true}
				onRow={handleClickRow}
			/>
		</section>
	);
};

export default CheckDetailSection;
