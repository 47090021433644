import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import ReportCampPrivateSummarySection from '@/components/report/eduCampPrivate/organisms/ReportCampPrivateSummarySection';
import ReportCampPrivateCheckDetailSection from '@/components/report/eduCampPrivate/organisms/ReportCampPrivateCheckDetailSection';
import ReportCampPrivateSatisfactionSection from '@/components/report/eduCampPrivate/organisms/ReportCampPrivateSatisfactionSection';
import useContractId from '@/hooks/useContractId';
import { debounce } from 'lodash';
import {
	useEduCampPrivateSatisfactionQuery,
	useEduCampPrivateSummaryQuery,
} from '@/queries/report/eduCampPrivate/useEduCampPrivateQuery';
import {
	EDU_CAMP_PRIVATE_SUMMARY_INITIAL_DATA,
	EDU_CAMP_SATISFACTION_INITIAL_DATA,
} from '@/utils/constants/eduCamp/eduCampCommon';

const EduCampPrivateHome = () => {
	const [page, setPage] = useState<number>(1);
	const contractId = useContractId();
	const { data: privateSummary, isLoading: privateSummaryLoading } = useEduCampPrivateSummaryQuery(contractId);
	const summaryData = privateSummary?.data ?? EDU_CAMP_PRIVATE_SUMMARY_INITIAL_DATA;
	const { data: privateSatisfaction, isLoading: satisfactionLoading } = useEduCampPrivateSatisfactionQuery(
		contractId,
		page,
	);
	const satisfactionData = privateSatisfaction?.data ?? EDU_CAMP_SATISFACTION_INITIAL_DATA;
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();

	const changePage = useCallback(
		debounce((page: number) => {
			setPage(page);
		}, 3000),
		[],
	);

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportCampPrivateSummarySection summaryData={summaryData} isLoading={privateSummaryLoading} />
			<ReportCampPrivateCheckDetailSection />
			<ReportCampPrivateSatisfactionSection
				isLoading={satisfactionLoading}
				satisfactionData={satisfactionData}
				page={page}
				changePage={changePage}
			/>
		</DetailSectionContainer>
	);
};

export default EduCampPrivateHome;
