import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
	InfoTitleWithInfoCircleContainer,
	StyledInfoTitle,
} from '@/components/common/molecules/InfoTitleWithInfoCircle/styled';
import theme from '@/styles/theme';
import { InfoTitleWithInfoCircleProps } from '@/components/common/molecules/InfoTitleWithInfoCircle/types';

export const INFO_CIRCLE_OUTLINED_TEST_ID = 'info-circle-outlined';

const InfoTitleWithInfoCircle = ({ title, tooltipContent, className }: InfoTitleWithInfoCircleProps) => {
	return (
		<InfoTitleWithInfoCircleContainer className={className}>
			<StyledInfoTitle title={title} tooltipContent={tooltipContent} />
			{tooltipContent && (
				<Tooltip placement="right" title={tooltipContent} color={theme['gray-9']}>
					<InfoCircleOutlined data-testid={INFO_CIRCLE_OUTLINED_TEST_ID} style={{ color: theme['gray-6'] }} />
				</Tooltip>
			)}
		</InfoTitleWithInfoCircleContainer>
	);
};

export default InfoTitleWithInfoCircle;
