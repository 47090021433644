import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';

export interface ContractInfo {
	partnerName: string;
	allowProduct: string[];
	startDate: string;
	endDate: string;
}

export interface contractState {
	contractInfo: ContractInfo;
}

const initialState: contractState = {
	contractInfo: {
		partnerName: '',
		allowProduct: [],
		startDate: '',
		endDate: '',
	},
};

const contractSlice = createSlice({
	name: 'contract',
	initialState,
	reducers: {
		setContractInfo: (state, action: PayloadAction<ContractInfo>) => {
			state.contractInfo = action.payload;
		},
	},
});

export const { setContractInfo } = contractSlice.actions;

export const getContractInfo = (state: RootState) => state.contract.contractInfo;
export const getPartnerName = (state: RootState) => state.contract.contractInfo.partnerName;
export const getContractPartnerName = (state: RootState) => state.contract.contractInfo.partnerName;
export const getAllowProduct = (state: RootState) => state.contract.contractInfo.allowProduct;

export default contractSlice.reducer;
