import React, { useEffect } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

interface FindAccountModalProps {
	visible: boolean;
	onOk?: () => void;
	onCancel?: () => void;
}

const StyledModal = styled(Modal)`
	width: 800px !important;

	.ant-modal-body {
		padding: 48px;
		min-height: 590px;
	}
`;

const FindAccountModal = ({ visible, onOk, onCancel }: FindAccountModalProps) => {
	useEffect(() => {
		// 파이프드라이브에서 사용하는 스크립트 삽입
		// const originalScript = document.getElementById('pipeDrive');
		// if (originalScript) {
		// 	originalScript.remove();
		// }

		const script = document.createElement('script');
		script.id = 'pipeDrive';
		script.setAttribute('src', 'https://webforms.pipedrive.com/f/loader');
		document.body.appendChild(script);
		script.remove();
	}, [visible]);

	return (
		<StyledModal visible={visible} onOk={onOk} onCancel={onCancel} footer={null}>
			<div
				className="pipedriveWebForms"
				data-pd-webforms="https://webforms.pipedrive.com/f/2Xj77d9Iz1M2mQ8Cg9EjJDSapHavIlv86e3Gu5bZYOvCOfzAZnf6j11Axma2otEcj"
			></div>
		</StyledModal>
	);
};

export default FindAccountModal;
