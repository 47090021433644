import React from 'react';
import HorizontalBarChart, { propTypes as HorizontalBarChartPropTypes } from '@/components/common/HorizontalBarChart';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export interface propTypes extends HorizontalBarChartPropTypes {
	title?: string;
	className?: string;
	isLoading?: boolean;
}

const StyledTitle = styled(Text)`
	font-size: 12px;
	line-height: 18px;
	color: ${props => props.theme['gray-7']};
`;

const HorizontalBarChartWithTitle = ({ data, title, className, isLoading }: propTypes) => {
	return (
		<div className={className}>
			{title && (
				<div className="mb-2">
					<StyledTitle>{title}</StyledTitle>
				</div>
			)}
			<HorizontalBarChart data={data} isLoading={isLoading} />
		</div>
	);
};

export default HorizontalBarChartWithTitle;
