import React from 'react';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import styled from 'styled-components';
import { formatDate } from '@/utils/date';
import { Skeleton } from 'antd';
import { cdnBaseurl } from '@/utils/constants/url';
import Avatar from 'antd/es/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import { flexCenter } from '@/styles/styles';
import { MentoringLogDetailQuestionItem } from '@/types/mentoring/mentoring';

export interface MentoringLogDetailQuestionProps {
	question: MentoringLogDetailQuestionItem;
	className?: string;
	isLoading?: boolean;
}

const StyledBox = styled.div`
	padding: 16px 32px;
	border: 1px solid ${props => props.theme['gray-4']};
`;

const StyledQuestionInfoWrapper = styled.div`
	margin-bottom: 14px;
	> * + * {
		margin-left: 4px;
	}
`;
const StyledMarginWrapper = styled.div<{ marginBottom: number }>`
	margin-bottom: ${props => `${props.marginBottom}px`};
`;

const StyledCaptionText = ({ children }: { children?: React.ReactNode }) => (
	<Text fontSize="12px" color={theme['gray-7']}>
		{children}
	</Text>
);

const StyledQuestionMarkText = ({ children }: { children?: React.ReactNode }) => (
	<Text fontSize="16px" color={theme['blue-7']}>
		{children}
	</Text>
);

const StyledSkeleton = styled(Skeleton)`
	.ant-skeleton-content .ant-skeleton-title {
		margin-top: 0;
	}
`;

const StyledBlurImage = styled.img`
	width: 100%;
`;

const StyledAuthorInfoWrapper = styled.div`
	flex-shrink: 0;
	margin-left: 8px;
	${flexCenter}
	> * + * {
		margin-left: 8px;
	}
`;

const StyledAuthorTextWrapper = styled.div`
	${flexCenter}
	> * + * {
		margin-left: 4px;
	}
`;

const StyledTitleContainer = styled.div`
	${mixin.flexBox()}
	${mixin.justifyContent('space-between')}
	${mixin.alignItems('flex-end')}
`;

const MentoringLogDetailQuestion = ({ question, className, isLoading }: MentoringLogDetailQuestionProps) => {
	const isTypeQA = question.type === 'qa';
	const isTypeINorCE = question.type === 'ce' || question.type === 'in';
	const blurContentImage = `${cdnBaseurl}/images/partners/blur-content.jpg`;
	return (
		<StyledBox className={className}>
			{isLoading ? (
				<StyledSkeleton active />
			) : (
				<>
					<StyledQuestionInfoWrapper>
						<StyledCaptionText>
							{question.company} / {question.job}
						</StyledCaptionText>
					</StyledQuestionInfoWrapper>
					<StyledMarginWrapper marginBottom={16}>
						{isTypeQA ? (
							<>
								<StyledQuestionMarkText>Q.</StyledQuestionMarkText>{' '}
								<Text fontSize="16px">{question.other_inf}</Text>
							</>
						) : (
							isTypeINorCE && (
								<StyledTitleContainer>
									<Text fontSize="16px" lineheight="24px">
										{question.other_inf
											? question.other_inf
											: question.title
											? question.title
											: '이력을 중심으로 자신을 소개해주세요'}
									</Text>
									<StyledAuthorInfoWrapper>
										<Avatar icon={<UserOutlined />} size="small" />
										<StyledAuthorTextWrapper>
											<Text color={theme['gray-7']}>{question.user_univ}</Text>
											<Text color={theme['gray-6']}>∙</Text>
											<Text color={theme['gray-6']}>{question.user_major}</Text>
										</StyledAuthorTextWrapper>
									</StyledAuthorInfoWrapper>
								</StyledTitleContainer>
							)
						)}
					</StyledMarginWrapper>
					<StyledMarginWrapper marginBottom={8}>
						{isTypeQA && <Text>{question.question}</Text>}
						{isTypeINorCE && <StyledBlurImage src={blurContentImage} alt="컨텐츠 블러 이미지" />}
					</StyledMarginWrapper>
					<div>
						<Text color={theme['gray-6']}>{formatDate(question.time, 'YYYY. MM. DD')}</Text>
					</div>
				</>
			)}
		</StyledBox>
	);
};

export default MentoringLogDetailQuestion;
