import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, Navigate } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import { Home } from '@/pages';
import { dynamicRoutePath, PARTNERS_REPORT_PATH, ROUTER_PATH } from '@/utils/constants/paths';
// 공용
import Login from '@/pages/Login';
import Logout from '@/pages/Logout';
// 콘텐츠
import ContentHome from '@/components/TabContent/Content/Home';
import ContentLog from '@/components/TabContent/Content/Log';
// 멘토링
import MentoringHome from '@/components/TabContent/Mentoring/Home';
import MentoringLog from '@/components/TabContent/Mentoring/Log';
import MentoringLogDetail from '@/components/TabContent/Mentoring/Log/Detail';
// AI
import AIHome from '@/components/TabContent/AI/Home';
import AILog from '@/components/TabContent/AI/Log';

// 직부캠 재맞고
import EduCampJumpUpHome from '@/components/TabContent/EduCamp/JumpUp/Home';
import EduCampJumpUpCampList from '@/components/TabContent/EduCamp/JumpUp/CampList';
import EduCampJumpUpUserList from '@/components/TabContent/EduCamp/JumpUp/UserList';
import EduCampJumpUpUserListDetail from '@/components/TabContent/EduCamp/JumpUp/ListDetail';
import EduCampJumpUpAssignment from '@/components/report/eduCampJumpUp/organisms/ReportCampJumpUpAssignment';

// 직부캠 개설형
import EduCampPrivateHome from '@/components/TabContent/EduCamp/Private/Home';
import EduCampPrivateCampList from '@/components/TabContent/EduCamp/Private/CampList';
import EduCampPrivateUserList from '@/components/TabContent/EduCamp/Private/UserList';
import EduCampPrivateUserListDetail from '@/components/TabContent/EduCamp/Private/ListDetail';
import EduCampPrivateAssignment from '@/components/report/eduCampPrivate/organisms/ReportCampPrivateAssignment';
// 직부캠 쿠폰형
import EduCampCouponHome from '@/components/TabContent/EduCamp/Coupon/Home';
import EduCampCouponCampList from '@/components/TabContent/EduCamp/Coupon/CouponList';
import EduCampCouponUserList from '@/components/TabContent/EduCamp/Coupon/UserList';

// 직로캠 쿠폰형
import EduPathCampCouponHome from '@/components/TabContent/EduPath/Coupon/Home';
import EduPathCampCouponCampList from '@/components/TabContent/EduPath/Coupon/CouponList';
import EduPathUserList from '@/components/TabContent/EduPath/Coupon/UserList';
import EduPathCouponUserListDetail from '@/components/TabContent/EduPath/Coupon/ListDetail';
import EduPathCampCouponAssignment from '@/components/report/eduPathCampCoupon/organisms/ReportEduCampCouponAssignment';

import EduPathV2CampCouponHome from '@/components/TabContent/EduPathV2/Coupon/Home';

// VOD
import VODHome from '@/components/TabContent/VOD/Home';
import VODLog from '@/components/TabContent/VOD/Log';
import VODUserList from '@/components/TabContent/VOD/UserList';

import DetailLayout from '@/layouts/DetailLayout';
import UserList from '@/components/UserList/UserList';
import EduCouponUserListDetail from '@/components/TabContent/EduCamp/Coupon/ListDetail';
import ReportEduCampCouponAssignment from '@/components/report/eduCampCoupon/organisms/ReportEduCampCouponAssignment';
import axiosClient from '@/api';
import { getPartnerNo, setPartnerNo } from '@/store/partnerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '@/contexts/AuthProvider';
import EduCampExternshipHome from '@/components/TabContent/EduCamp/Externship/Home';
import EduCampExternshipCampList from '@/components/TabContent/EduCamp/Externship/CampList';
import EduCampExternshipUserList from './components/TabContent/EduCamp/Externship/UserList';
import EduCampExternshipUserListDetail from '@/components/TabContent/EduCamp/Externship/ListDetail';
import EduCampExternshipAssignment from '@/components/report/eduCampExternship/organisms/EduCampExternshipAssignment';
import EduPathV2UserList from '@/components/TabContent/EduPathV2/Coupon/UserList';
import EduPathV2PrivateUserListDetail from '@/components/TabContent/EduPathV2/Coupon/ListDetail';
import EduPathV2CampAssignment from '@/components/report/eduPathCampCoupon/organisms/EduPathV2CampAssignment';
import EduPathV2CampCouponCampList from '@/components/TabContent/EduPathV2/Coupon/CouponList';

const Routes: React.FC = () => {
	return (
		<Router>
			<Switch>
				<Route index element={<DefaultRoute />} />
				<Route path={ROUTER_PATH.LOGIN} element={<Login />} />
				<Route path={ROUTER_PATH.LOGOUT} element={<Logout />} />
				<Route path={ROUTER_PATH.REPORT.HOME} element={<Home />} />
				<Route path={ROUTER_PATH.PARTNERS_BBS_HOME} element={<Home />} />
				<Route path={`${PARTNERS_REPORT_PATH}`} element={<DetailLayout />}>
					<Route index element={<ContentHome />} />
					<Route path={'content'}>
						<Route path={'home'} element={<ContentHome />} />
						<Route path={'log'} element={<ContentLog />} />
						<Route path={'user-list'} element={<UserList />} />
					</Route>
					<Route path={'mentoring'}>
						<Route path={'home'} element={<MentoringHome />} />
						<Route path={'log'} element={<MentoringLog />} />
						{/*// 중첩해서 사용하는 방법은 없는지?*/}
						<Route path={'log/detail/:id'} element={<MentoringLogDetail />} />
						<Route path={'user-list'} element={<UserList />} />
					</Route>
					<Route path={'ai'}>
						<Route path={'home'} element={<AIHome />} />
						<Route path={'log'} element={<AILog />} />
						<Route path={'user-list'} element={<UserList />} />
					</Route>
					<Route path={'edu-path'}>
						<Route path={'home'} element={<EduPathCampCouponHome />} />
						<Route path={'coupon-list'} element={<EduPathCampCouponCampList />} />
						<Route path={'user-list'} element={<EduPathUserList />} />
						<Route path={'user-list/:applicantNo'} element={<EduPathCouponUserListDetail />} />
						<Route
							path={'user-list/:applicantNo/assignment/:assignmentNo'}
							element={<EduPathCampCouponAssignment />}
						/>
					</Route>
					<Route path={'edu-path-v2'}>
						<Route path={'home'} element={<EduPathV2CampCouponHome />} />
						<Route path={'coupon-list'} element={<EduPathV2CampCouponCampList />} />
						<Route path={'user-list'} element={<EduPathV2UserList />} />
						<Route path={'user-list/:applicantNo'} element={<EduPathV2PrivateUserListDetail />} />
						<Route
							path={'user-list/:applicantNo/assignment/:assignmentNo'}
							element={<EduPathV2CampAssignment />}
						/>
					</Route>
					<Route path={'edu-camp'}>
						<Route path={'jump-up'}>
							<Route path={'home'} element={<EduCampJumpUpHome />} />
							<Route path={'camp-list'} element={<EduCampJumpUpCampList />} />
							<Route path={'user-list'} element={<EduCampJumpUpUserList />} />
							<Route path={'user-list/:applicantNo'} element={<EduCampJumpUpUserListDetail />} />
							<Route
								path={'user-list/:applicantNo/assignment/:assignmentNo'}
								element={<EduCampJumpUpAssignment />}
							/>
						</Route>
						<Route path={'private'}>
							<Route path={'home'} element={<EduCampPrivateHome />} />
							<Route path={'camp-list'} element={<EduCampPrivateCampList />} />
							<Route path={'user-list'} element={<EduCampPrivateUserList />} />
							<Route path={'user-list/:applicantNo'} element={<EduCampPrivateUserListDetail />} />
							<Route
								path={'user-list/:applicantNo/assignment/:assignmentNo'}
								element={<EduCampPrivateAssignment />}
							/>
						</Route>
						<Route path={'coupon'}>
							<Route path={'home'} element={<EduCampCouponHome />} />
							<Route path={'coupon-list'} element={<EduCampCouponCampList />} />
							<Route path={'user-list'} element={<EduCampCouponUserList />} />
							<Route path={'user-list/:applicantNo'} element={<EduCouponUserListDetail />} />
							<Route
								path={'user-list/:applicantNo/assignment/:assignmentNo'}
								element={<ReportEduCampCouponAssignment />}
							/>
						</Route>
						<Route path={'externship'}>
							<Route path={'home'} element={<EduCampExternshipHome />} />
							<Route path={'camp-list'} element={<EduCampExternshipCampList />} />
							<Route path={'user-list'} element={<EduCampExternshipUserList />} />
							<Route path={'user-list/:applicantNo'} element={<EduCampExternshipUserListDetail />} />
							<Route
								path={'user-list/:applicantNo/assignment/:assignmentNo'}
								element={<EduCampExternshipAssignment />}
							/>
						</Route>
					</Route>
					<Route path={'vod'}>
						<Route path={'home'} element={<VODHome />} />
						<Route path={'log'} element={<VODLog />} />
						<Route path={'user-list'} element={<VODUserList />} />
					</Route>
					<Route path={'user-list'} element={<UserList />} />
					<Route path="*" element={<DefaultRoute />} />
				</Route>
				<Route path="*" element={<DefaultRoute />} />
			</Switch>
		</Router>
	);
};

const DefaultRoute = () => {
	const partnerId = useSelector(getPartnerNo);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchPartnerNo = async function () {
			try {
				const { data } = await axiosClient.get('main/auth/check');
				dispatch(setPartnerNo(data));
			} catch (error) {
				dispatch(setPartnerNo(-1));
			}
		};
		fetchPartnerNo();
	}, []);

	const { isLogin } = useContext(AuthContext);
	if (!isLogin) {
		return <Navigate to={ROUTER_PATH.LOGIN} replace />;
	}

	return partnerId > 0 ? <Navigate to={dynamicRoutePath(ROUTER_PATH.REPORT.HOME, { partnerId })} replace /> : null;
};

export default Routes;
