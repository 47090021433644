import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import useContractId from '@/hooks/useContractId';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItem } from '@/components/common/organisms/InfoBoxWithTitle/types';
import { toFixedWithoutTrailingZeroes } from '@/utils/number';
import { InfoContent } from '@/components/common/molecules/InfoTitleWithInfoCircle/types';
import { useAiReportSummary } from '@/queries/report/AI/useAiReportQuery';
import { AI_SUMMARY_DATA } from '@/utils/constants/reportAi';
import { AISummaryData } from '@/types/AI/ai';

const ReportAISummarySection = () => {
	const contractId = useContractId();
	const { data, isLoading } = useAiReportSummary(contractId);
	const summaryData: AISummaryData = data?.data ?? AI_SUMMARY_DATA;

	const dataList = [
		{
			title: '등록자 수',
			tooltipContent: '코멘토 이용권을 등록한 사용자 수 입니다.',
			key: 'registrantsUserCount',
		},
		{
			title: '사용자 수',
			tooltipContent: '등록자 수 중 AI 분석을 1회 이상 사용한 사용자 수 입니다.',
			key: 'usersCount',
		},
		{
			title: '총 분석 횟수',
			tooltipContent: '분석을 사용한 사용자가 분석한 총 분석 횟수입니다.',
			key: 'totalViewsCount',
		},
		{
			title: '사용률',
			tooltipContent: '등록한 사용자 중 AI 분석을 1회 이상 사용한 사용자의 비율입니다.',
			key: 'utilizationRate',
			render: (content?: InfoContent) =>
				`${typeof content === 'string' ? content : toFixedWithoutTrailingZeroes(content || 0, 2)}%`,
		},
	];
	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SUMMARY_SECTION_TITLE}</StyledSectionTitle>
			<StyledInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.FOUR_COLUMN}>
				{dataList &&
					dataList.map(({ title, tooltipContent, key, render }: InfoBoxWithTitleArrayItem) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={summaryData[key]}
							key={title}
							isLoading={isLoading}
							render={render}
						/>
					))}
			</StyledInfoBoxContainer>
		</section>
	);
};

export default ReportAISummarySection;
