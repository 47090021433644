import React, { useState } from 'react';
import Title from '@/components/common/Typography/Title';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import HorizontalBarChartWithTitle from '@/components/common/HorizontalBarChartWithTitle';
import DropDownWithIcon, { IDropDownItem } from '@/components/common/molecules/DropDownWithIcon';
import InfoCircleWithTooltip from '@/components/common/molecules/InfoCircleWithTooltip';
import theme from '@/styles/theme';
import Text from '@/components/common/atoms/Text';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import { insightPeriodData } from '@/utils/constants/tabs';
import useContractId from '@/hooks/useContractId';
import { useAiInsightData } from '@/queries/report/AI/useAiReportQuery';

const StyledText = styled(Text)`
	margin-right: 8px;
`;

const StyledTitle = styled(Title)`
	margin-right: 4px;
`;

const StyledTitleContainer = styled.div`
	${flexAlignCenter}
`;

const ReportAIInsightSection = () => {
	const contractId = useContractId();
	const [period, setPeriod] = useState<IDropDownItem>(insightPeriodData[0]);
	const onClick = (item: IDropDownItem) => {
		setPeriod(item);
	};

	const {
		data: {
			data: {
				ai: { aiName, aiRatio },
			},
		},
		isRefetching,
	} = useAiInsightData(contractId, {
		type: 'ai' as const,
		period: period.value,
	});
	const AIChartItems = aiName.map((item: string, index: number) => {
		return {
			label: item,
			value: aiRatio[index],
		};
	});

	return (
		<section>
			<StyledTitleRowContainer>
				<StyledTitleContainer>
					<StyledTitle>{SECTION_TITLE.INSIGHT_SECTION_TITLE}</StyledTitle>
					<InfoCircleWithTooltip
						tooltipColor={theme['gray-9']}
						tooltipContent="사용자들의 AI 자기소개서 분석 데이터를 기반으로 작성된 데이터입니다."
						iconColor={theme['gray-8']}
						fontSize="20px"
					/>
				</StyledTitleContainer>
				<div>
					<StyledText fontSize="14px" color={theme['gray-6']}>
						기간 선택 :
					</StyledText>
					<DropDownWithIcon value={period} items={insightPeriodData} onClick={onClick} />
				</div>
			</StyledTitleRowContainer>
			<HorizontalBarChartWithTitle data={AIChartItems} isLoading={isRefetching} />
		</section>
	);
};

export default ReportAIInsightSection;
