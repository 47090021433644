import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderTitle, getListHeaderSubTitle } from '@/utils/header';

export const useUpdateListHeader = (subTitle: string) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle(subTitle)));
	}, [dispatch]);
};
