import axiosClient from '@/api';
const PRIVATE_PREFIX = '/educamp';
const INFO_PREFIX = '/info';
const SUMMARY_PREFIX = '/abstract';
const SATISFACTION_PREFIX = '/satisfaction';
const CAMP_PREFIX = '/camp';
const APPLICANT_PREFIX = '/applicant';
const LIST_PREFIX = '/list';
const ATTENDANCE_PREFIX = '/attendance';
const ASSIGNMENT_PREFIX = '/assignment';
const SCHEDULE_PREFIX = '/schedule';
const CLASSROOM_PREFIX = '/classroom';
const DOWNLOAD_PREFIX = '/download';

export const getPrivateSummarySectionRequest = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${PRIVATE_PREFIX}${SUMMARY_PREFIX}${INFO_PREFIX}`, { params });
};

export const getPrivateSatisfactionRequest = async (params: { contractNo: number; page: number }) => {
	// TODO: 테스트용 - 실제 배포시에는 삭제 예정
	return await axiosClient.get(`${PRIVATE_PREFIX}${SATISFACTION_PREFIX}${INFO_PREFIX}`, {
		params,
	});
};

export const getPrivateCampList = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${PRIVATE_PREFIX}${CAMP_PREFIX}${LIST_PREFIX}`, {
		params,
	});
};

export const getPrivateApplicantList = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${PRIVATE_PREFIX}${APPLICANT_PREFIX}${LIST_PREFIX}`, {
		params,
	});
};

export const getPrivateAttendanceAndAssignment = async (params: { applicant_no: number }) => {
	return await axiosClient.get(`${PRIVATE_PREFIX}${ATTENDANCE_PREFIX}/status`, {
		params,
	});
};

export const getPrivateCampSchedule = async (params: { applicant_no: number }) => {
	return await axiosClient.get(`${PRIVATE_PREFIX}${CAMP_PREFIX}${SCHEDULE_PREFIX}`, {
		params,
	});
};

export const getPrivateCampAssignment = async (params: { assignment_no: number; applicant_no: number }) => {
	return await axiosClient.get(`${PRIVATE_PREFIX}${CLASSROOM_PREFIX}${ASSIGNMENT_PREFIX}/detail`, {
		params,
	});
};

export const downloadApplicantToExcel = async (params: { contractNo: number }) => {
	return await axiosClient.getFile(`${PRIVATE_PREFIX}${APPLICANT_PREFIX}${DOWNLOAD_PREFIX}`, {
		params,
	});
};

export const downloadCampPrivateListToExcel = async (params: { contractNo: number }) => {
	return await axiosClient.getFile(`${PRIVATE_PREFIX}${CAMP_PREFIX}${DOWNLOAD_PREFIX}`, {
		params,
	});
};
