import React from 'react';
import styled from 'styled-components';
import { StyledBasicTable, StyledTitleRowContainer } from '@/components/common/styledComponents';
import { Button, Col, Row } from 'antd';
import { Attendance } from '@/components/report/eduPathCampCoupon/organisms/ReportCampCouponDetailSummary';
import { Link, useParams } from 'react-router-dom';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';

interface RecordNo {
	no?: number;
	assignment?: boolean;
}

interface RecordCreatedAt {
	classroom_assignments?: CreatedAt[];
}

interface CreatedAt {
	no?: number;
	created_at?: string;
}

const StyledTitle = styled.p`
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const StyledButton = styled(Button)`
	color: ${props => props.theme['link']};
	padding: 0;
`;

const StyledCellSection = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const ReportCampCouponDetailAssignment = ({
	applicantData,
	isLoading,
}: {
	applicantData?: Attendance[] | readonly object[];
	isLoading: boolean;
}) => {
	const params = useParams();

	const columnList = [
		{
			title: '제출자',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '차수',
			key: 'round',
			width: '80px',
			align: 'center' as const,
			render: (_: any, __: any, index: number) => {
				return `${index + 1}차`;
			},
		},
		{
			title: '제출일',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (date: string, record: RecordCreatedAt) => {
				const createdAt = record?.classroom_assignments && record?.classroom_assignments[0]?.created_at;
				return <p>{formatDate(createdAt, DATE_FORMAT.FULL_DATE)}</p>;
			},
		},
		{
			title: '피드백',
			key: 'feedback',
			dataIndex: 'feedback',
			render: (date: string) => <StyledCellSection>{date ? '완료' : '대기'}</StyledCellSection>,
		},
		{
			title: '과제 조회',
			key: 'action',
			dataIndex: 'action',
			render: (_: string, record: RecordNo) => (
				<>
					{record.assignment ? (
						<Link
							to={dynamicRoutePath(`${ROUTER_PATH.REPORT.EDU_PATH.ASSIGNMENT_LIST}/${record.no}`, params)}
						>
							<StyledButton type={'link'}>조회하기</StyledButton>
						</Link>
					) : (
						''
					)}
				</>
			),
		},
	];
	return (
		<section>
			<StyledTitleRowContainer>
				<StyledTitle>과제 제출 현황</StyledTitle>
			</StyledTitleRowContainer>
			<Row>
				<Col span={24}>
					<StyledBasicTable
						dataSource={applicantData}
						columns={columnList}
						pagination={false}
						loading={isLoading}
						rowKey={'no'}
						locale={{
							emptyText: <EmptyTableSection />,
						}}
					/>
				</Col>
			</Row>
		</section>
	);
};

export default ReportCampCouponDetailAssignment;
