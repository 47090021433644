import React from 'react';
import ScheduleTable from '@/components/common/molecules/ScheduleTable';
import styled from 'styled-components';
import { ScheduleData } from '@/types/eduCamp/campCommon';

const StyledSection = styled.section`
	margin-top: 60px;
`;

const StyledTitle = styled.p`
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const ReportCampExternshipSchedule = ({
	campSchedule,
	isLoading,
}: {
	campSchedule?: ScheduleData[];
	isLoading: boolean;
}) => {
	return (
		<StyledSection>
			<StyledTitle>캠프 일정</StyledTitle>
			<ScheduleTable campSchedule={campSchedule} isLoading={isLoading || !campSchedule} isJumpUp={false} />
		</StyledSection>
	);
};

export default ReportCampExternshipSchedule;
