import React, { useEffect, useState } from 'react';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import useContractId from '@/hooks/useContractId';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import ReportCampExternshipCheckDetailSection from '@/components/report/eduCampExternship/organisms/ReportCampExternshipCheckDetailSection';
import ReportCampExternshipSummarySection from '@/components/report/eduCampExternship/organisms/ReportCampExternshipSummarySection';
import ReportCampExternshipSatisfactionSection from '@/components/report/eduCampExternship/organisms/ReportCampExternshipSatisfactionSection';
import {
	useEduCampPrivateSatisfactionQuery,
	useEduCampPrivateSummaryQuery,
} from '@/queries/report/eduCampPrivate/useEduCampPrivateQuery';
import {
	EDU_CAMP_PRIVATE_SUMMARY_INITIAL_DATA,
	EDU_CAMP_SATISFACTION_INITIAL_DATA,
} from '@/utils/constants/eduCamp/eduCampCommon';

const EduCampExternshipHome = () => {
	const [page, setPage] = useState<number>(1);
	const contractId = useContractId();
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();
	const { data: externshipSummary, isLoading: summaryLoading } = useEduCampPrivateSummaryQuery(contractId);
	const summaryData = externshipSummary?.data ?? EDU_CAMP_PRIVATE_SUMMARY_INITIAL_DATA;
	const { data: satisfactionData, isLoading: satisfactionLoading } = useEduCampPrivateSatisfactionQuery(
		contractId,
		page,
	);
	const satisfaction = satisfactionData?.data ?? EDU_CAMP_SATISFACTION_INITIAL_DATA;

	const changePage = (page: number) => {
		setPage(page);
	};

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportCampExternshipSummarySection summaryData={summaryData} isLoading={summaryLoading} />
			<ReportCampExternshipCheckDetailSection />
			<ReportCampExternshipSatisfactionSection
				isLoading={satisfactionLoading}
				satisfactionData={satisfaction}
				page={page}
				changePage={changePage}
			/>
		</DetailSectionContainer>
	);
};

export default EduCampExternshipHome;
