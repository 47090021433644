import React from 'react';
import styled from 'styled-components';
import { nl2br } from '@/utils/string';
import DateCaption from '@/components/Mentoring/Log/Detail/MentoringDetailDateCaption';
import { Avatar } from 'antd';
import theme, { mixin } from '@/styles/theme';
import Text from '@/components/common/atoms/Text';
import { CheckCircleFilled } from '@ant-design/icons';
import { flexAlignCenter } from '@/styles/styles';

export type MentoringLogDetailReplyData = any;

interface MentoringLogDetailReplyProps {
	reply: MentoringLogDetailReplyData;
}

const StyledMentoringLogDetailReplyContainer = styled.div`
	${mixin.flexBox()};
`;

const StyledComment = styled.p`
	line-height: 22px;
	// antd 기본스타일 무시
	margin-bottom: 12px !important;
`;

const StyledUserInfoTextWrapper = styled.div`
	${flexAlignCenter}
	> * + * {
		margin-left: 4px;
	}
`;

const StyledUserInfoTextWrapperWithMargin = styled(StyledUserInfoTextWrapper)`
	margin-bottom: 2px;
	${flexAlignCenter}
`;

const StyledUserInfoContainer = styled.div`
	margin-bottom: 4px;
	//${flexAlignCenter};
`;

const StyledUserInfoTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const StyledLayoutContainer = styled.div`
	${mixin.flexBox()};
	${mixin.flexDirection('column')};
`;

const StyledBelongWrapper = styled.div`
	${flexAlignCenter};
`;

const MentoringLogDetailReply = ({ reply }: MentoringLogDetailReplyProps) => {
	return (
		<StyledMentoringLogDetailReplyContainer>
			<Avatar style={{ backgroundColor: theme['red-2'], verticalAlign: 'middle', marginRight: '8px' }}>
				{reply.user_list.nickname[0]}
			</Avatar>
			<StyledLayoutContainer>
				<StyledUserInfoContainer>
					<StyledUserInfoTextContainer>
						<StyledUserInfoTextWrapperWithMargin>
							<Text color={theme['gray-7']} fontSize="12px">
								{reply.user_list.nickname}
							</Text>
							{reply.user_list.belong && (
								<>
									<Text color={theme['gray-6']} fontSize="12px">
										∙
									</Text>
									<StyledBelongWrapper>
										<Text color={theme['gray-6']} fontSize="12px" style={{ marginRight: '4px' }}>
											{reply.user_list.belong}
										</Text>
										<CheckCircleFilled style={{ color: theme['blue-7'], fontSize: '12px' }} />
									</StyledBelongWrapper>
								</>
							)}
						</StyledUserInfoTextWrapperWithMargin>
					</StyledUserInfoTextContainer>
				</StyledUserInfoContainer>
				<StyledComment
					dangerouslySetInnerHTML={{
						__html: nl2br(reply.comment),
					}}
				/>
				<DateCaption date={reply.time} />
			</StyledLayoutContainer>
		</StyledMentoringLogDetailReplyContainer>
	);
};

export default MentoringLogDetailReply;
