import React, { useState } from 'react';
import Title from '@/components/common/Typography/Title';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import HorizontalBarChartWithTitle from '@/components/common/HorizontalBarChartWithTitle';
import DropDownWithIcon, { IDropDownItem } from '@/components/common/molecules/DropDownWithIcon';
import InfoCircleWithTooltip from '@/components/common/molecules/InfoCircleWithTooltip';
import theme from '@/styles/theme';
import Text from '@/components/common/atoms/Text';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import { useParams } from 'react-router-dom';
import { convertParamsIntoNumber } from '@/utils/params';
import { insightPeriodData } from '@/utils/constants/tabs';
import { useContentInsightQuery } from '@/queries/report/contents/useContentsQuery';

const StyledText = styled(Text)`
	margin-right: 8px;
`;

const StyledTitle = styled(Title)`
	margin-right: 4px;
`;

const StyledTitleContainer = styled.div`
	${flexAlignCenter}
`;

// TODO : BarChart 사이에 margin-bottom: 16px을 넣는 방법은 없을까? (차트가 추가될 수 있으므로)
const StyledHorizontalBarChartWithTitle = styled(HorizontalBarChartWithTitle)`
	margin-bottom: 16px;
`;

const ReportContentInsightSection = () => {
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	const [period, setPeriod] = useState<IDropDownItem>(insightPeriodData[0]);
	const {
		data: {
			data: { company, job },
		},
		isRefetching,
	} = useContentInsightQuery(contractId, {
		type: 'content' as const,
		period: period.value,
	});
	const companyChartItems = company.contentName.map((item: string, index: number) => {
		return {
			label: item,
			value: company.contentRatio[index],
		};
	});
	const jobChartItems = job.jobName.map((item: string, index: number) => {
		return {
			label: item,
			value: job.jobRatio[index],
		};
	});

	const onClick = (item: IDropDownItem) => {
		setPeriod(item);
	};

	return (
		<section>
			<StyledTitleRowContainer>
				<StyledTitleContainer>
					<StyledTitle>{SECTION_TITLE.INSIGHT_SECTION_TITLE}</StyledTitle>
					<InfoCircleWithTooltip
						tooltipColor={theme['gray-9']}
						tooltipContent="사용자들의 콘텐츠 열람을 기반으로 작성된 데이터입니다."
						iconColor={theme['gray-8']}
						fontSize="20px"
					/>
				</StyledTitleContainer>
				<div>
					<StyledText fontSize="14px" color={theme['gray-6']}>
						기간 선택 :
					</StyledText>
					<DropDownWithIcon value={period} items={insightPeriodData} onClick={onClick} />
				</div>
			</StyledTitleRowContainer>
			<StyledHorizontalBarChartWithTitle
				title="관심 기업 TOP10"
				data={companyChartItems}
				isLoading={isRefetching}
			/>
			<HorizontalBarChartWithTitle title="관심 직무 TOP10" data={jobChartItems} isLoading={isRefetching} />
		</section>
	);
};

export default ReportContentInsightSection;
