import React from 'react';
import styled from 'styled-components';
import theme from '@/styles/theme';
import Text from '@/components/common/atoms/Text';
import { flexAlignCenter } from '@/styles/styles';
import { HeartOutlined, CommentOutlined } from '@ant-design/icons';
import { nl2br } from '@/utils/string';
import { formatDate } from '@/utils/date';
import ChosenBadge from '@/components/TabContent/Mentoring/Log/Detail/ChosenBadge';
import CommonUserInformation from '@/components/Mentoring/Log/Detail/CommonUserInformation';
import ContentBody from '@/components/Mentoring/Log/Detail/ContentBody';
import MentoringLogDetailReply, {
	MentoringLogDetailReplyData,
} from '@/components/Mentoring/Log/Detail/MentoringLogDetailReply';
import { Divider, Skeleton } from 'antd';
import { MentoringLogDetailAnswerItem } from '@/types/mentoring/mentoring';

interface MentoringLogDetailAnswerProps {
	answer: MentoringLogDetailAnswerItem;
	type: string;
	isLoading?: boolean;
}

const StyledMentoringQAAnswerContainer = styled.div`
	padding: 20px 32px;
	border: 1px solid ${props => props.theme['gray-4']};
	position: relative;
`;

const StyledCommentWrapper = styled.div`
	margin-bottom: 8px;
`;

const StyledAnswerDateWrapper = styled.div`
	margin-bottom: 8px;
	line-height: 22px;
`;

const StyledActionButtonWrapper = styled.div`
	line-height: 18px;
	padding: 4px;
	${flexAlignCenter}
	> * + * {
		margin-left: 4px;
	}
`;

const StyledActionButtonContainer = styled.div`
	> ${StyledActionButtonWrapper} + ${StyledActionButtonWrapper} {
		margin-left: 10px;
	}
	${flexAlignCenter}
`;

export const StyledComment = styled.p`
	line-height: 22px;
	// antd 기본스타일 무시
	margin-bottom: 0 !important;
`;

const StyledContentBodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledSkeleton = styled(Skeleton)`
	.ant-skeleton-content .ant-skeleton-title {
		margin-top: 0;
	}
`;

const StyledReplyContainer = styled.div`
	> * + * {
		margin-top: 16px;
	}
`;

const MentoringLogDetailAnswer = ({
	answer: { user_list, comment, time, likenum, reply_feedback, choose },
	type,
	isLoading,
}: MentoringLogDetailAnswerProps) => {
	const isChosen = choose === 2;
	const replyCount = reply_feedback ? reply_feedback.length : 0;
	const hasReply = replyCount > 0;

	return (
		<StyledMentoringQAAnswerContainer>
			{isLoading ? (
				<StyledSkeleton active />
			) : (
				<>
					{isChosen && <ChosenBadge />}
					<CommonUserInformation userList={user_list} />
					<StyledCommentWrapper>
						<Text>
							{type === 'in' && typeof comment === 'object' ? (
								<StyledContentBodyWrapper>
									<ContentBody
										align="left"
										emphasized
										contentBody={JSON.stringify(comment.q1.ques)}
									/>
									<ContentBody contentBody={JSON.stringify(comment.q1.answ)} />
									<ContentBody emphasized contentBody={JSON.stringify(comment.q2.ques)} />
									<ContentBody contentBody={JSON.stringify(comment.q2.answ)} />
								</StyledContentBodyWrapper>
							) : (
								<StyledComment
									dangerouslySetInnerHTML={{
										__html: nl2br(typeof comment === 'string' ? comment : ''),
									}}
								/>
							)}
						</Text>
					</StyledCommentWrapper>
					<StyledAnswerDateWrapper>
						<Text color={theme['gray-6']}>{formatDate(time, 'YYYY. MM. DD')}</Text>
					</StyledAnswerDateWrapper>
					<StyledActionButtonContainer>
						<StyledActionButtonWrapper>
							<HeartOutlined style={{ color: theme['gray-7'] }} />
							<Text color={theme['gray-7']}>좋아요 {likenum}</Text>
						</StyledActionButtonWrapper>
						<StyledActionButtonWrapper>
							<CommentOutlined style={{ color: theme['gray-7'] }} />
							<Text color={theme['gray-7']}>댓글 {replyCount}</Text>
						</StyledActionButtonWrapper>
					</StyledActionButtonContainer>
					{hasReply && <Divider dashed />}
					{hasReply && (
						<StyledReplyContainer>
							{reply_feedback.map((item: MentoringLogDetailReplyData) => (
								<MentoringLogDetailReply reply={item} key={item.no} />
							))}
						</StyledReplyContainer>
					)}
				</>
			)}
		</StyledMentoringQAAnswerContainer>
	);
};

export default MentoringLogDetailAnswer;
