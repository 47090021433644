import {
	AI,
	CONTENT,
	EDU_CAMP_COUPON,
	EDU_CAMP_EXTERNSHIP,
	EDU_CAMP_PRIVATE,
	EDU_PATH,
	EDU_PATH_V2,
	MENTORING,
	VOD,
} from '@/utils/constants/paths';

export const SIDE_MENU_WIDTH = '236px' as const;

export const ProductPathRecord = {
	콘텐츠: CONTENT,
	멘토링: MENTORING,
	'AI 자기소개서 분석기': AI,
	'직무부트캠프(개설형)': EDU_CAMP_PRIVATE,
	'직무부트캠프(쿠폰형)': EDU_CAMP_COUPON,
	익스턴십: EDU_CAMP_EXTERNSHIP,
	'직무 VOD': VOD,
	진로부트캠프: EDU_PATH,
	진로부트캠프V2: EDU_PATH_V2,
} as const;

export const REPORT_CONTRACT_INITIAL_DATA = {
	data: {
		partnerName: '',
		contracts: [],
	},
};

export const USER_LIST_INITIAL_DATA = {
	data: [
		{
			email: '',
			joinDate: '',
			mobile: '',
			name: '',
			registrationDate: '',
			studentNumber: '',
			userNumber: 0,
		},
	],
	total: 0,
};

export const SATISFACTION_INITIAL_DATA = {
	all: 0,
	avg: 0,
	grade: {
		one: 0,
		two: 0,
		three: 0,
		four: 0,
		five: 0,
	},
	list: [],
};

export const EDU_COUPON_UNUSED_INITIAL_DATA = {
	count: 0,
	list: [],
	expire_date: '',
};

export const CAMP_UNUSED_COUPON_INITIAL_DATA = {
	data: EDU_COUPON_UNUSED_INITIAL_DATA,
};

export const USAGE_DEFAULT_VALUE = {
	barGraph: [{ date: '', value: 0 }],
	lineGraph: [{ date: '', value: 0 }],
	table: { use: {}, user: {} },
};

export const REPORT_SATISFACTION_INITIAL_DATA = {
	avg: 0,
	satisfaction: [
		{
			value: 5,
			percent: 0,
		},
		{
			value: 4,
			percent: 0,
		},
		{
			value: 3,
			percent: 0,
		},
		{
			value: 2,
			percent: 0,
		},
		{
			value: 1,
			percent: 0,
		},
	],
	total: 0,
};

export const REPORT_LOG_INITIAL = {
	current_page: 1,
	data: [],
	total: 0,
};
