import axiosClient from '@/api';
import { InsightRequestParams, RegistrantsRequestParams, SatisfactionRequestParams } from '@/api/report/content';
import { blobResponseType } from '@/utils/download';

const AI_PREFIX = '/ai';

const SUMMARY_PREFIX = '/summary';
const MONTHLY_PREFIX = '/monthly';
const INSIGHT_PREFIX = '/insight';
const SATISFACTION_PREFIX = '/satisfaction';

export const AISummaryRequest = async (contractId: number) => {
	return await axiosClient.get(`${AI_PREFIX}${SUMMARY_PREFIX}/${contractId}`);
};

export const AIMonthlyUsageRequest = async (contractId: number) => {
	return await axiosClient.get(`${AI_PREFIX}${MONTHLY_PREFIX}/${contractId}`);
};

export const AIMonthlyUsageExcelRequest = async (contractId: number) => {
	return await axiosClient.get(`${AI_PREFIX}${MONTHLY_PREFIX}/${contractId}?excel=1`, { ...blobResponseType });
};

export const AIInsightRequest = async (contractId: number, params: InsightRequestParams) => {
	return await axiosClient.get(`${AI_PREFIX}${INSIGHT_PREFIX}/${contractId}`, { params });
};

export const AISatisfactionRequest = async (contractId: number, params: SatisfactionRequestParams) => {
	return await axiosClient.get(`${AI_PREFIX}${SATISFACTION_PREFIX}/${contractId}`, { params });
};

type AILogRequestParams = RegistrantsRequestParams;
export const AILogRequest = async (contractId: number, params: AILogRequestParams) => {
	return await axiosClient.get(`${AI_PREFIX}/used/status/${contractId}`, { params });
};

type AILogExcelRequestParams = AILogRequestParams;
export const AILogExcelRequest = async (contractId: number, params: AILogExcelRequestParams) => {
	return await axiosClient.get(`${AI_PREFIX}/used/status/${contractId}?excel=1`, { params, ...blobResponseType });
};
