import React, { useContext, useState } from 'react';
import Title from '@/components/common/Typography/Title';
import DataTable from '@/components/common/organisms/DataTable';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import { ReportContentMonthlyUsageContext } from '@/contexts/ReportContentMonthlyUsage';
import { contentMonthlyUsageExcelRequest } from '@/api/report/content';
import useContractId from '@/hooks/useContractId';
import { downloadExcelFile } from '@/utils/download';
import usePartnerName from '@/hooks/usePartnerName';
import { covertDataToTableData } from '@/utils/table';

const ReportContentMonthlyUsageTableSection = () => {
	const { reportContentMonthlyUsage, isLoading } = useContext(ReportContentMonthlyUsageContext);
	const contractId = useContractId();
	const partnerName = usePartnerName();
	const [isLoadingDownload, setLoadingDownload] = useState(false);

	const handleClickDownloadButton = async () => {
		try {
			setLoadingDownload(true);
			const { data } = await contentMonthlyUsageExcelRequest(contractId);
			downloadExcelFile(data, `${partnerName}-콘텐츠-월별 이용 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingDownload(false);
		}
	};
	// data 예시
	// const data = [
	// 	{
	// 		xAxisTitle: '사용자 수',
	// 		'2021-01': 9,
	// 		'2021-02': 231,
	// 		'2021-03': 231,
	// 		'2021-04': 231,
	// 		'2021-05': 231,
	// 		'2021-06': 231,
	// 	},
	// 	{
	// 		xAxisTitle: '열람 횟수',
	// 		'2021-01': 9,
	// 		'2021-02': 231,
	// 		'2021-03': 3285,
	// 		'2021-04': 6543,
	// 		'2021-05': 2422,
	// 		'2021-06': 231,
	// 	},
	// ];
	const convertedData = covertDataToTableData(reportContentMonthlyUsage.table);

	// 둘 중 하나
	// 모든 아이템에 key가 빠지지 않고 일정하게 들어간다면 노이슈
	const keyList = convertedData.length === 0 ? [] : Object.keys(convertedData[0]);

	return (
		<section>
			<StyledTitleRowContainer>
				<Title>{SECTION_TITLE.MONTHLY_USAGE_TABLE_SECTION_TITLE}</Title>
				<DownloadButton loading={isLoadingDownload} onClick={handleClickDownloadButton}>
					다운로드
				</DownloadButton>
			</StyledTitleRowContainer>
			<DataTable data={convertedData} keyList={keyList} isLoading={isLoading} />
		</section>
	);
};

export default ReportContentMonthlyUsageTableSection;
