import React from 'react';
import { Bar } from '@ant-design/charts';
import styled from 'styled-components';
import theme from '@/styles/theme';

const StyledHorizontalBarChartContainer = styled.div`
	position: relative;
`;

// TODO : 오른쪽에 항목 떠다니는거 없애기
// TODO : 밑에 가로축 value 없애기
const SkeletonBarChart = () => {
	const config = {
		data: [
			{
				label: '1',
				value: 15.78,
			},
			{
				label: '2',
				value: 13.88,
			},
			{
				label: '3',
				value: 6.84,
			},
			{
				label: '4',
				value: 6.27,
			},
			{
				label: '5',
				value: 6.27,
			},
			{
				label: '6',
				value: 5.32,
			},
			{
				label: '7',
				value: 4.94,
			},
			{
				label: '8',
				value: 3.99,
			},
			{
				label: '9',
				value: 3.99,
			},
			{
				label: '10',
				value: 3.8,
			},
		],
		xField: 'value',
		yField: 'label',
		// blue가 object 배열이므로 값만 추출
		color: theme['gray-4'],
	};
	return (
		<StyledHorizontalBarChartContainer>
			<Bar {...config} />
		</StyledHorizontalBarChartContainer>
	);
};

export default SkeletonBarChart;
