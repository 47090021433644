import { getEduPathV2AllAssignmentsDownload, getEduPathV2ApplicantListDownload } from '@/api/report/eduPath';
import useContractId from '@/hooks/useContractId';
import usePartnerName from '@/hooks/usePartnerName';
import { PRODUCTS } from '@/utils/constants/products';
import { /*downloadFile,*/ downloadExcelFile, downloadFile } from '@/utils/download';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

const StyledButtonsContainer = styled.div`
	display: flex;
	gap: 8px;
`;

export interface props {
	noteUrl: string | null;
	expireDate: string;
}

const EduPathUserListDownloadButtons = (info: props) => {
	const contractId = useContractId();
	const partnerName = usePartnerName();
	const [isAssignmentsDownloadLoading, setAssignmentDownloadLoading] = useState(false);
	const [isApplicantsDownloadLoading, setApplicantsDownloadLoading] = useState(false);

	const downloadEduPathAssignment = useCallback(async () => {
		try {
			setAssignmentDownloadLoading(true);
			const { data } = await getEduPathV2AllAssignmentsDownload(contractId);
			downloadFile(data, 'application/zip', `${partnerName}-${PRODUCTS.EDU_PATH}-신청자 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setAssignmentDownloadLoading(false);
		}
	}, [contractId, partnerName]);

	const downloadEduPathApplicantsList = useCallback(async () => {
		try {
			setApplicantsDownloadLoading(true);
			const { data } = await getEduPathV2ApplicantListDownload(contractId);
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.EDU_PATH}-신청자 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setApplicantsDownloadLoading(false);
		}
	}, [contractId, partnerName]);

	const isNoteUrlAvailable = useCallback(() => {
		const isExpireDatePassed = dayjs().isAfter(dayjs(info.expireDate));
		if (isExpireDatePassed) {
			return !!info.noteUrl;
		} else {
			return false;
		}
	}, [info.noteUrl, info.expireDate]);

	const downloadEduPathNote = useCallback(() => {
		window.open(info.noteUrl);
	}, [info.noteUrl]);

	return (
		<StyledButtonsContainer>
			<Button
				icon={<DownloadOutlined />}
				onClick={downloadEduPathApplicantsList}
				loading={isApplicantsDownloadLoading}
			>
				신청자 명단 다운로드
			</Button>
			<Button
				icon={<DownloadOutlined />}
				onClick={downloadEduPathAssignment}
				loading={isAssignmentsDownloadLoading}
			>
				과제 일괄 다운로드
			</Button>
			<Button icon={<DownloadOutlined />} onClick={downloadEduPathNote} disabled={!isNoteUrlAvailable()}>
				진로 포트폴리오 다운로드
			</Button>
		</StyledButtonsContainer>
	);
};

export default EduPathUserListDownloadButtons;
