import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItemOmitKey } from '@/components/common/organisms/InfoBoxWithTitle/types';

type ReportInfoSectionProps = {
	title: string;
	data: InfoBoxWithTitleArrayItemOmitKey[];
	isLoading: boolean;
};

const ReportInfoSection = ({ title, data, isLoading }: ReportInfoSectionProps) => {
	return (
		<section>
			<StyledSectionTitle>{title}</StyledSectionTitle>
			<StyledInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.THREE_COLUMN}>
				{data &&
					data.map(({ title, tooltipContent, content, couponData }: InfoBoxWithTitleArrayItemOmitKey) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={content}
							key={title}
							couponData={couponData}
							isLoading={isLoading}
						/>
					))}
			</StyledInfoBoxContainer>
		</section>
	);
};

export default ReportInfoSection;
