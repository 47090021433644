export const AI_SUMMARY_DATA = {
	registrantsUserCount: 0,
	totalViewsCount: 0,
	usersCount: 0,
	utilizationRate: 0,
};

export const AI_INSIGHT_INITIAL_DATA = {
	data: {
		ai: {
			aiName: [],
			aiRatio: [],
		},
	},
};
