import React from 'react';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import { Skeleton } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const StyledRow = styled.div`
	justify-content: space-between;
	margin-bottom: 24px;
	${flexAlignCenter}
`;

interface MentoringLogDetailAnswerCountInfoProps {
	answer: Record<any, any>;
	isLoading?: boolean;
}

const StyledChooseTextWrapper = styled.div`
	${flexAlignCenter}
`;

const MentoringLogDetailAnswerCountInfo = ({ answer, isLoading }: MentoringLogDetailAnswerCountInfoProps) => {
	const hasChoose = answer.some((answer: any) => answer.choose === 2);

	return (
		<StyledRow>
			{isLoading ? (
				<Skeleton title={false} paragraph={{ rows: 1 }} active />
			) : (
				<>
					<Text color={theme['gray-7']} fontSize="12px">
						답변 {answer.length}
					</Text>
					{hasChoose && (
						<StyledChooseTextWrapper>
							<Text color={theme['gray-6']} fontSize="12px">
								마감된 사례입니다.
							</Text>
							<InfoCircleOutlined
								style={{ color: theme['gray-6'], fontSize: '14px', marginLeft: '2px' }}
							/>
						</StyledChooseTextWrapper>
					)}
				</>
			)}
		</StyledRow>
	);
};

export default MentoringLogDetailAnswerCountInfo;
