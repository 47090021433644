import React, { useEffect, useState } from 'react';
import { Col, ConfigProvider, DatePicker, Row } from 'antd';
import ResetButton from '@/components/common/molecules/ResetButton';
import { numberToLocalString } from '@/utils/number';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { StyledBasicTable } from '@/components/common/styledComponents';
import { StyledUserCountContainer, StyledDatePickerContainer, createColumns } from '@/components/UserList/UserList';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { animateScroll } from 'react-scroll';
import { contentLogExcelRequest } from '@/api/report/content';
import useRangeInput, { emptyRangeDate } from '@/hooks/useRangeInput';
import useContractId from '@/hooks/useContractId';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { downloadExcelFile } from '@/utils/download';
import usePartnerName from '@/hooks/usePartnerName';
import { defaultTablePaginationConfig } from '@/utils/constants/table';
import { PRODUCTS } from '@/utils/constants/products';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { useContentLogQuery } from '@/queries/report/contents/useContentsQuery';
import { REPORT_LOG_INITIAL } from '@/utils/constants/common';
const { RangePicker } = DatePicker;

const StyledContentTitle = styled(Text)`
	${mixin.ellipsis(2)};
`;

const StyledSpan = styled.span`
	white-space: pre-line;
`;

const ContentLog = () => {
	const contractId = useContractId();
	const partnerName = usePartnerName();
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [isLoadingDownload, setIsLoadingDownload] = useState(false);
	const { rangeDate, rangeDateString, onChange, resetRangeDate, isResetButtonDisabled } =
		useRangeInput(emptyRangeDate);
	const { data, isLoading } = useContentLogQuery(contractId, {
		...(rangeDateString[0] && { startDate: rangeDateString[0] }),
		...(rangeDateString[1] && { endDate: rangeDateString[1] }),
		page,
	});
	const { data: contentLogList, total: totalLog } = data?.data ?? REPORT_LOG_INITIAL;

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('콘텐츠 열람 현황')));
	}, []);

	useEffect(() => {
		animateScroll.scrollToTop({
			delay: 100,
			duration: 100,
			smooth: true,
		});
	}, [contractId, rangeDateString, page]);

	const handleClickExcelDownloadButton = async () => {
		try {
			setIsLoadingDownload(true);
			const { data } = await contentLogExcelRequest(contractId, {
				...(rangeDateString[0] && { startDate: rangeDateString[0] }),
				...(rangeDateString[1] && { endDate: rangeDateString[1] }),
			});
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.CONTENT}-열람 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoadingDownload(false);
		}
	};

	const list = [
		{
			title: '열람일시',
			key: 'date',
			width: '143px',
			render: (date: string) => (
				<StyledSpan>{formatDate(date, DATE_FORMAT.DATE_WITH_TIME).replace(' ', '\n')}</StyledSpan>
			),
		},
		{
			title: '학번/ID',
			key: 'studentNumber',
			width: '143px',
		},
		{
			title: '전공',
			key: 'major',
			width: '100px',
		},
		{
			title: '이름',
			key: 'name',
			width: '100px',
		},
		{
			title: '글 번호',
			key: 'contentNumber',
			width: '100px',
			render: (contentNumber: string) => <>{Number(contentNumber) > 0 && contentNumber}</>,
		},
		{
			title: '글 제목',
			key: 'contentTitle',
			align: 'left' as const,
			width: '240px',
			render: (content_title: string) => <StyledContentTitle>{content_title}</StyledContentTitle>,
		},
		{
			title: '연관 기업',
			tooltipContent:
				'유저가 열람한 사례와 관련된 기업입니다. 특별한 연관 기업 정보가 없다면 “모든 기업”으로 노출됩니다.',
			width: '143px',
			key: 'company',
		},
		{
			title: '연관 직무',
			tooltipContent:
				'유저가 열람한 사례와 연관된 직무입니다. 연관 기업이 없다면 해당 셀은 비어있을 수 있습니다.',
			width: '143px',
			key: 'job',
		},
	];

	const onTableChange = (pagination: TablePaginationConfig) => {
		// pagination이 없을 수 있다.
		setPage(pagination?.current || 1);
	};

	return (
		<>
			<Row>
				<StyledDatePickerContainer>
					<RangePicker
						value={rangeDate}
						onChange={onChange}
						allowClear={false}
						placeholder={['시작 기간', '종료 기간']}
					/>
					<ResetButton onClick={resetRangeDate} disabled={isResetButtonDisabled} />
				</StyledDatePickerContainer>
			</Row>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							데이터 수 : <Text color={theme['blue-7']}>{numberToLocalString(totalLog)} </Text> 건
						</Text>
						<DownloadButton onClick={handleClickExcelDownloadButton} loading={isLoadingDownload} />
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledBasicTable
							dataSource={contentLogList}
							columns={createColumns(list)}
							pagination={{ ...defaultTablePaginationConfig, total: totalLog }}
							loading={isLoading}
							isLoading={isLoading}
							onChange={onTableChange}
							rowKey="key"
						/>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};
export default ContentLog;
