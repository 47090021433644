import { useEduCampPathV2ApplicantList } from '@/queries/report/eduCampPath/eduCampPathV2Query';
import { flatten } from 'lodash';
import { generateArrayFilledWithLength, removeConsecutiveDuplicates } from '@/utils/data';
import useContractId from '@/hooks/useContractId';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useEduCampPathV2ApplicantTablePaginationData = () => {
	const contractId = useContractId();

	const [perPage, setPerPage] = useState(8);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);

	const { data, isLoading } = useEduCampPathV2ApplicantList(contractId, {
		page,
		limit: perPage,
	});

	// 데이터를 다시 불러올 때 total이 0이 됨. 따라서 기존 total값을 기억하는 용도
	useEffect(() => {
		if (data && data.total !== total) setTotal(data.total);
	}, [data, total]);

	// 쿠폰 번호별로 그룹지어진 데이터
	const rawData = data?.list;
	// 그룹을 해제해서 쭉 편 데이터(table에 표시할 데이터)
	const flattenedData = useMemo(() => flatten(rawData), [rawData]);
	// 쿠폰 번호별로 그룹 지어진 데이터의 개수를 array로 return함
	// 예를 들어, [2,3,1]
	// 여기서 flatMap을 적용해서 [2,2,3,3,3,1]로 변경함
	const flatMap = useMemo(() => generateArrayFilledWithLength(rawData), [rawData]);
	// [2,2,3,3,3,1] => [2,0,3,0,0,1]으로 변환하여 rowSpans로 사용
	const rowSpans = useMemo(() => removeConsecutiveDuplicates(flatMap), [flatMap]);

	// useCallback을 이용해서 최초 1회 memoization
	const handleSetPage = useCallback((page: number) => {
		setPage(page);
	}, []);

	const handlePageSizeChange = useCallback((size: number) => {
		setPerPage(size);
	}, []);

	const noteUrl = data?.noteUrl;
	const expireDate = data?.expireDate;

	return {
		rowSpans,
		flattenedData,
		total,
		page,
		perPage,
		setPage: handleSetPage,
		setPerPage: handlePageSizeChange,
		isLoading,
		noteUrl,
		expireDate,
	};
};
