import React, { useCallback } from 'react';
// import dayjs from 'dayjs';
import Title from '@/components/common/Typography/Title';
import DataTable from '@/components/common/organisms/DataTable';
import SECTION_TITLE from '@/utils/constants/section-title';
import useContractId from '@/hooks/useContractId';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import { useVodUsage } from '@/queries/report/vod/useVodQuery';
import { VODMonthlyUsage } from '@/types/vod/vod';

interface MonthlyUsageRow {
	[key: string]: number | string;
	xAxisTitle: string;
}

const ReportVodMonthlyUsageTableSection = () => {
	const contractId = useContractId();
	const { data, isLoading } = useVodUsage(contractId);
	const vodUsage = data ?? [];
	const getUsage = useCallback((usages: VODMonthlyUsage[]) => {
		const firstRow: MonthlyUsageRow = { xAxisTitle: '학습자 수' };
		const secondRow: MonthlyUsageRow = { xAxisTitle: '재생 횟수' };
		// 데이터 없으면 usages는 []
		if (usages.length === 0) return [];
		usages.forEach((item: VODMonthlyUsage) => {
			firstRow[item.month] = Number(item.user);
			secondRow[item.month] = Number(item.used);
		});
		return [firstRow, secondRow];
	}, []);
	const usage = getUsage(vodUsage);

	const keyList = usage.length === 0 ? [] : Object.keys(usage[0]);

	return (
		<section>
			<StyledTitleRowContainer>
				<Title>{SECTION_TITLE.MONTHLY_USAGE_TABLE_SECTION_TITLE}</Title>
			</StyledTitleRowContainer>
			<DataTable data={usage} keyList={keyList} isLoading={isLoading} />
		</section>
	);
};

export default ReportVodMonthlyUsageTableSection;
