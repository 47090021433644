import React, { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import theme from '@/styles/theme';
import InfoCircleWithTooltip from '@/components/common/molecules/InfoCircleWithTooltip';
import ReportVODTable from '@/components/report/vod/organisms/ReportVODTable';
import { TablePaginationConfig } from 'antd';
import useContractId from '@/hooks/useContractId';
import { getContractInfo } from '@/store/contractSlice';
import { useDispatch, useSelector } from 'react-redux';
import { downloadExcelFile } from '@/utils/download';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { fetchVODUsersExcel } from '@/api/report/vod';
import { PRODUCTS } from '@/utils/constants/products';
import Text from '@/components/common/atoms/Text';
import { useVodUserList } from '@/queries/report/vod/useVodQuery';
import { VODUser } from '@/types/vod/vod';
import { VOD_USER_LIST_INITIAL_DATA } from '@/utils/constants/vod';

const Over70Tooltip = styled(InfoCircleWithTooltip)`
	margin-left: 4px;
`;

const VODUserList = () => {
	const dispatch = useDispatch();
	const contractId = useContractId();
	const contractInfo = useSelector(getContractInfo);
	const [isLoadingDownload, setLoadingDownload] = useState(false);
	const [page, setPage] = useState(1);
	const { data, isLoading } = useVodUserList(contractId, page);
	const { data: users, total } = data ?? VOD_USER_LIST_INITIAL_DATA;
	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('VOD 학습자 이용 현황')));
	}, [contractInfo]);

	const columns = [
		{
			title: '학번/ID',
			dataIndex: 'student_no',
			key: 'student_no',
		},
		{
			title: '전공',
			dataIndex: 'major',
			key: 'major',
		},
		{
			title: '재생 VOD 수',
			dataIndex: 'used',
			key: 'used',
			render: (used: number) => <>{used >= 0 && <>{used}</>}</>,
		},
		{
			title: '재생 횟수',
			dataIndex: 'used',
			key: 'used',
			render: (used: number) => <>{used >= 0 && <>{used}</>}</>,
		},
		{
			title: (
				<>
					70% 이상 재생
					<Over70Tooltip
						tooltipColor={theme['gray-9']}
						iconColor={theme['gray-9']}
						tooltipContent="70% 이상 재생한 VOD 수"
						tooltipPlacement="top"
					/>
				</>
			),
			dataIndex: 'completionCount',
			key: 'completionCount',
			render: (completionCount: number) => <>{completionCount >= 0 && <>{completionCount}</>}</>,
		},
		{
			title: '상태',
			dataIndex: 'completion',
			key: 'completion',
			render: (completion: string) => (
				<>
					{completion === '미수료' ? <Text color={theme['volcano-6']}>{completion}</Text> : <>{completion}</>}
				</>
			),
		},
	];

	const onClickDownload = async (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setLoadingDownload(true);
		const data = await fetchVODUsersExcel(contractId);
		downloadExcelFile(
			data,
			`${contractInfo.partnerName}-${dayjs().format('YYYY-MM-DD')}-${PRODUCTS.VOD}-학습자 이용 현황`,
		);
		setLoadingDownload(false);
	};

	const onChangePage = ({ current }: TablePaginationConfig) => {
		const newPage = current === undefined ? 1 : current;
		setPage(newPage);
	};

	return (
		<ReportVODTable<VODUser>
			dataSource={users}
			columns={columns}
			total={total}
			totalRegistrants={total}
			loading={isLoading}
			rowKey="user_no"
			loadingDownload={isLoadingDownload}
			onClickDownload={onClickDownload}
			onPageChange={onChangePage}
		/>
	);
};

export default VODUserList;
