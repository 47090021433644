import React, { useEffect } from 'react';
import ReportAISummarySection from '@/components/report/ai/organisms/ReportAISummarySection';
import ReportAIMonthlyUsageGraphSection from '@/components/report/ai/organisms/ReportAIMonthlyUsageGraphSection';
import ReportAIMonthlyUsageTableSection from '@/components/report/ai/organisms/ReportAIMonthlyUsageTableSection';
import ReportAICheckDetailSection from '@/components/report/ai/organisms/ReportAICheckDetailSection';
import ReportAIInsightSection from '@/components/report/ai/organisms/ReportAIInsightSection';
import ReportAISatisfactionSection from '@/components/report/ai/organisms/ReportAISatisfactionSection';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import { ReportAIMonthlyUsageProvider } from '@/contexts/ReportAIMonthlyUsage';

const AIHome = () => {
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportAISummarySection />
			<ReportAIMonthlyUsageProvider>
				<ReportAIMonthlyUsageGraphSection />
				<ReportAIMonthlyUsageTableSection />
			</ReportAIMonthlyUsageProvider>
			<ReportAICheckDetailSection />
			<ReportAIInsightSection />
			<ReportAISatisfactionSection />
		</DetailSectionContainer>
	);
};

export default AIHome;
