import React from 'react';
import {
	StyledAttachmentSummary,
	StyledAttachmentSummaryContainer,
} from '@/components/common/atoms/AttachmentLinkSection';
import { FileOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import AttachmentFileItem from '@/components/common/atoms/AttachmentFileItem';
import { mixin } from '@/styles/theme';
import styled from 'styled-components';
import { downloadFile } from '@/utils/download';
import { useParams, useLocation } from 'react-router-dom';
import { downloadCampCouponAllAssignment } from '@/api/report/eduCampCoupon';
import { getEduPathAssignmentsAllDownload, getEduPathAssignmentsMentorAllDownload } from '@/api/report/eduPath';
import useContractId from '@/hooks/useContractId';
import { FileType, Reply } from '@/types/eduCamp/campCommon';

const StyledAssignmentSummary = styled(StyledAttachmentSummaryContainer)`
	${mixin.justifyContent('space-between')}
`;

export const StyledAttachmentFileSection = styled.article`
	margin-top: 20px;
`;

const AttachmentFileSection = ({
	reply,
	fileList,
	applicant,
}: {
	reply: undefined | null | boolean;
	fileList?: FileType[] | Reply[];
	applicant: any;
}) => {
	const contractId = useContractId();
	const { assignmentNo } = useParams();
	const isEduPathType = useLocation().pathname.includes('edu-path');

	const downloadAllAssignment = async () => {
		if (isEduPathType) {
			try {
				const { data } = reply
					? await getEduPathAssignmentsMentorAllDownload({
							contractId,
							eduApplicant: assignmentNo,
							eduClassroomAssignment: applicant.edu_classroom_assignment_no,
					  })
					: await getEduPathAssignmentsAllDownload({
							contractId,
							eduApplicant: applicant.applicant.no,
							eduClassroomAssignment: applicant.no,
					  });
				downloadFile(data, 'application/zip', `진로부트캠프-과제`);
			} catch (error) {
				console.error(error);
			}
		} else {
			try {
				const params = {
					assignmentNo: Number(assignmentNo),
				};
				const { data } = await downloadCampCouponAllAssignment(params);
				downloadFile(data, 'application/zip', `직무부트캠프-과제`);
			} catch (error) {
				console.error(error);
			}
		}
	};

	return (
		<StyledAttachmentFileSection>
			<StyledAssignmentSummary>
				<StyledAttachmentSummaryContainer>
					<FileOutlined style={{ fontSize: '12px', color: '#8C8C8C' }} />
					<StyledAttachmentSummary>파일</StyledAttachmentSummary>
					<StyledAttachmentSummary>{`(${fileList ? fileList.length : 0}개)`}</StyledAttachmentSummary>
				</StyledAttachmentSummaryContainer>
				{!!fileList && !!fileList.length && (
					<Button size={'small'} onClick={downloadAllAssignment}>
						전체 다운로드
					</Button>
				)}
			</StyledAssignmentSummary>
			{fileList && (
				<>
					{!!fileList.length && <Divider style={{ margin: '12px 0' }} />}
					<ul>
						{[...fileList].map((item: FileType | Reply) => (
							<AttachmentFileItem
								reply={reply}
								fileName={item.title}
								fileNo={item.no}
								assignment={item}
								key={`attachmentFile-${item.no}`}
							/>
						))}
					</ul>
				</>
			)}
		</StyledAttachmentFileSection>
	);
};

export default AttachmentFileSection;
