import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';

type IRangeDate = Moment | null;
type IRangeDateString = string | null;

export const emptyRangeDate: [IRangeDate, IRangeDate] = [null, null];

// RangePicker에 사용할 custom hook
const useRangeInput = (initialValue: [IRangeDate, IRangeDate]) => {
	const dateFormat = 'YYYY-MM-DD';
	// rangeDate -> rangeDateString으로 변환
	const getRangeDateStringItem = (rangeDate: IRangeDate) => {
		if (rangeDate === null) return '';
		return moment(rangeDate).format(dateFormat);
	};
	// 실제로 사용하는 rangeDate[] => rageDateString[]으로 변환
	const getRangeDateString = (rangeDate: [IRangeDate, IRangeDate]): [IRangeDateString, IRangeDateString] => {
		return [getRangeDateStringItem(rangeDate[0]), getRangeDateStringItem(rangeDate[1])];
	};
	const [rangeDate, setRangeDate] = useState<[IRangeDate, IRangeDate]>(initialValue);
	const [rangeDateString, setRangeDateString] = useState<[IRangeDateString, IRangeDateString]>(
		getRangeDateString(initialValue),
	);
	// rangeDate가 변할때마다 rangeDateString도 변환
	useEffect(() => {
		setRangeDateString(getRangeDateString(rangeDate));
	}, [rangeDate]);
	// dateString: [string, string] | string
	// 뒤에 string은 DatePicker일때 일듯.
	// [string, string] => RangePicker
	const onChange = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
		// value는 null일 수 있음. (allowClear 버튼을 눌렀을 떄)
		if (Array.isArray(value) && value.length) {
			setRangeDate(value);
		} else {
			setRangeDate(emptyRangeDate);
		}
	};
	const resetRangeDate = () => {
		setRangeDate(emptyRangeDate);
	};
	const isResetButtonDisabled = rangeDate.every(item => !item);

	// TODO: 배열로 return하고 싶으나 에러가 나서 못함.
	return { rangeDate, rangeDateString, onChange, resetRangeDate, isResetButtonDisabled, emptyRangeDate };
};

export default useRangeInput;
