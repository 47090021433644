import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { StyledBasicTable } from '@/components/common/styledComponents';
import { Col, Row } from 'antd';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import styled from 'styled-components';
import { PrivateApplicant } from '@/types/eduCamp/private';

interface RecordNo {
	no?: number;
}

const StyledCellSection = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const StyledTitle = styled.p`
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const ReportCampExternshipDetailAssignment = ({
	applicantData,
	isLoading,
}: {
	applicantData: PrivateApplicant;
	isLoading: boolean;
}) => {
	const params = useParams();
	const columnList = [
		{
			title: '제출자',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '차수',
			key: 'week',
			dataIndex: 'week',
			render: (week: number) => <p>{`${week}차`}</p>,
		},
		{
			title: '제출일',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (date: string) => <p>{formatDate(date, DATE_FORMAT.FULL_DATE)}</p>,
		},
		{
			title: '피드백',
			key: 'read_at',
			dataIndex: 'read_at',
			render: (date: string) => <StyledCellSection>{date ? '완료' : '대기'}</StyledCellSection>,
		},
		{
			title: '과제 조회',
			key: 'action',
			dataIndex: 'action',
			render: (_: string, record: RecordNo) => (
				<Link
					to={dynamicRoutePath(
						`${ROUTER_PATH.REPORT.EDU_CAMP_EXTERNSHIP.ASSIGNMENT_LIST}/${record.no}`,
						params,
					)}
				>
					조회하기
				</Link>
			),
		},
	];
	return (
		<section>
			<StyledTitle>과제 제출 현황</StyledTitle>
			<Row>
				<Col span={24}>
					<StyledBasicTable
						dataSource={applicantData.assignment}
						columns={columnList}
						pagination={false}
						loading={isLoading}
						rowKey={'no'}
						locale={{
							emptyText: <EmptyTableSection />,
						}}
					/>
				</Col>
			</Row>
		</section>
	);
};

export default ReportCampExternshipDetailAssignment;
