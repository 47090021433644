import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItemOmitKey } from '@/components/common/organisms/InfoBoxWithTitle/types';
import { EduPathApplicant } from '@/types/eduCamp/path';

const ReportEduPathCampCouponGraduate = ({
	eduPathApplicant,
	isLoading,
}: {
	eduPathApplicant: number[];
	isLoading: boolean;
}) => {
	const stepValue = [];
	const MAXIMUM_GRADUATE_STEP = 3;
	for (let i = 0; i <= MAXIMUM_GRADUATE_STEP; i++) {
		// eduPathApplicant에 값이 없을 때는 0명이기 때문에 0명으로 표시
		stepValue.push(eduPathApplicant[i] ?? 0);
	}

	const data = [
		{
			title: '1개 이상 수료',
			key: 'graduateStep1',
			content: stepValue[1],
		},
		{
			title: '2개 이상 수료',
			key: 'graduateStep2',
			content: stepValue[2],
		},
		{
			title: '3개 이상 수료',
			key: 'graduateStep3',
			content: stepValue[3],
		},
	];

	return (
		<section>
			<StyledSectionTitle>{'수료자 현황'}</StyledSectionTitle>
			<StyledInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.THREE_COLUMN}>
				{data &&
					data.map(({ title, tooltipContent, content, couponData }: InfoBoxWithTitleArrayItemOmitKey) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={content}
							key={title}
							couponData={couponData}
							isLoading={isLoading}
						/>
					))}
			</StyledInfoBoxContainer>
		</section>
	);
};

export default ReportEduPathCampCouponGraduate;
