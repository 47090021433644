import { ROUTER_PATH } from '@/utils/constants/paths';
import { Product, ReportRecord } from '@/types/common';

type ProductInfo = {
	name: Product;
	pathInfo: {
		[key: string]: string;
	};
};

type TProductInfoMap = {
	[key in string | number]: ProductInfo;
};

export const ProductInfoMap: TProductInfoMap = {
	1: {
		name: '콘텐츠',
		pathInfo: ROUTER_PATH.REPORT.CONTENT,
	},
	2: {
		name: 'AI 자기소개서 분석기',
		pathInfo: ROUTER_PATH.REPORT.AI,
	},
	3: {
		name: '멘토링',
		pathInfo: ROUTER_PATH.REPORT.MENTORING,
	},
	4: {
		name: '직무부트캠프(쿠폰형)',
		pathInfo: ROUTER_PATH.REPORT.EDU_CAMP_COUPON,
	},
	5: {
		name: '직무부트캠프(개설형)',
		pathInfo: ROUTER_PATH.REPORT.EDU_CAMP_PRIVATE,
	},
	'5b': {
		name: '직무부트캠프(재맞고)',
		pathInfo: ROUTER_PATH.REPORT.EDU_CAMP_JUMP_UP,
	},
	6: {
		name: '직무 VOD',
		pathInfo: ROUTER_PATH.REPORT.VOD,
	},
	8: {
		name: '익스턴십',
		pathInfo: ROUTER_PATH.REPORT.EDU_CAMP_EXTERNSHIP,
	},
	9: {
		name: '진로부트캠프',
		pathInfo: ROUTER_PATH.REPORT.EDU_PATH,
	},
	'9a': {
		name: '진로부트캠프',
		pathInfo: ROUTER_PATH.REPORT.EDU_PATH,
	},
	'9b': {
		name: '진로부트캠프',
		pathInfo: ROUTER_PATH.REPORT.EDU_PATH_V2,
	},
} as const;

type ProductKey = keyof typeof ProductInfoMap;
export const getProductKeyFromRecord = (record: ReportRecord): ProductKey => {
	// variation이 있으면 사용. 없으면 allowProduct중 맨 첫번째 것
	// 지금은 api에서 없으면 ''로 넘겨줌.
	if (record.variation === '' || !record.variation) return record.allowProductCode[0];
	return record.variation;
};

export const getProductHomePathFromKey = (key: ProductKey): string => {
	const productPathInfo = ProductInfoMap[key];
	if (!productPathInfo) {
		throw new Error(`유효하지 않은 key입니다 : ${key}`);
	}
	if (!productPathInfo.pathInfo.HOME) {
		throw new Error(`HOME path가 없는 product입니다 : ${key}`);
	}
	return productPathInfo.pathInfo.HOME;
};

export const getProductHomePathFromRecord = (record: ReportRecord): string => {
	const key = getProductKeyFromRecord(record);
	return getProductHomePathFromKey(key);
};
