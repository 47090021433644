import { /*getEduPathAllAssignmentsDownload,*/ getEduPathApplicantListDownload } from '@/api/report/eduPath';
import useContractId from '@/hooks/useContractId';
import usePartnerName from '@/hooks/usePartnerName';
import { PRODUCTS } from '@/utils/constants/products';
import { /*downloadFile,*/ downloadExcelFile } from '@/utils/download';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState, useCallback } from 'react';

const EduPathUserListDownloadButtons = () => {
	const contractId = useContractId();
	const partnerName = usePartnerName();
	// const [isAssignmentsDownloadLoading, setAssignmentDownloadLoading] = useState(false);
	const [isApplicantsDownloadLoading, setApplicantsDownloadLoading] = useState(false);

	// const downloadEduPathAssignment = useCallback(async () => {
	// 	try {
	// 		setAssignmentDownloadLoading(true);
	// 		const { data } = await getEduPathAllAssignmentsDownload(contractId);
	// 		downloadFile(data, 'application/zip', `${partnerName}-${PRODUCTS.EDU_PATH}-신청자 현황`);
	// 	} catch (error) {
	// 		console.error(error);
	// 	} finally {
	// 		setAssignmentDownloadLoading(false);
	// 	}
	// }, [contractId, partnerName]);

	const downloadEduPathApplicantsList = useCallback(async () => {
		try {
			setApplicantsDownloadLoading(true);
			const { data } = await getEduPathApplicantListDownload(contractId);
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.EDU_PATH}-신청자 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setApplicantsDownloadLoading(false);
		}
	}, [contractId, partnerName]);

	return (
		<div>
			{/*<Button*/}
			{/*	icon={<DownloadOutlined />}*/}
			{/*	onClick={downloadEduPathAssignment}*/}
			{/*	loading={isAssignmentsDownloadLoading}*/}
			{/*>*/}
			{/*	과제 일괄 다운로드*/}
			{/*</Button>*/}
			<Button
				icon={<DownloadOutlined />}
				onClick={downloadEduPathApplicantsList}
				loading={isApplicantsDownloadLoading}
			>
				신청자 명단 다운로드
			</Button>
		</div>
	);
};

export default EduPathUserListDownloadButtons;
