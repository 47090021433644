import React, { ReactNode } from 'react';
import theme from '@/styles/theme';
import styled from 'styled-components';
import Text from '@/components/common/atoms/Text';
import InfoCircleWithTooltip, { TooltipPlacement } from '@/components/common/molecules/InfoCircleWithTooltip';

export interface tableHeaderWithInfoCircleType {
	title: string;
	tooltipContent?: ReactNode;
	tooltipPlacement?: TooltipPlacement;
}

const StyledTextWithMargin = styled(Text)`
	margin-right: 4px;
`;

const TableHeaderWithInfoCircle = (item: tableHeaderWithInfoCircleType) => {
	return (
		<>
			<StyledTextWithMargin>{item.title}</StyledTextWithMargin>
			{item.tooltipContent && (
				<InfoCircleWithTooltip
					tooltipPlacement={item.tooltipPlacement}
					tooltipColor={theme['gray-9']}
					tooltipContent={item.tooltipContent}
					iconColor={theme['gray-9']}
					fontSize="14px"
				/>
			)}
		</>
	);
};

export default TableHeaderWithInfoCircle;
