import React from 'react';
import { Avatar } from 'antd';
import theme from '@/styles/theme';
import Text from '@/components/common/atoms/Text';
import { CheckCircleFilled } from '@ant-design/icons';
import Chip from '@/components/common/Chip';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import { MentoringLogItemUserList } from '@/types/mentoring/mentoring';

const StyledUserInfoContainer = styled.div`
	margin-bottom: 24px;
	${flexAlignCenter};
`;

const StyledUserInfoTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const StyledUserInfoTextWrapper = styled.div`
	${flexAlignCenter}
	> * + * {
		margin-left: 4px;
	}
`;

const StyledUserInfoTextWrapperWithMargin = styled(StyledUserInfoTextWrapper)`
	margin-bottom: 2px;
	line-height: 24px;
	${flexAlignCenter}
`;

const StyledGrayText = styled(Text)`
	color: ${props => props.theme['gray-6']};
	font-size: 12px;
`;

const StyledBelongWrapper = styled.div`
	height: 16px;
	${flexAlignCenter};
`;

const CommonUserInformation = ({ userList }: { userList?: MentoringLogItemUserList }) => {
	const hasMatchedItem = userList?.matching && Object.values(userList.matching).includes(true);
	return (
		<StyledUserInfoContainer>
			<Avatar
				style={{ backgroundColor: theme['red-2'], verticalAlign: 'middle', marginRight: '8px' }}
				size="large"
			>
				{userList?.nickname[0] ?? ''}
			</Avatar>
			<StyledUserInfoTextContainer>
				<StyledUserInfoTextWrapperWithMargin>
					<Text color={theme['gray850']} fontSize="16px" fontWeight={600}>
						{userList?.nickname ?? ''}
					</Text>
					{userList?.belong && (
						<StyledBelongWrapper>
							<Text color={theme['gray500']} fontSize="14px" style={{ marginRight: '2px' }}>
								{userList.belong}
							</Text>
							<CheckCircleFilled style={{ color: theme['blue-7'], fontSize: '12px' }} />
						</StyledBelongWrapper>
					)}
				</StyledUserInfoTextWrapperWithMargin>
				<StyledUserInfoTextWrapper>
					<StyledGrayText>{userList?.grade_name ?? ''}</StyledGrayText>
					<StyledGrayText>·</StyledGrayText>
					<StyledGrayText>채택률 {userList?.choice_rate ?? 0}%</StyledGrayText>
					{hasMatchedItem && (
						<>
							<StyledGrayText>·</StyledGrayText>
							{userList?.matching?.company && <Chip>회사</Chip>}
							{userList?.matching?.industry && <Chip>산업</Chip>}
							{userList?.matching?.job && <Chip>직무</Chip>}
							{userList?.matching?.univ && <Chip>직무</Chip>}
							<StyledGrayText>일치</StyledGrayText>
						</>
					)}
				</StyledUserInfoTextWrapper>
			</StyledUserInfoTextContainer>
		</StyledUserInfoContainer>
	);
};

export default CommonUserInformation;
