export const TabIndex = {
	Report: '1',
	Community: '2',
} as const;

export const insightPeriodData = [
	{ label: '누적', value: 'default' as const, key: '1' },
	{ label: '한주', value: 'weekly' as const, key: '2' },
	{ label: '한달', value: 'monthly' as const, key: '3' },
	{ label: '최근 3개월', value: '3monthly' as const, key: '4' },
	{ label: '최근 6개월', value: '6monthly' as const, key: '5' },
];
