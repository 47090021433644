import { useCallback, useMemo } from 'react';
import TableHeaderWithInfoCircle from '@/components/common/molecules/tableHeaderWithInfoCircle';
import { Button, Col, ConfigProvider, Pagination, Row } from 'antd';
import { StyledUserCountContainer } from '@/components/UserList/UserList';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { StyledBasicTable } from '@/components/common/styledComponents';
import styled from 'styled-components';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { ColumnsType } from 'antd/lib/table';
import ProgressCard from '@/components/common/atoms/ProgressCard';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { useNavigate, useParams } from 'react-router-dom';
import { EduPathV2Applicant, EduPathV2ApplicantDetail } from '@/types/eduCamp/path';
import EduPathV2UserListDownloadButtons from '@/components/TabContent/EduPathV2/Coupon/UserList/EduPathV2UserListDownloadButtons';
import { useUpdateListHeader } from '@/hooks/useUpdateListHeader';
import { getPhoneNumberFormat } from '@/utils/string';
import { useEduCampPathV2ApplicantTablePaginationData } from '@/hooks/useEduCampPathV2ApplicantListData';
import { checkIsLastRowSpan } from '@/utils/data';

const StyledDateString = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const StyledEmptyText = styled(StyledDateString)`
	color: ${props => props.theme['gray-7']};
`;

const StyledTitleBox = styled.div`
	display: flex;
	align-items: flex-start;
	${mixin.ellipsis(2)}
`;

const StyledTableWrapper = styled.div`
	.coupon-code {
		padding: 12px;
	}

	.ant-table-row {
		& .ant-table-cell {
			border-bottom: none;
		}

		&.border-bottom .ant-table-cell,
		.ant-table-cell[rowspan] {
			border-bottom: 1px solid #f0f0f0;
		}
	}
`;

const StyledPaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 24px;
`;

const StyledButtonsContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const StyledLinksContainer = styled(StyledButtonsContainer)`
	display: flex;
	gap: 8px;
	flex-direction: column;
`;

const infoTooltipScript = (
	<>
		완료 : 캠프 수강을 완료하였습니다.
		<br />
		미완료 : VOD, 과제 중 한 가지 이상을 완료하지 않았습니다.
	</>
);

type EduPathV2UserListTableRow = Omit<EduPathV2ApplicantDetail, 'camps'> & EduPathV2Applicant;

const EduPathV2UserList = () => {
	const params = useParams();
	const navigate = useNavigate();

	useUpdateListHeader('캠프 신청자 현황');

	const { flattenedData, rowSpans, total, page, perPage, setPage, setPerPage, isLoading, noteUrl, expireDate } =
		useEduCampPathV2ApplicantTablePaginationData();

	const onClickUserDetailButton = (userNo: EduPathV2UserListTableRow['user_no']) => {
		if (!userNo) {
			alert('신청 내역이 없는 유저입니다.');
		}
		return navigate(
			dynamicRoutePath(ROUTER_PATH.REPORT.EDU_PATH_V2.USER_DETAIL, {
				...params,
				applicantNo: userNo,
			}),
		);
	};

	const onChange = (page: number, perPage: number) => {
		setPage(page);
		setPerPage(perPage);
	};

	const onCell = useCallback(
		(_: EduPathV2UserListTableRow, rowIndex: number | undefined) => {
			if (rowIndex === undefined) return {};
			return { rowSpan: rowSpans[rowIndex] };
		},
		[rowSpans],
	);

	const tableColumns: ColumnsType<EduPathV2UserListTableRow> = useMemo(
		() => [
			{
				title: '쿠폰 번호',
				dataIndex: 'coupon_code',
				key: 'coupon_codes',
				width: '112px',
				className: 'coupon-code',
				align: 'center' as const,
				onCell,
			},
			{
				title: '이름',
				dataIndex: 'name',
				key: 'user_name',
				width: '80px',
				align: 'center' as const,
				onCell,
			},
			{
				title: '휴대폰 번호',
				dataIndex: 'mobile',
				key: 'mobile',
				width: '140px',
				align: 'center' as const,
				render: (mobile: string) => (
					<StyledDateString>{mobile ? getPhoneNumberFormat(mobile) : ' - '}</StyledDateString>
				),
				onCell,
			},
			{
				title: '수강 VOD',
				dataIndex: 'vod_count',
				key: 'vod_count',
				width: '80px',
				align: 'center' as const,
				onCell,
			},
			{
				title: '신청한 실무과제',
				dataIndex: 'camp_title',
				key: 'camp_title',
				width: '280px',
				align: 'left' as const,
				render: (camp_title: EduPathV2UserListTableRow['camp_title']) => {
					return (
						<StyledTitleBox>
							{camp_title ? (
								<StyledDateString>{camp_title}</StyledDateString>
							) : (
								<StyledEmptyText>아직 신청한 실무과제가 없어요.</StyledEmptyText>
							)}
						</StyledTitleBox>
					);
				},
			},
			{
				title: '수강 현황',
				key: 'progress',
				width: '120px',
				align: 'center' as const,
				render: (detail: EduPathV2UserListTableRow) => {
					const vodStatus = detail.vod;
					const assignmentStatus = detail.assignment ?? false;
					return (
						<ProgressCard
							progressState={[
								{
									title: 'VOD',
									status: [vodStatus],
								},
								{
									title: '과제',
									status: [assignmentStatus],
								},
							]}
							dotPosition={'left'}
						/>
					);
				},
			},
			{
				title: () => (
					<TableHeaderWithInfoCircle
						title={'상태'}
						tooltipContent={infoTooltipScript}
						tooltipPlacement={'bottom'}
					/>
				),
				dataIndex: 'status',
				key: 'status',
				width: '100px',
				align: 'center' as const,
				render: (status: boolean) => (status ? '완료' : '미완료'),
			},
			{
				title: '내역 조회 및 다운로드',
				key: 'user_no',
				width: '182px',
				align: 'center' as const,
				onCell,
				render: (_, detail) => {
					return (
						<StyledLinksContainer>
							<Button type={'link'} onClick={() => onClickUserDetailButton(detail.user_no)}>
								수강 현황
							</Button>
						</StyledLinksContainer>
					);
				},
			},
		],
		[params, rowSpans],
	);

	return (
		<>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							총 신청자 수 :&nbsp;
							<Text color={theme['blue-7']}>{total}</Text>명
						</Text>
						<StyledButtonsContainer>
							<EduPathV2UserListDownloadButtons noteUrl={noteUrl} expireDate={expireDate} />
						</StyledButtonsContainer>
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledTableWrapper>
							<StyledBasicTable
								dataSource={flattenedData}
								columns={tableColumns}
								loading={isLoading}
								pagination={false}
								isLoading={isLoading}
								rowKey={'no'}
								rowClassName={(_, index) =>
									checkIsLastRowSpan(index, rowSpans) ? 'border-bottom' : ''
								}
							/>
						</StyledTableWrapper>
						<StyledPaginationContainer>
							<Pagination
								current={page}
								total={total}
								pageSize={perPage}
								onChange={onChange}
								showSizeChanger={true}
							/>
						</StyledPaginationContainer>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};
export default EduPathV2UserList;
