import React, { useContext } from 'react';
import MixedChart from '@/components/common/MixedChart';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import SECTION_TITLE from '@/utils/constants/section-title';
import { ReportAIMonthlyUsageContext } from '@/contexts/ReportAIMonthlyUsage';

const ReportAIMonthlyUsageGraphSection = () => {
	const { reportAIMonthlyUsage, isLoading } = useContext(ReportAIMonthlyUsageContext);

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.MONTHLY_USAGE_GRAPH_SECTION_TITLE}</StyledSectionTitle>
			<MixedChart
				columnPlotData={reportAIMonthlyUsage.barGraph}
				linePlotData={reportAIMonthlyUsage.lineGraph}
				isLoading={isLoading}
			/>
		</section>
	);
};

export default ReportAIMonthlyUsageGraphSection;
