import axiosClient from '@/api';
const JUMP_UP_PREFIX = '/educamp';
const INFO_PREFIX = '/info';
const SUMMARY_PREFIX = '/abstract';
const SATISFACTION_PREFIX = '/satisfaction';
const CAMP_PREFIX = '/camp';
const APPLICANT_PREFIX = '/applicant';
const LIST_PREFIX = '/list';
const ATTENDANCE_PREFIX = '/attendance';
const ASSIGNMENT_PREFIX = '/assignment';
const SCHEDULE_PREFIX = '/schedule';
const CLASSROOM_PREFIX = '/classroom';
const DOWNLOAD_PREFIX = '/download';

export const getJumpUpSummarySectionRequest = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${JUMP_UP_PREFIX}${SUMMARY_PREFIX}${INFO_PREFIX}`, { params });
};

export const getJumpUpSatisfactionRequest = async (params: { contractNo: number; page: number }) => {
	// TODO: 테스트용 - 실제 배포시에는 삭제 예정
	return await axiosClient.get(`${JUMP_UP_PREFIX}${SATISFACTION_PREFIX}${INFO_PREFIX}`, {
		params,
	});
};

export const getJumpUpCampList = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${JUMP_UP_PREFIX}${CAMP_PREFIX}${LIST_PREFIX}`, {
		params,
	});
};

export const getJumpUpApplicantList = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${JUMP_UP_PREFIX}${APPLICANT_PREFIX}${LIST_PREFIX}`, {
		params,
	});
};

export const getJumpUpAttendanceAndAssignment = async (params: { applicant_no: number }) => {
	return await axiosClient.get(`${JUMP_UP_PREFIX}${ATTENDANCE_PREFIX}/status`, {
		params,
	});
};

export const getJumpUpCampSchedule = async (params: { applicant_no: number }) => {
	return await axiosClient.get(`${JUMP_UP_PREFIX}${CAMP_PREFIX}${SCHEDULE_PREFIX}`, {
		params,
	});
};

export const getJumpUpCampAssignment = async (params: { assignment_no: number; applicant_no: number }) => {
	return await axiosClient.get(`${JUMP_UP_PREFIX}${CLASSROOM_PREFIX}${ASSIGNMENT_PREFIX}/detail`, {
		params,
	});
};

export const downloadApplicantToExcel = async (params: { contractNo: number }) => {
	return await axiosClient.getFile(`${JUMP_UP_PREFIX}${APPLICANT_PREFIX}${DOWNLOAD_PREFIX}`, {
		params,
	});
};

export const downloadCampJumpUpListToExcel = async (params: { contractNo: number }) => {
	return await axiosClient.getFile(`${JUMP_UP_PREFIX}${CAMP_PREFIX}${DOWNLOAD_PREFIX}`, {
		params,
	});
};
