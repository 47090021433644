import React from 'react';
import styled from 'styled-components';

export interface propTypes {
	children: React.ReactNode;
}

const StyledInfoBox = styled.div`
	background-color: ${props => props.theme['gray-2']};
	padding: 24px 32px;
	width: 100%;
	border-radius: 8px;
`;

const InfoBox = ({ children }: propTypes) => {
	return <StyledInfoBox>{children}</StyledInfoBox>;
};

export default InfoBox;
