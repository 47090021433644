import { useEduCampPathV2Attendance } from '@/queries/report/eduCampPath/eduCampPathV2Query';
import { useParams } from 'react-router-dom';
import useContractId from '@/hooks/useContractId';

export const useEduCampPathV2AttendanceListData = () => {
	const { applicantNo } = useParams();
	const contractId = useContractId();
	const { data, isLoading, isFetching } = useEduCampPathV2Attendance(contractId, applicantNo);

	return { data, isLoading, isFetching };
};
