import React, { useContext } from 'react';
import styled from 'styled-components';
import theme, { mixin } from '@/styles/theme';
import Text from '@/components/common/atoms/Text';
import { cdnBaseurl } from '@/utils/constants/url';
import { useNavigate, useParams } from 'react-router-dom';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { AuthContext } from '@/contexts/AuthProvider';

// export interface propTypes {};

const StyledLogoContainer = styled.div`
	width: 236px;
	height: 64px;
	padding: 20px 24px;
	cursor: pointer;
	& > :not(:last-child) {
		margin-right: 6px;
	}
	${mixin.flexBox()};
	${mixin.alignItems('center')};
`;

const Logo = () => {
	const faviconSrc = `${cdnBaseurl}/favicon/favicon-24x24.png`;
	const navigate = useNavigate();
	const params = useParams();
	const { isLogin } = useContext(AuthContext);

	const handleLogoClick = () => {
		if (isLogin) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.HOME, params));
		return navigate(ROUTER_PATH.LOGIN);
	};

	return (
		<StyledLogoContainer onClick={handleLogoClick}>
			<img src={faviconSrc} />
			<Text color={theme['blue-7']} fontWeight={600} fontSize="16px">
				코멘토 제휴 관리자
			</Text>
		</StyledLogoContainer>
	);
};

export default Logo;
