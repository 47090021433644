import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledProfileSection = styled.article`
	display: flex;
	align-items: center;
`;

const StyledProfileInfo = styled.div`
	margin-left: 8px;
`;

const StyledProfileName = styled.p`
	margin-bottom: 2px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${props => props.theme['gray850']};
`;

const StyledProfileDate = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: ${props => props.theme['gray-6']};
`;

const ProfileSection = ({ name, createdAt, isMentor }: { name?: string; createdAt?: string; isMentor: boolean }) => {
	const avatarColors = [
		'#f5b3b3',
		'#f3c499',
		'#f0db80',
		'#b4d2a9',
		'#91cfd3',
		'#acc5ea',
		'#ceb9e2',
		'#b0aba4',
	] as const;

	const getAvatarBackground = () => {
		const maximum = avatarColors.length;
		const minimum = 0;
		const randomNum = Math.floor(Math.random() * (maximum - minimum) + minimum);
		return avatarColors[randomNum];
	};

	return (
		<StyledProfileSection>
			{isMentor ? (
				<Avatar size={48} style={{ backgroundColor: getAvatarBackground() }}>
					{!!name && name[0]}
				</Avatar>
			) : (
				<Avatar size={48} icon={<UserOutlined />} />
			)}
			<StyledProfileInfo>
				<StyledProfileName>{name ? name : '멘티'}</StyledProfileName>
				<StyledProfileDate>{createdAt ? createdAt : ''}</StyledProfileDate>
			</StyledProfileInfo>
		</StyledProfileSection>
	);
};

export default ProfileSection;
