import React from 'react';
import { ConfigProvider, Table } from 'antd';
import { numberToLocalString } from '@/utils/number';
import styled from 'styled-components';
import Empty from '@/components/common/Empty';

// 동적 키
type PropItemTypes = {
	[key: string]: string | number | JSX.Element;
};

interface PropTypes {
	data: PropItemTypes[];
	keyList: string[];
	isLoading?: boolean;
	width?: string;
}

const StyledTable = styled(Table)`
	.ant-table-thead .ant-table-cell {
		&:first-child {
			background-color: ${props => props.theme['gray-4']};
		}
		&:not(:first-child) {
			text-align: center;
			font-weight: 600;
		}
	}
	.ant-table-row .ant-table-cell {
		&:first-child {
			background-color: ${props => props.theme['gray-2']};
			text-align: right;
			font-weight: 600;
		}
		&:not(:first-child) {
			text-align: center;
		}
	}
	// table head 항목 사이에 divider 나오는거 제거
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
		display: none;
	}

	.ant-table-cell {
		color: ${props => props.theme['gray-9']};
	}
`;

const StyledEmpty = styled(Empty)`
	padding: 20px;
`;

const DataTable = ({ data, keyList, isLoading, width = 'auto' }: PropTypes) => {
	const dataSourceMap = data.map((data, index) => {
		return {
			key: String(index),
			...data,
		};
	});

	const columnsMap = keyList.map(key => {
		if (key === 'xAxisTitle') {
			return {
				title: null,
				dataIndex: key,
				key,
				width: '120px',
				fixed: true,
			};
		} else {
			return {
				title: key,
				dataIndex: key,
				width,
				key,
				render: (value: number | JSX.Element) => {
					if (typeof value === 'object') {
						return value;
					}
					return numberToLocalString(value);
				},
			};
		}
	});

	return (
		<ConfigProvider renderEmpty={() => <StyledEmpty />}>
			<StyledTable
				dataSource={dataSourceMap}
				columns={columnsMap}
				pagination={false}
				bordered={false}
				loading={isLoading}
				scroll={keyList.length > 6 ? { x: 1500 } : undefined}
			/>
		</ConfigProvider>
	);
};

export default DataTable;
