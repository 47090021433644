import React from 'react';
import { Mix } from '@ant-design/charts';
import styled from 'styled-components';
import EmptyWithOverlay from '@/components/common/EmptyWithOverlay';
import SkeletonChart from '@/components/common/SkeletonChart';

const StyledMixedChartContainer = styled.div`
	position: relative;
`;

interface PlotData {
	date: string;
	value: number;
}

interface MixedChartProps {
	columnPlotData: PlotData[];
	linePlotData: PlotData[];
	isLoading?: boolean;
}

const MixedChart = ({ columnPlotData, linePlotData, isLoading }: MixedChartProps) => {
	const isEmpty = columnPlotData.length === 0;
	const config = {
		appendPadding: 8,
		tooltip: {
			shared: true,
		},
		syncViewPadding: true,
		plots: [
			{
				type: 'column' as const,
				options: {
					data: columnPlotData,
					xField: 'date',
					yField: 'value',
					yAxis: {
						position: 'left' as const,
					},
					isGroup: true,
					meta: {
						date: {
							sync: true,
						},
						value: {
							alias: '열람 횟수',
						},
					},
					color: '#2A7DE1',
				},
			},
			{
				type: 'line' as const,
				options: {
					data: linePlotData,
					xField: 'date',
					yField: 'value',
					yAxis: {
						position: 'right' as const,
					},
					isGroup: true,
					meta: {
						date: {
							sync: true,
						},
						value: {
							alias: '사용자 수',
						},
					},
					color: '#F3A77E',
				},
			},
		],
	};

	return (
		<StyledMixedChartContainer>
			{isLoading ? (
				<SkeletonChart />
			) : (
				<EmptyWithOverlay isEmpty={isEmpty}>
					<Mix {...config} />
				</EmptyWithOverlay>
			)}
		</StyledMixedChartContainer>
	);
};

export default MixedChart;
