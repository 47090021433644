import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import useContractId from '@/hooks/useContractId';
import { convertParamsIntoNumber } from '@/utils/params';
import MentoringDetailQA from '@/components/TabContent/Mentoring/Log/Detail/MentoringDetailQA';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import { useMentoringDetailQuery } from '@/queries/report/mentoring/useMentoringQuery';
import { MENTORING_LOG_DETAIL_INITIAL_DATA } from '@/utils/constants/mentoring';

const MentoringLogDetail = () => {
	const params = useParams();
	const requestNo = convertParamsIntoNumber(params.id);
	const contractId = useContractId();
	const dispatch = useDispatch();
	const { data, isLoading } = useMentoringDetailQuery(contractId, requestNo);
	const mentoringLog = data?.data.result ?? MENTORING_LOG_DETAIL_INITIAL_DATA;

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle('상세 내용'));
	}, []);

	useEffect(() => {
		animateScroll.scrollToTop({
			delay: 100,
			duration: 100,
			smooth: true,
		});
	}, []);

	return <MentoringDetailQA data={mentoringLog} isLoading={isLoading} />;
};

export default MentoringLogDetail;
