import useLogout from '@/hooks/useLogout';
import { useEffect } from 'react';

const Logout = () => {
	const handleLogout = useLogout();

	useEffect(() => {
		handleLogout();
	}, []);

	// only logout 용도.
	// logout 성공 실패 여부에 관계없이 login 페이지로 Redirect되므로 보여줄게 없음.
	return <></>;
};

export default Logout;
