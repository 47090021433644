export const CONTENT_SUMMARY_INITIAL_DATA = {
	registrantsUserCount: 0,
	totalViewsCount: 0,
	usersCount: 0,
	utilizationRate: 0,
};

export const CONTENT_INSIGHT_INITIAL_DATA = {
	data: {
		company: {
			contentName: [],
			contentRatio: [],
		},
		job: {
			jobName: [],
			jobRatio: [],
		},
	},
};
