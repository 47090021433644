import React from 'react';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import Title from '@/components/common/Typography/Title';
import DataTable from '@/components/common/organisms/DataTable';
import { convertParamsIntoNumber } from '@/utils/params';
import { useParams } from 'react-router-dom';
import { useMentoringSituationQuery } from '@/queries/report/mentoring/useMentoringQuery';
import { DataByCategoryItemResponse } from '@/types/mentoring/mentoring';
import { DATA_BY_CATEGORY_INITIAL_DATA, DATA_BY_CATEGORY_KEY, KEY_LIST } from '@/utils/constants/mentoring';

const ReportMentoringDataByCategorySection = () => {
	// TODO: contractId도 Provider로 변경
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	const { data, isLoading } = useMentoringSituationQuery(contractId);
	const { category } = data?.data.result ?? DATA_BY_CATEGORY_INITIAL_DATA;

	const setMentoringSituation = (mentoringData: DataByCategoryItemResponse) => {
		const { ce_avg, ce_use, ce_user, in_avg, in_use, in_user, qa_avg, qa_use, qa_user } = mentoringData;
		if (qa_user + ce_user + in_user === 0 && qa_use + ce_use + in_use === 0 && qa_avg + ce_avg + in_avg === 0)
			return [];
		return [
			{
				xAxisTitle: DATA_BY_CATEGORY_KEY.QA,
				'사용자 수': qa_user,
				'질문 횟수': qa_use,
				'1인당 평균 질문 횟수': qa_avg,
			},
			{
				xAxisTitle: DATA_BY_CATEGORY_KEY.CE,
				'사용자 수': ce_user,
				'질문 횟수': ce_use,
				'1인당 평균 질문 횟수': ce_avg,
			},
			{
				xAxisTitle: DATA_BY_CATEGORY_KEY.IN,
				'사용자 수': in_user,
				'질문 횟수': in_use,
				'1인당 평균 질문 횟수': in_avg,
			},
			{
				xAxisTitle: DATA_BY_CATEGORY_KEY.TOTAL,
				'사용자 수': qa_user + ce_user + in_user,
				'질문 횟수': qa_use + ce_use + in_use,
				'1인당 평균 질문 횟수': qa_avg + ce_avg + in_avg,
			},
		];
	};

	const situationData = setMentoringSituation(category);

	return (
		<section>
			<StyledTitleRowContainer>
				<Title>카테고리별 현황</Title>
			</StyledTitleRowContainer>
			<DataTable
				isLoading={isLoading}
				data={situationData}
				keyList={situationData.length === 0 ? [] : Object.values(KEY_LIST)}
				width={'298px'}
			/>
		</section>
	);
};

export default ReportMentoringDataByCategorySection;
