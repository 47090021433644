import React from 'react';
import { Rate } from 'antd';
import SatisfactionProgressBar, {
	propTypes as SatisfactionProgressBarPropTypes,
} from '@/components/common/atoms/SatisfactionProgressBar';
import styled from 'styled-components';

export interface propTypes extends SatisfactionProgressBarPropTypes {
	value: number;
	isLoading: boolean;
}

const StyledSatisfactionProgressBar = styled(SatisfactionProgressBar)`
	width: 237px;
`;

const StyledRate = styled(Rate)`
	font-size: 15px;
	margin-right: 10px;
	.ant-rate-star:not(:last-child) {
		margin-right: 1.3px;
	}
	.ant-rate-star-full {
		color: ${props => props.theme['gray-6']};
	}
	.ant-rate-star-zero .ant-rate-star-second {
		color: ${props => props.theme['gray-4']};
	}
`;

const RateWithProgressBar = ({ value, percent, size, isLoading }: propTypes) => {
	return (
		<div className="d-flex align-center">
			<StyledRate value={value} disabled />
			<StyledSatisfactionProgressBar percent={percent} size={size} isLoading={isLoading} />
		</div>
	);
};

export default RateWithProgressBar;
