import axiosClient from '@/api';
import { VODLogResponse } from '@/components/TabContent/VOD/Log';
import { VODMonthlyUsage, VODSatisfactionSummary, VODSummary, VODUserResponse } from '@/types/vod/vod';

export const fetchVODSummary = async (contractId: number): Promise<VODSummary> => {
	const { data } = await axiosClient.get<VODSummary>(`/vod/summary/${contractId}`);
	return data;
};

export const fetchVODMonthlyUsage = async (contractId: number): Promise<VODMonthlyUsage[]> => {
	const { data } = await axiosClient.get<VODMonthlyUsage[]>(`/vod/monthly/${contractId}`);
	return data;
};

export const fetchVODSatisfaction = async (contractId: number): Promise<VODSatisfactionSummary> => {
	const { data } = await axiosClient.get<VODSatisfactionSummary>(`/vod/satisfaction/${contractId}`, {
		params: { type: 'vodList' },
	});
	return data;
};

export const fetchVODUsers = async (contractId: number, page: number): Promise<VODUserResponse> => {
	const { data } = await axiosClient.get<VODUserResponse>(`/vod/used/status/${contractId}`, { params: { page } });
	return data;
};

export const fetchVODUsersExcel = async (contractId: number) => {
	const { data } = await axiosClient.getFile(`/vod/used/status/${contractId}?excel=1`);
	return data;
};

export const fetchVODLogs = async (contractId: number, page: number): Promise<VODLogResponse> => {
	const { data } = await axiosClient.get(`/vod/playback/history/${contractId}`, { params: { page } });
	return data;
};

export const fetchVODLogsExcel = async (contractId: number) => {
	const { data } = await axiosClient.getFile(`/vod/playback/history/${contractId}?excel=1`);
	return data;
};
