import React, { useContext, useState } from 'react';
import Title from '@/components/common/Typography/Title';
import DataTable from '@/components/common/organisms/DataTable';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import { ReportAIMonthlyUsageContext } from '@/contexts/ReportAIMonthlyUsage';
import { AIMonthlyUsageExcelRequest } from '@/api/report/AI';
import useContractId from '@/hooks/useContractId';
import usePartnerName from '@/hooks/usePartnerName';
import { downloadExcelFile } from '@/utils/download';
import { PRODUCTS } from '@/utils/constants/products';
import { covertDataToTableData } from '@/utils/table';

const ReportAIMonthlyUsageTableSection = () => {
	const { reportAIMonthlyUsage, isLoading } = useContext(ReportAIMonthlyUsageContext);
	const contractId = useContractId();
	const partnerName = usePartnerName();
	const [isLoadingDownload, setLoadingDownload] = useState(false);

	const convertedData = covertDataToTableData(reportAIMonthlyUsage.table, '사용자 수', '분석 횟수');
	const keyList = convertedData.length === 0 ? [] : Object.keys(convertedData[0]);

	const handleClickDownloadButton = async () => {
		try {
			setLoadingDownload(true);
			const { data } = await AIMonthlyUsageExcelRequest(contractId);
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.AI}-월별 이용 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	return (
		<section>
			<StyledTitleRowContainer>
				<Title>{SECTION_TITLE.MONTHLY_USAGE_TABLE_SECTION_TITLE}</Title>
				<DownloadButton loading={isLoadingDownload} onClick={handleClickDownloadButton}>
					다운로드
				</DownloadButton>
			</StyledTitleRowContainer>
			<DataTable data={convertedData} keyList={keyList} isLoading={isLoading} />
		</section>
	);
};

export default ReportAIMonthlyUsageTableSection;
