import { useParams } from 'react-router-dom';
import { convertParamsIntoNumber } from '@/utils/params';

const useContractId = () => {
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	return contractId;
};

export default useContractId;
