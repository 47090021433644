import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';

export interface IHeaderState {
	headerTitle: string;
	headerSubTitle: string;
}

const initialState: IHeaderState = {
	headerTitle: '',
	headerSubTitle: '',
};

const headerSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setHeaderTitle: (state, action: PayloadAction<string>) => {
			state.headerTitle = action.payload;
		},
		setHeaderSubTitle: (state, action: PayloadAction<string>) => {
			state.headerSubTitle = action.payload;
		},
	},
});

export const { setHeaderTitle, setHeaderSubTitle } = headerSlice.actions;

export const getHeaderTitle = (state: RootState) => state.header.headerTitle;
export const getHeaderSubTitle = (state: RootState) => state.header.headerSubTitle;

export default headerSlice.reducer;
