import React from 'react';
import MentoringLogDetailAnswer from '@/components/Mentoring/Log/Detail/MentoringLogDetailAnswer';
import styled from 'styled-components';
import { MentoringLogDetailAnswerItem } from '@/types/mentoring/mentoring';

interface MentoringLogDetailAnswerListProps {
	answer: MentoringLogDetailAnswerItem[];
	isLoading?: boolean;
	type: string;
}

const StyledMentoringLogDetailAnswerContainer = styled.div`
	> * + * {
		margin-top: 24px;
	}
`;

const MentoringLogDetailAnswerList = ({ answer, isLoading, type }: MentoringLogDetailAnswerListProps) => {
	return (
		<StyledMentoringLogDetailAnswerContainer>
			{isLoading && !!answer.length ? (
				<>
					<MentoringLogDetailAnswer answer={answer[0]} type={type} isLoading={isLoading} />
					<MentoringLogDetailAnswer answer={answer[0]} type={type} isLoading={isLoading} />
				</>
			) : (
				<>
					{answer &&
						answer.map((item: MentoringLogDetailAnswerItem) => {
							return <MentoringLogDetailAnswer answer={item} type={type} key={item.no} />;
						})}
				</>
			)}
		</StyledMentoringLogDetailAnswerContainer>
	);
};

export default MentoringLogDetailAnswerList;
