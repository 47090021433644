import React, { useEffect } from 'react';
import ReportCampPrivateDetailSummary from '@/components/report/eduCampPrivate/organisms/ReportCampPrivateDetailSummary';
import ReportCampPrivateDetailAssignment from '@/components/report/eduCampPrivate/organisms/ReportCampPrivateDetailAssignment';
import ReportCampPrivateDetailSchedule from '@/components/report/eduCampPrivate/organisms/ReportCampPrivateDetailSchedule';
import { useParams } from 'react-router-dom';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import {
	useEduCampPrivateListDetailQuery,
	useEduCampPrivateListScheduleQuery,
} from '@/queries/report/eduCampPrivate/useEduCampPrivateQuery';
import { EDU_CAMP_APPLICANT_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCommon';

const EduCampPrivateUserListDetail = () => {
	const { applicantNo } = useParams();
	const { data: applicantData, isLoading: applicantLoading } = useEduCampPrivateListDetailQuery(Number(applicantNo));
	const applicant = applicantData?.data ?? EDU_CAMP_APPLICANT_INITIAL_DATA;
	const { data: campScheduleData, isLoading: scheduleLoading } = useEduCampPrivateListScheduleQuery(
		Number(applicantNo),
	);
	const campSchedule = campScheduleData?.data;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('수강 현황')));
	}, []);

	return (
		<>
			<ReportCampPrivateDetailSummary applicantData={applicant} isLoading={applicantLoading} />
			<ReportCampPrivateDetailAssignment applicantData={applicant} isLoading={applicantLoading} />
			<ReportCampPrivateDetailSchedule campSchedule={campSchedule} isLoading={scheduleLoading} />
		</>
	);
};

export default EduCampPrivateUserListDetail;
