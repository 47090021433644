import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import SECTION_TITLE from '@/utils/constants/section-title';
import SatisfactionContent from '@/components/common/SatisfactionContent';
import useContractId from '@/hooks/useContractId';
import { useContentSatisfactionQuery } from '@/queries/report/contents/useContentsQuery';
import { REPORT_SATISFACTION_INITIAL_DATA } from '@/utils/constants/common';

const ReportContentSatisfactionSection = () => {
	const contractId = useContractId();
	const satisfactionType = 'readCase' as const;
	const { data, isLoading } = useContentSatisfactionQuery(contractId, { type: satisfactionType });
	const { avg, satisfaction, total } = data?.data ?? REPORT_SATISFACTION_INITIAL_DATA;

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SATISFACTION_SECTION_TITLE}</StyledSectionTitle>
			<SatisfactionContent
				isLoading={isLoading}
				total={total}
				score={avg}
				satisfactionData={satisfaction}
				satisfactionType={satisfactionType}
			/>
		</section>
	);
};

export default ReportContentSatisfactionSection;
