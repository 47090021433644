import React from 'react';
import styled from 'styled-components';

export interface propTypes {
	children: React.ReactNode;
}

const StyledContainer = styled.div`
	& > section:not(:last-child) {
		margin-bottom: 60px;
	}
`;

const DetailSectionContainer = ({ children }: propTypes) => {
	return <StyledContainer>{children}</StyledContainer>;
};

export default DetailSectionContainer;
