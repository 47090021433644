import React from 'react';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import DataTable from '@/components/common/organisms/DataTable';
import styled from 'styled-components';
import { PrivateApplicant, PrivateAssignment, PrivateAttendance } from '@/types/eduCamp/private';

const ReportCampPrivateDetailSummary = ({
	applicantData,
	isLoading,
}: {
	applicantData: PrivateApplicant;
	isLoading: boolean;
}) => {
	const getApplicantAttendance = (array: number[], week: number) => {
		if (!array || !array?.length) return '';
		const attendance = array[week - 1];
		if (attendance < 0) return '';
		return attendance === 1 ? 'O' : 'X';
	};

	const { applicant } = applicantData;
	const data = [
		{
			xAxisTitle: '출석',
			'1차': getApplicantAttendance(applicant?.attendances_detail, 1),
			'2차': <StyledText>라이브세션 없음</StyledText>,
			'3차': getApplicantAttendance(applicant?.attendances_detail, 3),
			'4차': <StyledText>라이브세션 없음</StyledText>,
			'5차': getApplicantAttendance(applicant?.attendances_detail, 5),
		},
		{
			xAxisTitle: '과제',
			'1차': getApplicantAttendance(applicant?.assignments_detail, 1),
			'2차': getApplicantAttendance(applicant?.assignments_detail, 2),
			'3차': getApplicantAttendance(applicant?.assignments_detail, 3),
			'4차': getApplicantAttendance(applicant?.assignments_detail, 4),
			'5차': <StyledText>과제 없음</StyledText>,
		},
	];

	// 둘 중 하나
	// 모든 아이템에 key가 빠지지 않고 일정하게 들어간다면 노이슈
	const keyList = Object.keys(data[0]);
	return (
		<section>
			<StyledTitleRowContainer>요약정보</StyledTitleRowContainer>
			<DataTable data={data} keyList={keyList} isLoading={isLoading} width={'178px'} />
		</section>
	);
};

export default ReportCampPrivateDetailSummary;

const StyledText = styled.span`
	color: ${props => props.theme['gray-6']};
`;
