import React from 'react';
import { useParams } from 'react-router-dom';
import CheckDetailSection from '@/components/common/organisms/CheckDetailSection/CheckDetailSection';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';

const ReportAICheckDetailSection = () => {
	const params = useParams();
	const dataSource = [
		{
			key: '1',
			title: '등록자 전체 명단',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.AI.USER_LIST, params),
		},
		{
			key: '2',
			title: 'AI 분석기 이용 현황',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.AI.LOG, params),
		},
	];
	return <CheckDetailSection title={'상세 이력 조회'} dataSource={dataSource} />;
};

export default ReportAICheckDetailSection;
