import axiosClient from '@/api';

const EDU_COUPON_PREFIX = '/edu-camp-coupon';
const APPLICANT_PREFIX = '/applicant';
const SUMMARY_PREFIX = '/abstract';
const SATISFACTION_PREFIX = '/satisfaction';
const UNUSED_PREFIX = '/unused';
const CAMP_PREFIX = '/camp';
const ATTENDANCE_PREFIX = '/attendance';
const ASSIGNMENT_PREFIX = '/assignment';
const SCHEDULE_PREFIX = '/schedule';
const REDEEM_PREFIX = '/redeem';
const CLASSROOM_PREFIX = '/classroom';
const COUPON_PREFIX = '/coupons';
const STATUS_PREFIX = '/status';
const INFO_PREFIX = '/info';
const LIST_PREFIX = '/list';
const DOWNLOAD_PREFIX = '/download';

export const getCampCouponSummaryInfo = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${EDU_COUPON_PREFIX}${SUMMARY_PREFIX}${INFO_PREFIX}`, {
		params,
	});
};

export const getCampCouponSatisfactionRequest = async (params: { contractNo: number; page: number }) => {
	return await axiosClient.get(`${EDU_COUPON_PREFIX}${SATISFACTION_PREFIX}${INFO_PREFIX}`, {
		params,
	});
};

export const getCampCouponApplicantListInfo = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${EDU_COUPON_PREFIX}${APPLICANT_PREFIX}${LIST_PREFIX}`, {
		params,
	});
};

export const getCampCouponListInfo = async (params: { contractNo: number }) => {
	return await axiosClient.get(`${EDU_COUPON_PREFIX}${UNUSED_PREFIX}${COUPON_PREFIX}`, {
		params,
	});
};

export const getCampCouponAttendanceStatus = async (params: { applicant_no: number }) => {
	return await axiosClient.get(`${EDU_COUPON_PREFIX}${ATTENDANCE_PREFIX}${STATUS_PREFIX}`, {
		params,
	});
};

export const getCampCouponSchedule = async (params: { applicant_no: number }) => {
	return await axiosClient.get(`${EDU_COUPON_PREFIX}${CAMP_PREFIX}${SCHEDULE_PREFIX}`, {
		params,
	});
};

export const getCampCouponAssignmentDetail = async (params: { assignment_no: number; applicant_no: number }) => {
	return await axiosClient.get(`${EDU_COUPON_PREFIX}${CLASSROOM_PREFIX}${ASSIGNMENT_PREFIX}/detail`, {
		params,
	});
};

export const redeemUserCouponRequest = async (user_coupon_id: number) => {
	return await axiosClient.post(`${EDU_COUPON_PREFIX}${REDEEM_PREFIX}${COUPON_PREFIX}`, {
		user_coupon_id,
	});
};

export const downloadCampCouponApplicantToExcel = async (params: { contractNo: number }) => {
	return await axiosClient.getFile(`${EDU_COUPON_PREFIX}${APPLICANT_PREFIX}${DOWNLOAD_PREFIX}`, {
		params,
	});
};

export const downloadCampCouponAllAssignment = async (params: { assignmentNo: number }) => {
	return await axiosClient.getFile(`${CLASSROOM_PREFIX}${DOWNLOAD_PREFIX}${ASSIGNMENT_PREFIX}`, {
		params,
	});
};

export const downloadCampAssignmentFile = async (params: { file_no: number }) => {
	return await axiosClient.getFile(`${CLASSROOM_PREFIX}${DOWNLOAD_PREFIX}${ASSIGNMENT_PREFIX}/file`, {
		params,
	});
};

export const downloadCampAssignmentReplyFile = async (params: { file_no: number }) => {
	return await axiosClient.getFile(`${CLASSROOM_PREFIX}${DOWNLOAD_PREFIX}${ASSIGNMENT_PREFIX}/reply-file`, {
		params,
	});
};

export const downloadCampCouponUnusedList = async (contractNo: number) => {
	return await axiosClient.getFile(`${EDU_COUPON_PREFIX}${UNUSED_PREFIX}${COUPON_PREFIX}`, {
		params: {
			contractNo,
			excel: 1,
		},
	});
};
