import React, { useContext, useEffect, useState } from 'react';
import HeaderLayout from '@/layouts/HeaderLayout';
import HomeHeader from '@/components/Header/HomeHeader';
import styled from 'styled-components';
import Text from '@/components/common/atoms/Text';
import { Button, Form, Input } from 'antd';
import { StyledFlexCenterContainer } from '@/components/common/styledComponents';
import { loginRequest, loginRequestDataTypes } from '@/api/common';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { COMENTO_TOKEN } from '@/utils/constants/cookies';
import { authCookieOption } from '@/utils/auth';
import FindAccountModal from '@/components/login/FindAccountModal';
import theme from '@/styles/theme';
import { useDispatch } from 'react-redux';
import { setPartnerNo } from '@/store/partnerSlice';
import { AuthContext } from '@/contexts/AuthProvider';
import axiosClient from '@/api';

const StyledLoginContainer = styled.div`
	background-color: white;
	width: 343px;
	margin: 60px auto 0 auto;
	border: 1px solid ${props => props.theme['gray100']};
`;

const StyledLoginContentWrapper = styled.div`
	margin: 60px auto;
	width: 266px;
	text-align: center;
	& > :not(:last-child) {
		margin-bottom: 24px;
	}
`;

const StyledLoginTitle = styled(Text)`
	font-weight: 600;
	font-size: 24px;
	color: #21282f;
`;

const StyledLoginTitleWrapper = styled.div`
	margin-bottom: 24px;
`;

const StyledLoginButton = styled(Button)`
	background-color: ${props => props.theme['blue-7']};
	width: 100%;
`;

const StyledForm = styled(Form)`
	.ant-form-item {
		margin-bottom: 0;
	}
	.ant-form-item + .ant-form-item {
		margin-top: 8px;
	}
`;

const StyledErrorMessageContainer = styled.div`
	text-align: left;
	margin-top: 2px;
	margin-bottom: 24px;
`;

const StyledLostUserButton = styled(Button)`
	color: ${props => props.theme['gray-6']};
	&:hover {
		color: ${props => props.theme['gray-6']};
		background-color: ${props => props.theme['gray-3']};
	}
`;

interface LoginState {
	userId?: string;
}

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const state = location.state as LoginState;
	const { isLogin } = useContext(AuthContext);
	const [form] = Form.useForm();
	const [, forceUpdate] = useState({});
	const [, setCookie] = useCookies([COMENTO_TOKEN]);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);
	const [isFindAccountModalVisible, setFindAccountModalVisible] = useState(false);

	// 로그인 상태일때 로그인 화면으로 들어오면 REPORT HOME으로 이동
	useEffect(() => {
		if (isLogin) {
			try {
				(async () => {
					const { data } = await axiosClient.get('main/auth/check');
					dispatch(setPartnerNo(data));
					navigate(dynamicRoutePath(ROUTER_PATH.REPORT.HOME, { partnerId: data }));
				})();
			} catch (error) {
				console.error(error);
			}
		}
	}, [isLogin]);

	// /logout?userId=jeju_admin 처럼 userId가 붙어서 넘어오면 인풋창에 미리 입력시켜줌.
	// 용도는 사업개발팀에서 구어드민 대시보드 자세히보기를 눌렀을 때 아이디 입력을 해야 하는 불편함을 최소화하기 위함.
	useEffect(() => {
		// location.state의 type이 'unknown'이므로 이렇게 처리
		const userId = state?.userId;
		if (userId) {
			form.setFieldsValue({
				user_id: userId,
			});
		}
	}, [state]);

	const handleLogin = async (data: loginRequestDataTypes) => {
		try {
			const {
				data: { token, partnerNumber },
			} = await loginRequest(data);
			setCookie(COMENTO_TOKEN, token, authCookieOption);
			dispatch(setPartnerNo(partnerNumber));
			return { partnerId: partnerNumber };
		} catch (error) {
			console.error(error);
			setErrorMessage(error.response.data.message);
			setIsError(true);
			return false;
		}
	};

	// 버튼을 처음부터 disable시키기 위함
	useEffect(() => {
		forceUpdate({});
	}, []);

	const onFinish = async (values: any) => {
		setIsLoginButtonLoading(true);
		const result = await handleLogin(values);
		// 로그인 성공
		if (result && result.partnerId) {
			navigate(dynamicRoutePath(ROUTER_PATH.REPORT.HOME, { partnerId: result.partnerId }));
		} else {
			setIsLoginButtonLoading(false);
		}
	};
	const onFinishFailed = (errorInfo: any) => {
		console.error('Failed:', errorInfo);
	};

	const toggleFindAccountModalVisible = () => {
		setFindAccountModalVisible(!isFindAccountModalVisible);
	};

	return (
		<>
			<HeaderLayout isGhostStyle>
				<HomeHeader />
			</HeaderLayout>
			<StyledLoginContainer>
				<StyledLoginContentWrapper>
					<StyledLoginTitleWrapper>
						<StyledLoginTitle>제휴관리자 로그인</StyledLoginTitle>
					</StyledLoginTitleWrapper>
					<StyledForm form={form} labelCol={{ span: 8 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
						<Form.Item name="user_id" rules={[{ required: false, message: '아이디를 입력해주세요.' }]}>
							<Input status={isError ? 'error' : ''} placeholder="아이디를 입력하세요" allowClear />
						</Form.Item>
						<Form.Item name="user_pw" rules={[{ required: false, message: '비밀번호를 입력해주세요.' }]}>
							<Input.Password
								status={isError ? 'error' : ''}
								placeholder="비밀번호를 입력하세요"
								allowClear
							/>
						</Form.Item>
						<StyledErrorMessageContainer>
							{isError && <Text color={theme['red-5']}>{errorMessage}</Text>}
						</StyledErrorMessageContainer>
						<Form.Item shouldUpdate>
							{() => (
								<StyledLoginButton
									type="primary"
									htmlType="submit"
									disabled={
										!form.isFieldsTouched(true) ||
										!!form.getFieldsError().filter(({ errors }) => errors.length).length
									}
									loading={isLoginButtonLoading}
								>
									로그인
								</StyledLoginButton>
							)}
						</Form.Item>
					</StyledForm>
					<StyledFlexCenterContainer>
						<StyledLostUserButton onClick={toggleFindAccountModalVisible} type="text">
							아이디/비밀번호 찾기
						</StyledLostUserButton>
					</StyledFlexCenterContainer>
				</StyledLoginContentWrapper>
			</StyledLoginContainer>
			<FindAccountModal visible={isFindAccountModalVisible} onCancel={toggleFindAccountModalVisible} />
		</>
	);
};

export default Login;
