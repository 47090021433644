import React from 'react';
import styled from 'styled-components';
import { StyledBasicTable, StyledTitleRowContainer } from '@/components/common/styledComponents';
import { Col, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { JumpUpApplicant } from '@/types/eduCamp/jumpUp';

interface RecordNo {
	no?: number;
}

const StyledCellSection = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const ReportCampJumpUpDetailAssignment = ({
	applicantData,
	isLoading,
}: {
	applicantData?: JumpUpApplicant | null;
	isLoading: boolean;
}) => {
	const params = useParams();
	const columnList = [
		{
			title: '제출자',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '차수',
			key: 'week',
			dataIndex: 'week',
			render: (week: number) => <p>{`${week}차`}</p>,
		},
		{
			title: '제출일',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (date: string) => <p>{formatDate(date, DATE_FORMAT.FULL_DATE)}</p>,
		},
		{
			title: '피드백',
			key: 'read_at',
			dataIndex: 'read_at',
			render: (date: string) => <StyledCellSection>{date ? '완료' : '대기'}</StyledCellSection>,
		},
		{
			title: '과제 조회',
			key: 'action',
			dataIndex: 'action',
			render: (_: string, record: RecordNo) => (
				<Link
					to={dynamicRoutePath(`${ROUTER_PATH.REPORT.EDU_CAMP_JUMP_UP.ASSIGNMENT_LIST}/${record.no}`, params)}
				>
					조회하기
				</Link>
			),
		},
	];
	return (
		<StyledSection>
			<StyledTitleRowContainer>과제 제출 현황</StyledTitleRowContainer>
			<Row>
				<Col span={24}>
					<StyledBasicTable
						dataSource={applicantData?.assignment}
						columns={columnList}
						pagination={false}
						loading={isLoading}
						rowKey={'no'}
						locale={{
							emptyText: <EmptyTableSection />,
						}}
					/>
				</Col>
			</Row>
		</StyledSection>
	);
};

export default ReportCampJumpUpDetailAssignment;

const StyledSection = styled.section`
	margin-top: 60px;
`;
