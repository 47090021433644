import React from 'react';
import styled from 'styled-components';

const StyledContentSection = styled.article`
	margin-top: 24px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
	white-space: pre-wrap;
`;

const AssignmentContentSection = ({ content }: { content?: string }) => {
	return <StyledContentSection dangerouslySetInnerHTML={{ __html: content ? content : '<div />' }} />;
};

export default AssignmentContentSection;
