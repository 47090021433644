import React, { MouseEventHandler } from 'react';
import { Table } from 'antd';
import Text from '@/components/common/atoms/Text';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import theme, { mixin } from '@/styles/theme';
import { ColumnProps } from 'antd/lib/table';
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { VODTableRow } from '@/types/vod/vod';

const ReportVODListTitle = styled.div`
	${flexAlignCenter};
	${mixin.justifyContent('space-between')};
	margin-bottom: 16px;
`;

const StyledTableWrapper = styled.div<{ showBorder?: boolean }>`
	${props =>
		!!props.showBorder &&
		`	.ant-table-tbody > tr > td {
		border: none;
	}`}
`;

interface VODTableProps<T> {
	dataSource: T[];
	columns?: ColumnProps<T>[];
	total: number;
	totalRegistrants: number;
	loading?: boolean;
	isRefetching?: boolean;
	loadingDownload?: boolean;
	rowKey: string;
	onClickDownload: MouseEventHandler<HTMLButtonElement>;
	onPageChange?: (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<T> | SorterResult<T>[],
		extra: TableCurrentDataSource<T>,
	) => void;
}

const ReportVODTable = <T extends VODTableRow>({
	isRefetching,
	dataSource,
	columns,
	total,
	totalRegistrants,
	loading,
	loadingDownload,
	rowKey,
	onClickDownload,
	onPageChange,
}: VODTableProps<T>) => {
	return (
		<>
			<ReportVODListTitle>
				<Text fontSize="14px" lineheight="22px" color={theme['gray-7']}>
					학습자 수: <Text color={theme['blue-7']}>{totalRegistrants}</Text>명{isRefetching && '(새로고침중)'}
				</Text>
				<DownloadButton loading={loadingDownload} onClick={onClickDownload}>
					다운로드
				</DownloadButton>
			</ReportVODListTitle>
			<StyledTableWrapper showBorder={loading}>
				<Table<T>
					dataSource={dataSource}
					pagination={{ position: ['bottomCenter'], pageSize: 25, total, showSizeChanger: false }}
					onChange={onPageChange}
					loading={loading}
					rowKey={rowKey}
					locale={{
						emptyText: <EmptyTableSection />,
					}}
				>
					{columns &&
						columns.map(column => {
							return <Table.Column<T> align="center" {...column} />;
						})}
				</Table>
			</StyledTableWrapper>
		</>
	);
};

export default ReportVODTable;
