import React, { useContext } from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import SECTION_TITLE from '@/utils/constants/section-title';
import { ReportMentoringMonthlyUsageContext } from '@/contexts/ReportMentoringMonthlyUsage';
import ColumnChart from '@/components/common/ColumnChart';

const ReportMentoringMonthlyUsageGraphSection = () => {
	const {
		reportMentoringMonthlyUsage: { graph },
		isLoading,
	} = useContext(ReportMentoringMonthlyUsageContext);

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.MONTHLY_USAGE_GRAPH_SECTION_TITLE}</StyledSectionTitle>
			<ColumnChart data={graph} isLoading={isLoading} />
		</section>
	);
};

export default ReportMentoringMonthlyUsageGraphSection;
