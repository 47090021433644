import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';

export interface partnerState {
	partnerNo: number;
	partnerName: string;
}

const initialState: partnerState = {
	partnerNo: -1,
	partnerName: '제휴기관',
};

const partnerSlice = createSlice({
	name: 'partner',
	initialState,
	reducers: {
		setPartnerNo: (state, action: PayloadAction<number>) => {
			state.partnerNo = action.payload;
		},
		setPartnerName: (state, action: PayloadAction<string>) => {
			state.partnerName = action.payload;
		},
	},
});

export const { setPartnerNo, setPartnerName } = partnerSlice.actions;

export const getPartnerNo = (state: RootState) => state.partner.partnerNo;
export const getPartnerName = (state: RootState) => state.partner.partnerName;

export default partnerSlice.reducer;
