import axiosClient from '@/api';
import { EduPathV2ApplicantDetail, EduPathV2Attendance, EduPathV2SummaryResult } from '@/types/eduCamp/path';

const EDU_PATH_PREFIX = '/edupath';

export const getEduPathInfo = async (contractId: number) => {
	return await axiosClient.get(`${EDU_PATH_PREFIX}/${contractId}/info`);
};

export const getEduPathV2Info = async (contractId: number) => {
	const response = await axiosClient.get<EduPathV2SummaryResult>(`v2${EDU_PATH_PREFIX}/${contractId}/info`);
	return response.data;
};

//진로캠프 신청자 현황
export const getEduPathApplicantList = async (contractId: number) => {
	return await axiosClient.get(`${EDU_PATH_PREFIX}/${contractId}`);
};
export interface GetEduPathApplicantListResponse {
	data: EduPathV2ApplicantDetail[];
	// pagination 링크
	links: object;
	// 사용하는 타입만 정의함.
	meta: {
		total: number;
		per_page: number;
	};
	note_url: string | null;
}

export interface PaginationOptions {
	page: number;
	limit: number;
}
export const getEduPathV2ApplicantList = async (contractId: number, paginationOptions: PaginationOptions) => {
	const response = await axiosClient.get<GetEduPathApplicantListResponse>(`v2${EDU_PATH_PREFIX}/${contractId}`, {
		params: paginationOptions,
	});
	return response.data;
};

//캠프 신청자 현황 다운로드
export const getEduPathApplicantListDownload = async (contractId: number) => {
	return await axiosClient.getFile(`${EDU_PATH_PREFIX}/${contractId}/download`);
};

//캠프 신청자 현황 다운로드
export const getEduPathV2ApplicantListDownload = async (contractId: number) => {
	return await axiosClient.getFile(`v2${EDU_PATH_PREFIX}/${contractId}/download`);
};

//모든 과제 다운로드
export const getEduPathAllAssignmentsDownload = async (contractId: number) => {
	return await axiosClient.getFile(`${EDU_PATH_PREFIX}/${contractId}/assignments/download`);
};

//모든 과제 다운로드
export const getEduPathV2AllAssignmentsDownload = async (contractId: number) => {
	return await axiosClient.getFile(`v2${EDU_PATH_PREFIX}/${contractId}/assignments/download`);
};

//유저 수강현황
export type getEduPathApplicantStatusParams = {
	contractId: number;
	userList?: string;
};
export const getEduPathApplicantStatus = async ({ contractId, userList }: getEduPathApplicantStatusParams) => {
	return await axiosClient.get(`${EDU_PATH_PREFIX}/${contractId}/mentee/${userList}`);
};
export const getEduPathV2Attendance = async ({ contractId, userList }: getEduPathApplicantStatusParams) => {
	const response = await axiosClient.get<EduPathV2Attendance[]>(
		`v2${EDU_PATH_PREFIX}/${contractId}/mentee/${userList}`,
	);
	return response.data;
};

//쿠폰 리스트
export const getEduPathCouponList = async (contractId: number) => {
	return await axiosClient.get(`${EDU_PATH_PREFIX}/${contractId}/coupon`);
};
//쿠폰 리스트
export const getEduPathV2CouponList = async (contractId: number) => {
	return await axiosClient.get(`v2${EDU_PATH_PREFIX}/${contractId}/coupon`);
};

//쿠폰 회수
export type postEduPathCouponRevokeParams = {
	contractId: number;
	coupon_code: string | null;
};
export const postEduPathCouponRevoke = async ({ contractId, coupon_code }: postEduPathCouponRevokeParams) => {
	return await axiosClient.post(`${EDU_PATH_PREFIX}/${contractId}/coupon/revoke`, { coupon_code });
};
export const postEduPathV2CouponRevoke = async ({ contractId, coupon_code }: postEduPathCouponRevokeParams) => {
	return await axiosClient.post(`v2${EDU_PATH_PREFIX}/${contractId}/coupon/revoke`, { coupon_code });
};

//미사용 쿠폰 현황 다운로드
export const getEduPathCouponDownload = async (contractId: number) => {
	return await axiosClient.getFile(`${EDU_PATH_PREFIX}/${contractId}/coupon/download`);
};
export const getEduPathV2CouponDownload = async (contractId: number) => {
	return await axiosClient.getFile(`v2${EDU_PATH_PREFIX}/${contractId}/coupon/download`);
};

export type getEduPathAssignmentsParams = {
	contractId: number;
	eduApplicant?: string;
};
export const getEduPathAssignments = async ({ contractId, eduApplicant }: getEduPathAssignmentsParams) => {
	return await axiosClient.get(`v2${EDU_PATH_PREFIX}/${contractId}/applicant/${eduApplicant}/assignments`);
};

export type getEduPathAssignmentsDownloadParams = {
	contractId: number;
	eduApplicant?: string;
	eduClassroomAssignment?: number;
};

//멘티 과제 파일 다운로드
export const getEduPathClassroomAssignmentFile = async ({ contractId, eduApplicant }: getEduPathAssignmentsParams) => {
	return await axiosClient.get(`${EDU_PATH_PREFIX}/${contractId}/assignments/download/mentee/${eduApplicant}`);
};
//멘티 파일전체 다운로드
export const getEduPathAssignmentsAllDownload = async ({
	contractId,
	eduApplicant,
	eduClassroomAssignment,
}: getEduPathAssignmentsDownloadParams) => {
	return await axiosClient.getFile(
		`${EDU_PATH_PREFIX}/${contractId}/applicant/${eduApplicant}/assignments/${eduClassroomAssignment}/download`,
	);
};
//멘토 피드백 파일전체 다운로드
export const getEduPathAssignmentsMentorAllDownload = async ({
	contractId,
	eduApplicant,
	eduClassroomAssignment,
}: getEduPathAssignmentsDownloadParams) => {
	return await axiosClient.getFile(
		`${EDU_PATH_PREFIX}/${contractId}/applicant/${eduApplicant}/assignments/${eduClassroomAssignment}/download/mentor`,
	);
};
