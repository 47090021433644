import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';

export interface propTypes {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	loading?: boolean;
	children?: React.ReactNode;
}

const DownloadButton = ({ children, loading, onClick }: propTypes) => {
	return (
		<Button
			icon={loading ? <LoadingOutlined /> : <DownloadOutlined style={{ color: theme['gray-9'] }} />}
			onClick={onClick}
			disabled={loading}
		>
			<Text color={theme['gray-9']}>{children || '다운로드'}</Text>
		</Button>
	);
};

export default DownloadButton;
