export const KEY_LIST = {
	X_AXIS_TITLE: 'xAxisTitle',
	USER_COUNT: '사용자 수',
	QA_COUNT: '질문 횟수',
	QA_AVERAGE_PER_USER: '1인당 평균 질문 횟수',
} as const;

export const DATA_BY_CATEGORY_KEY = {
	QA: 'Q&A',
	CE: '자소서 첨삭',
	IN: '면접 질문',
	TOTAL: '합계',
} as const;

export const defaultMonthlyUsageItem = { date: '', value: 0 };
export const defaultTotalItem = {};
export const defaultValue = {
	graph: {
		qa: [defaultMonthlyUsageItem],
		ce: [defaultMonthlyUsageItem],
		in: [defaultMonthlyUsageItem],
		total: defaultTotalItem,
	},
	table: {
		qa: [defaultMonthlyUsageItem],
		ce: [defaultMonthlyUsageItem],
		in: [defaultMonthlyUsageItem],
		total: defaultTotalItem,
	},
};

export const MENTORING_SUMMARY_INITIAL_DATA = {
	info: {
		active_user: 0,
		count_answer: 0,
		count_coupon: 0,
		count_qa: 0,
		total_user: 0,
	},
	coupon: {
		coupon: {
			ce: 0,
			in: 0,
			qa: 0,
			total: 0,
		},
	},
};

export const MONTHLY_USAGE_INITIAL_DATA = {
	month: {
		graph: {
			qa: [defaultMonthlyUsageItem],
			ce: [defaultMonthlyUsageItem],
			in: [defaultMonthlyUsageItem],
			total: defaultTotalItem,
		},
		table: {
			qa: [defaultMonthlyUsageItem],
			ce: [defaultMonthlyUsageItem],
			in: [defaultMonthlyUsageItem],
			total: defaultTotalItem,
		},
	},
};

export const DATA_BY_CATEGORY_INITIAL_DATA = {
	category: {
		ce_avg: 0,
		ce_use: 0,
		ce_user: 0,
		in_avg: 0,
		in_use: 0,
		in_user: 0,
		qa_avg: 0,
		qa_use: 0,
		qa_user: 0,
	},
};

export const MENTORING_CHART_INITIAL_DATA = {
	data: {
		company: {
			contentName: [],
			contentRatio: [],
		},
		job: {
			jobName: [],
			jobRatio: [],
		},
	},
};

export const MENTORING_SATISFACTION_INITIAL_DATA = {
	avg: 0,
	satisfaction: [
		{
			value: 5,
			percent: 0,
		},
		{
			value: 4,
			percent: 0,
		},
		{
			value: 3,
			percent: 0,
		},
		{
			value: 2,
			percent: 0,
		},
		{
			value: 1,
			percent: 0,
		},
	],
	total: 0,
};

export const MENTORING_LOG_INITIAL_DATA = {
	list: {
		current_page: 1,
		data: [],
	},
	total_count: 0,
};

export const MENTORING_LOG_DETAIL_INITIAL_DATA = {
	question: {
		category: '',
		company: '',
		job: '',
		other_inf: '',
		question: '',
		time: '',
		type: '',
		user_major: '',
		user_univ: '',
	},
	answer: [
		{
			no: 0,
			user_no: 0,
			// question type in은 Record
			comment: '',
			likenum: 0,
			user_belong: '',
			user_dep: '',
			choose: 0,
			time: '',
			confirm_time: '',
			answer_after: 0,
			request_no: 0,
			parent_no: 0,
			dormancy: false,
			category_group: 0,
			user_list: {
				belong: '',
				choice_rate: 0,
				grade: 0,
				grade_name: '',
				id: '',
				nickname: '',
				no: 0,
				type: 0,
				univ: '',
			},
			reply_feedback: {},
		},
	],
};
