import React from 'react';
import { useParams } from 'react-router-dom';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import CheckDetailSection from '@/components/common/organisms/CheckDetailSection/CheckDetailSection';

const EduPathV2ReportCampCouponCheckDetailSection = () => {
	const params = useParams();
	const dataSource = [
		{
			key: '1',
			title: '캠프 신청자 현황',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.EDU_PATH_V2.USER_LIST, params),
		},
		{
			key: '2',
			title: '미사용 쿠폰 현황',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.EDU_PATH_V2.COUPON_LIST, params),
		},
	];
	return <CheckDetailSection title={'상세 이력 조회'} dataSource={dataSource} />;
};

export default EduPathV2ReportCampCouponCheckDetailSection;
