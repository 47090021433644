import React from 'react';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import { CouponDataProp } from '@/types/eduCamp/path';

const StyledCouponInfoContainer = styled.div`
	${flexAlignCenter}
`;

const StyledCouponInfoState = styled.div`
	margin-right: 4px;
	font-size: 12px;
	line-height: 20px;
	color: ${props => props.theme['gray-7']};
`;

const StyledCouponCount = styled.div<{ countMargin: number }>`
	margin-right: ${props => `${props.countMargin}px`};
	font-size: 16px;
	line-height: 24px;
	color: ${props => props.theme['gray-7']};
`;

const CouponInfoData = ({ couponInfo }: { couponInfo: CouponDataProp }) => {
	const { inProgress, finished, revoke } = couponInfo;
	return (
		<StyledCouponInfoContainer>
			<StyledCouponInfoState>진행</StyledCouponInfoState>
			<StyledCouponCount countMargin={8}>{inProgress}</StyledCouponCount>
			<StyledCouponInfoState>종료</StyledCouponInfoState>
			<StyledCouponCount countMargin={8}>{finished}</StyledCouponCount>
			{revoke && (
				<>
					<StyledCouponInfoState>취소</StyledCouponInfoState>
					<StyledCouponCount countMargin={0}>{revoke}</StyledCouponCount>
				</>
			)}
		</StyledCouponInfoContainer>
	);
};

export default CouponInfoData;
