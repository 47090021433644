import React from 'react';
import Text from '@/components/common/atoms/Text';
import styled from 'styled-components';
import { flexCenter } from '@/styles/styles';

interface propTypes {
	className?: string;
}

const StyledEmptyContainer = styled.div`
	flex-direction: column;
	${flexCenter};
`;

const StyledTitleWrapper = styled.div`
	margin-bottom: 8px;
`;
const Empty = ({ className }: propTypes) => {
	return (
		<StyledEmptyContainer className={className}>
			<StyledTitleWrapper>
				<Text fontSize={'14px'} fontWeight={600}>
					보고할 데이터가 없습니다.
				</Text>
			</StyledTitleWrapper>
			<Text fontSize={'12px'}>데이터가 수집될 때까지 기다려주세요.</Text>
		</StyledEmptyContainer>
	);
};

export default Empty;
