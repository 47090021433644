import React, { useEffect } from 'react';
import ReportCampJumpUpDetailSummary from '@/components/report/eduCampJumpUp/organisms/ReportCampJumpUpDetailSummary';
import ReportCampJumpUpDetailAssignment from '@/components/report/eduCampJumpUp/organisms/ReportCampJumpUpDetailAssignment';
import ReportCampJumpUpDetailSchedule from '@/components/report/eduCampJumpUp/organisms/ReportCampJumpUpDetailSchedule';
import { useParams } from 'react-router-dom';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import {
	useEduCampJumpUpListDetailQuery,
	useEduCampJumpUpListScheduleQuery,
} from '@/queries/report/eduCampJumpUp/useEduCampJumpUpQuery';
import { EDU_CAMP_APPLICANT_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCommon';

const EduCampJumpUpUserListDetail = () => {
	const { applicantNo } = useParams();
	const { data: applicantData, isLoading: applicantLoading } = useEduCampJumpUpListDetailQuery(Number(applicantNo));
	const applicant = applicantData?.data ?? EDU_CAMP_APPLICANT_INITIAL_DATA;
	const { data: campScheduleData, isLoading: scheduleLoading } = useEduCampJumpUpListScheduleQuery(
		Number(applicantNo),
	);
	const campSchedule = campScheduleData?.data;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('수강 현황')));
	}, []);

	return (
		<>
			<ReportCampJumpUpDetailSummary applicantData={applicant} isLoading={applicantLoading} />
			<ReportCampJumpUpDetailAssignment applicantData={applicant} isLoading={applicantLoading} />
			<ReportCampJumpUpDetailSchedule campSchedule={campSchedule} isLoading={scheduleLoading} />
		</>
	);
};

export default EduCampJumpUpUserListDetail;
