import React, { useEffect } from 'react';
import CampAssignmentCard from '@/components/common/CampAssignmentCard';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { flexAlignCenter, flexCenter } from '@/styles/styles';
import Loading from '@/components/common/Loading';
import useContractId from '@/hooks/useContractId';
import { AssignmentData } from '@/types/eduCamp/campCommon';
import { EDU_PATH_ASSIGNMENT_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampPath';
import { getSignedCookie } from '@/api/common';
import { useUpdateListHeader } from '@/hooks/useUpdateListHeader';
import { useEduCampPathV2Assignment } from '@/queries/report/eduCampPath/eduCampPathV2Query';

const StyledFeedbackCountSection = styled.section`
	${flexAlignCenter}
`;

const StyledFeedbackText = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: ${props => props.theme['gray-7']};
`;

const StyledFeedbackTextCount = styled(StyledFeedbackText)`
	margin-left: 4px;
`;

const StyledLoadingSection = styled.div`
	${flexCenter};
	height: 619px;
`;

const EduPathV2CampAssignment = () => {
	const contractId = useContractId();
	const { assignmentNo } = useParams();

	const { data, isLoading } = useEduCampPathV2Assignment(contractId, assignmentNo);
	const assignment = data?.data.assignment ?? EDU_PATH_ASSIGNMENT_INITIAL_DATA.assignment;
	const fetchedAssignmentNo = assignment.no;
	const assignment_replies = data?.data.assignment_replies ?? EDU_PATH_ASSIGNMENT_INITIAL_DATA.assignment_replies;

	const fetchSignedCookie = async (assignmentNo: number) => {
		if (!assignmentNo) return;
		try {
			await getSignedCookie(assignmentNo);
		} catch (error) {
			console.error(error);
		}
	};

	useUpdateListHeader('과제 조회');

	useEffect(() => {
		const cookieFetch = async () => await fetchSignedCookie(fetchedAssignmentNo);
		cookieFetch();
	}, [fetchedAssignmentNo]);

	return (
		<>
			{!isLoading ? (
				<>
					<CampAssignmentCard isAssignment assignment={assignment} reply={false} />
					<StyledFeedbackCountSection>
						<StyledFeedbackText>피드백</StyledFeedbackText>
						<StyledFeedbackTextCount>{assignment_replies.length}건</StyledFeedbackTextCount>
					</StyledFeedbackCountSection>
					{assignment_replies.map((assignment: AssignmentData) => (
						<CampAssignmentCard
							reply={true}
							isAssignment={false}
							assignment={assignment}
							key={`assignment-replies-${assignment.no}`}
						/>
					))}
				</>
			) : (
				<StyledLoadingSection>
					<Loading />
				</StyledLoadingSection>
			)}
		</>
	);
};

export default EduPathV2CampAssignment;
