import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { getIsFreeSchedule, getWeekText } from '@/utils/date';
import { ScheduleData } from '@/types/eduCamp/campCommon';

const StyledDateString = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const StyledDiv = styled.div`
	.antd-data-table {
		.ant-table-thead .ant-table-cell {
			&:first-child {
				background-color: ${props => props.theme['gray-4']};
			}
			&:not(:first-child) {
				text-align: center;
				font-weight: 600;
			}
		}
		.ant-table-row .ant-table-cell {
			text-align: center;
			&.table-first-column {
				vertical-align: middle;
				background-color: ${props => props.theme['gray-2']};
				font-weight: 600;
			}
		}
		.date-string-cell {
			padding: 12px !important;
		}
		// table head 항목 사이에 divider 나오는거 제거
		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
			display: none;
		}
		.class-progress {
			white-space: pre-wrap;
			text-align: left !important;
		}
	}
`;

const columns: ColumnsType<ScheduleData> = [
	{
		title: null,
		width: '120px',
		dataIndex: 'week',
		key: 'week',
		className: 'table-first-column',
		render: (week, record) => (
			<div className={'table-column-head'} style={{ height: '100%', width: '100%' }}>
				{getIsFreeSchedule(record.start_date, record.end_date) ? getWeekText(week - 1) : `${week} 주차`}
			</div>
		),
		onCell: (item, index) => {
			if (!getIsFreeSchedule(item.start_date, item.end_date)) {
				if (index === 0 || (index as number) % 2 === 0) {
					return {
						rowSpan: 2,
					};
				}
				if ((index as number) % 2 !== 0) {
					return {
						rowSpan: 0,
					};
				}
			} else {
				if (index !== 0 && (index as number) % 2 !== 0) {
					return {
						rowSpan: 2,
					};
				}
				if (index !== 0 && (index as number) % 2 === 0) {
					return {
						rowSpan: 0,
					};
				}
				return {};
			}
			return {};
		},
	},
	{
		title: '날짜',
		width: '200px',
		dataIndex: 'date',
		key: 'date',
		className: 'date-string-cell',
		render: (_: string, record: ScheduleData) => {
			if (getIsFreeSchedule(record.start_date, record.end_date)) {
				if (record.progress.includes('피드백')) return <div>제출 후 +3일 이내</div>;
				return <div>자율 일정</div>;
			} else {
				return (
					<StyledDateString>
						{record.start_date !== record.end_date
							? `${dayjs(record.start_date).locale('ko').format('MM월 DD일(ddd) HH:mm')}~${dayjs(
									record.end_date,
							  ).format('HH:mm')}`
							: `${dayjs(record.end_date).locale('ko').format('MM월 DD일(ddd) HH:mm')}까지`}
					</StyledDateString>
				);
			}
		},
	},
	{
		title: '진행방식',
		width: '200px',
		dataIndex: 'progress',
		key: 'progress',
		render: (progress: string, record: ScheduleData, index: number, { isJumpUp }: { isJumpUp: boolean }) => {
			if (isJumpUp) {
				return record.start_date !== record.end_date ? '현직자 온라인 세션' : progress;
			}
			return progress;
		},
	},
	{
		title: '학습내용',
		className: 'class-progress',
		dataIndex: 'information',
		key: 'information',
		render: (info: string, record: ScheduleData, index: number, { isJumpUp }: { isJumpUp: boolean }) => {
			const content = isJumpUp ? `${info} <br/>*시수 충족을 위해 최소 2시간 이상 참여가 필요합니다.` : info;
			return <div dangerouslySetInnerHTML={{ __html: content }} />;
		},
	},
];

const ScheduleTable = ({
	campSchedule,
	isLoading,
	isJumpUp,
}: {
	campSchedule?: ScheduleData[];
	isLoading: boolean;
	isJumpUp: boolean;
}) => {
	const sortCampSchedule = () => {
		if (campSchedule && !campSchedule.length) return;
		return campSchedule?.sort((a: ScheduleData, b: ScheduleData) => {
			return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
		});
	};
	return (
		<StyledDiv>
			<Table
				columns={columns.map(column => ({
					...column,
					render: column.render
						? (text: any, record: ScheduleData, index: number) =>
								column.render!(text, record, index, { isJumpUp })
						: undefined,
				}))}
				dataSource={sortCampSchedule()}
				className="antd-data-table"
				loading={isLoading}
				pagination={false}
				rowKey={'no'}
			/>
		</StyledDiv>
	);
};

export default ScheduleTable;
