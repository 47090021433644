import React from 'react';
import { useParams } from 'react-router-dom';
import CheckDetailSection from '@/components/common/organisms/CheckDetailSection/CheckDetailSection';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';

const ReportVODCheckDetailSection = () => {
	const params = useParams();
	const dataSource = [
		{
			key: '1',
			title: 'VOD 학습자 이용 현황',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.VOD.USER_LIST, params),
		},
		{
			key: '2',
			title: 'VOD 재생 이력',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.VOD.LOG, params),
		},
	];
	return <CheckDetailSection title={'상세 이력 조회'} dataSource={dataSource} />;
};

export default ReportVODCheckDetailSection;
