import React from 'react';
import styled from 'styled-components';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import ScheduleTable from '@/components/common/molecules/ScheduleTable';
import { ScheduleData } from '@/types/eduCamp/campCommon';

const ReportCampPrivateDetailSchedule = ({
	campSchedule,
	isLoading,
}: {
	campSchedule?: ScheduleData[];
	isLoading: boolean;
}) => {
	return (
		<StyledSection>
			<StyledTitleRowContainer>캠프 일정</StyledTitleRowContainer>
			<ScheduleTable campSchedule={campSchedule} isLoading={isLoading || !campSchedule} isJumpUp={false} />
		</StyledSection>
	);
};

export default ReportCampPrivateDetailSchedule;

const StyledSection = styled.section`
	margin-top: 60px;
`;
