import React from 'react';
import { Column } from '@ant-design/plots';
import theme from '@/styles/theme';
import SkeletonColumn from '@/components/common/SkeletonColumn';
import EmptyWithOverlay from '@/components/common/EmptyWithOverlay';
import styled from 'styled-components';

interface ColumnChartProps {
	data: any;
	isLoading: boolean;
}

const StyledColumnChartContainer = styled.div`
	position: relative;
`;

const ColumnChart = ({ data, isLoading }: ColumnChartProps) => {
	const isEmptyArray = Array.isArray(data) && data.length === 0;
	const isEmpty = Object.keys(data?.total ?? {}).length === 0;
	const config = {
		data: isEmptyArray ? [] : [...data.qa, ...data.ce, ...data.in],
		xField: 'date',
		yField: 'value',
		seriesField: 'type',
		isGroup: true,
		color: [theme['primary'], theme['yellow600'], theme['secondary']],
	};
	return (
		<StyledColumnChartContainer>
			{isLoading ? (
				<SkeletonColumn />
			) : (
				<EmptyWithOverlay isEmpty={isEmptyArray || isEmpty}>
					<Column {...config} />
				</EmptyWithOverlay>
			)}
		</StyledColumnChartContainer>
	);
};

export default ColumnChart;
