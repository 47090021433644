import { useQuery } from '@tanstack/react-query';
import {
	contentInsightRequest,
	contentLogRequest,
	contentMonthlyUsageRequest,
	contentSatisfactionRequest,
	contentSummaryRequest,
	InsightRequestParams,
	RegistrantsRequestParams,
	SatisfactionRequestParams,
} from '@/api/report/content';
import { ContentInsightResult, ContentLogResult, ContentSummaryDataResult } from '@/types/contents/contents';
import { MonthlyUsageResult, ReportSatisfactionResult } from '@/types/common';
import { CONTENT_INSIGHT_INITIAL_DATA } from '@/utils/constants/content';

export const useContentSummaryQuery = (contractId: number) => {
	return useQuery<ContentSummaryDataResult>(['content', 'summary', contractId], () =>
		contentSummaryRequest(contractId),
	);
};

export const useContentMonthlyResultQuery = (contactId: number) => {
	return useQuery<MonthlyUsageResult>(['content', 'monthly', contactId], () => contentMonthlyUsageRequest(contactId));
};

// insight는 initial data가 있는게 로딩중이란걸 보여주기 쉬운 이유로 유지
export const useContentInsightQuery = (contractId: number, params: InsightRequestParams) => {
	return useQuery<ContentInsightResult>(
		['content', 'insight', contractId, params.period],
		() => contentInsightRequest(contractId, params),
		{ initialData: CONTENT_INSIGHT_INITIAL_DATA },
	);
};

export const useContentSatisfactionQuery = (contractId: number, params: SatisfactionRequestParams) => {
	return useQuery<ReportSatisfactionResult>(['content', 'satisfaction', contractId], () =>
		contentSatisfactionRequest(contractId, params),
	);
};

export const useContentLogQuery = (contractId: number, params: RegistrantsRequestParams) => {
	return useQuery<ContentLogResult>(
		['content', 'log', contractId, params.startDate, params.endDate, params.page],
		() => contentLogRequest(contractId, params),
	);
};
