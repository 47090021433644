import React, { useEffect, useRef, useState } from 'react';
import { loadingSpinnerRequest } from '@/api/common';
import lottie from 'lottie-web';
import styled from 'styled-components';

const Loader = styled.div`
	width: 100px;
	height: 100px;
	margin: 0 auto;
	position: absolute;
	margin: 0 auto;
	z-index: 1000;
`;

const Loading = () => {
	const [loadingSpinner, setLoadingSpinner] = useState<any>('');
	const lottieContainer = useRef(null);

	useEffect(() => {
		const fetchLoadingSpinner = async () => {
			try {
				const res = await loadingSpinnerRequest();
				setLoadingSpinner(res);
			} catch (error) {
				console.error(error);
			}
		};
		fetchLoadingSpinner().catch(error => console.error(error));
	}, []);

	useEffect(() => {
		if (lottieContainer.current) {
			lottie.loadAnimation({
				container: lottieContainer.current!,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: loadingSpinner,
			});
		}
	}, [lottieContainer, loadingSpinner]);

	return (
		<>
			<Loader ref={lottieContainer} />
		</>
	);
};

export default Loading;
