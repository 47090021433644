import React from 'react';
import { StyledEmpty } from '@/components/common/Empty/Styled';
import styled from 'styled-components';

interface propTypes {
	children?: React.ReactNode;
	isEmpty: boolean;
}

const StyledOverlay = styled.div`
	width: 100%;
	height: 100%;
	opacity: 0.2;
`;

const EmptyWithOverlay = ({ children, isEmpty }: propTypes) => {
	return (
		<>
			{isEmpty ? (
				<>
					<StyledEmpty />
					<StyledOverlay>{children}</StyledOverlay>
				</>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export default EmptyWithOverlay;
