import axiosClient from '@/api';
import { InsightRequestParams, RegistrantsRequestParams, SatisfactionRequestParams } from '@/api/report/content';

const MENTORING_PREFIX = '/mentoring';
const MAIN_PREFIX = '/main';
const DETAIL_PREFIX = '/detail';
const MONTH_PREFIX = '/month';

export const mentoringSummaryRequest = async (contract_no: number) => {
	return await axiosClient.post(`${MENTORING_PREFIX}${MAIN_PREFIX}/count`, { contract_no });
};

export const mentoringDataByCategoryRequest = async (contract_no: number) => {
	return await axiosClient.post(`${MENTORING_PREFIX}${MAIN_PREFIX}/category`, { contract_no });
};

export const mentoringMonthlyUsageRequest = async (contract_no: number) => {
	return await axiosClient.post(`${MENTORING_PREFIX}${MAIN_PREFIX}${MONTH_PREFIX}`, { contract_no });
};

export const mentoringMonthlyUsageExcelRequest = async (contractId: number) => {
	return await axiosClient.getFile(`${MENTORING_PREFIX}${MAIN_PREFIX}${MONTH_PREFIX}/${contractId}/excel`);
};

type MentoringLogExcelRequestParams = RegistrantsRequestParams;
export const mentoringLogExcelRequest = async (contractId: number, params: MentoringLogExcelRequestParams) => {
	return await axiosClient.getFile(`${MENTORING_PREFIX}/detail/usage/${contractId}/excel`, { params });
};

export const mentoringChartRequest = async (contract_no: number) => {
	return await axiosClient.post(`${MENTORING_PREFIX}${MAIN_PREFIX}/chart`, { contract_no });
};

export const mentoringInsightRequest = async (contractId: number, params: InsightRequestParams) => {
	return await axiosClient.get(`${MENTORING_PREFIX}${MAIN_PREFIX}/getRankPeriod/${contractId}`, { params });
};

export const mentoringSatisfactionRequest = async (contract_no: number, params: SatisfactionRequestParams) => {
	return await axiosClient.post(`${MENTORING_PREFIX}${MAIN_PREFIX}/satisfaction`, { contract_no, ...params });
};

type MentoringLogRequestParams = RegistrantsRequestParams;
export const mentoringLogRequest = async (contract_no: number, params: MentoringLogRequestParams) => {
	return await axiosClient.post(`${MENTORING_PREFIX}${DETAIL_PREFIX}/list`, { contract_no, ...params });
};

export const mentoringLogDetailRequest = async (contract_no: number, request_no: number) => {
	return await axiosClient.post(`${MENTORING_PREFIX}${DETAIL_PREFIX}/usage`, {
		contract_no,
		request_no,
	});
};
