import React, { useEffect } from 'react';
import ReportCampCouponDetailSummary from '@/components/report/eduCampCoupon/organisms/ReportCampCouponDetailSummary';
import ReportCampCouponDetailAssignment from '@/components/report/eduCampCoupon/organisms/ReportCampCouponDetailAssignment';
import ReportCampCouponDetailSchedule from '@/components/report/eduCampCoupon/organisms/ReportCampCouponDetailSchedule';
import { useParams } from 'react-router-dom';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import { getIsFreeSchedule } from '@/utils/date';
import {
	useEduCouponApplicantDetailQuery,
	useEduCouponScheduleDetailQuery,
} from '@/queries/report/eduCampCoupon/useEduCampCouponQuery';
import { EDU_CAMP_DETAIL_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCommon';

const EduCouponUserListDetail = () => {
	const { applicantNo } = useParams();
	const { data: applicantDetailData, isLoading: applicantLoading } = useEduCouponApplicantDetailQuery(
		Number(applicantNo),
	);
	const applicantData = applicantDetailData?.data ?? EDU_CAMP_DETAIL_INITIAL_DATA;
	const { data: couponCampSchedule, isLoading: campScheduleLoading } = useEduCouponScheduleDetailQuery(
		Number(applicantNo),
	);
	const campSchedule = couponCampSchedule?.data;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('수강 현황')));
	}, []);

	return (
		<>
			{!getIsFreeSchedule(applicantData.camp.start_date, applicantData.camp.end_date) && (
				<ReportCampCouponDetailSummary applicantData={applicantData} isLoading={applicantLoading} />
			)}
			<ReportCampCouponDetailAssignment applicantData={applicantData} isLoading={applicantLoading} />
			<ReportCampCouponDetailSchedule campSchedule={campSchedule} isLoading={campScheduleLoading} />
		</>
	);
};

export default EduCouponUserListDetail;
