import React from 'react';
import { Mix } from '@ant-design/charts';
import styled from 'styled-components';
import theme from '@/styles/theme';

const StyledMixedChartContainer = styled.div`
	position: relative;
`;

const SkeletonChart = () => {
	const columnPlotData = [
		{
			date: '1',
			value: 800,
		},
		{
			date: '2',
			value: 500,
		},
		{
			date: '3',
			value: 400,
		},
		{
			date: '4',
			value: 900,
		},
		{
			date: '5',
			value: 600,
		},
		{
			date: '6',
			value: 700,
		},
		{
			date: '7',
			value: 300,
		},
		{
			date: '8',
			value: 600,
		},
		{
			date: '9',
			value: 100,
		},
	];

	const config = {
		appendPadding: 8,
		plots: [
			{
				type: 'column' as const,
				options: {
					data: columnPlotData,
					xField: 'date',
					yField: 'value',
					color: theme['gray-4'],
				},
			},
		],
	};

	return (
		<StyledMixedChartContainer>
			<Mix {...config} />
		</StyledMixedChartContainer>
	);
};

export default SkeletonChart;
