import React from 'react';
import styled from 'styled-components';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';

interface ProgressCard {
	title: string;
	status: boolean[];
}
interface ProgressCardPropTypes {
	progressState: ProgressCard[];
	dotPosition: 'left' | 'right';
}

const ProgressDot = styled.div<{ isActive?: boolean }>`
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: ${props => (props.isActive ? props.theme['blue-7'] : props.theme['gray-4'])};
	&:not(:last-child) {
		margin-right: 4px;
	}
`;

const ProgressTitle = styled(Text)`
	width: 25px;
	white-space: nowrap;
`;

const ProgressCard = ({ progressState, dotPosition }: ProgressCardPropTypes) => {
	return progressState.length ? (
		<div>
			{progressState.map(state => (
				<React.Fragment key={`${state.title}-progress`}>
					<div className="d-flex justify-center align-center">
						{dotPosition === 'left' && (
							<div className="mr-2 d-flex align-center">
								{state.status.map((status, index) => (
									<ProgressDot key={`${state.title}-progress-${index}`} isActive={status} />
								))}
							</div>
						)}
						<ProgressTitle color={theme['gray-7']} fontSize="12px" lineheight="20px">
							{state.title}
						</ProgressTitle>
						{dotPosition === 'right' && (
							<div className="ml-2 d-flex align-center">
								{state.status.map((status, index) => (
									<ProgressDot key={`${state.title}-progress-${index}`} isActive={status} />
								))}
							</div>
						)}
					</div>
				</React.Fragment>
			))}
		</div>
	) : null;
};

export default ProgressCard;
