import React from 'react';
import styled from 'styled-components';
import { StyledBasicTable, StyledTitleRowContainer } from '@/components/common/styledComponents';
import { Button, Col, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { ApplicantData } from '@/types/eduCamp/campCommon';

interface RecordNo {
	no?: number;
}

const StyledTitle = styled.p`
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const StyledButton = styled(Button)`
	color: ${props => props.theme['link']};
	padding: 0;
`;

const StyledCellSection = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const ReportCampCouponDetailAssignment = ({
	applicantData,
	isLoading,
}: {
	applicantData: ApplicantData;
	isLoading: boolean;
}) => {
	const params = useParams();
	const columnList = [
		{
			title: '제출자',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '차수',
			key: 'week',
			dataIndex: 'week',
			render: (week: number) => <StyledCellSection>{`${week}차`}</StyledCellSection>,
		},
		{
			title: '제출일',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (date: string) => <StyledCellSection>{formatDate(date, DATE_FORMAT.FULL_DATE)}</StyledCellSection>,
		},
		{
			title: '피드백',
			key: 'read_at',
			dataIndex: 'read_at',
			render: (date: string) => <StyledCellSection>{date ? '완료' : '대기'}</StyledCellSection>,
		},
		{
			title: '과제 조회',
			key: 'action',
			dataIndex: 'action',
			render: (_: string, record: RecordNo) => (
				<Link
					to={dynamicRoutePath(`${ROUTER_PATH.REPORT.EDU_CAMP_COUPON.ASSIGNMENT_LIST}/${record.no}`, params)}
				>
					<StyledButton type={'link'}>조회하기</StyledButton>
				</Link>
			),
		},
	];
	return (
		<section>
			<StyledTitleRowContainer>
				<StyledTitle>과제 제출 현황</StyledTitle>
			</StyledTitleRowContainer>
			<Row>
				<Col span={24}>
					<StyledBasicTable
						dataSource={applicantData.assignment}
						columns={columnList}
						pagination={false}
						loading={isLoading}
						rowKey={'no'}
						locale={{
							emptyText: <EmptyTableSection />,
						}}
					/>
				</Col>
			</Row>
		</section>
	);
};

export default ReportCampCouponDetailAssignment;
