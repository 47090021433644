import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { convertParamsIntoNumber } from '@/utils/params';
import { useContentMonthlyResultQuery } from '@/queries/report/contents/useContentsQuery';
import { USAGE_DEFAULT_VALUE } from '@/utils/constants/common';
const ReportContentMonthlyUsageContext = createContext({
	reportContentMonthlyUsage: { ...USAGE_DEFAULT_VALUE },
	isLoading: true,
});

const ReportContentMonthlyUsageProvider = ({ children }: { children: React.ReactNode }) => {
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	const { data, isLoading } = useContentMonthlyResultQuery(contractId);
	const reportContentMonthlyUsage = data?.data ?? USAGE_DEFAULT_VALUE;

	return (
		<ReportContentMonthlyUsageContext.Provider value={{ reportContentMonthlyUsage, isLoading }}>
			{children}
		</ReportContentMonthlyUsageContext.Provider>
	);
};

export { ReportContentMonthlyUsageProvider, ReportContentMonthlyUsageContext };
