import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

// defaultText -> alias
const { Text: DefaultText } = Typography;

export interface propTypes {
	children?: React.ReactNode;
	className?: string;
	fontSize?: string;
	fontWeight?: string | number;
	lineheight?: string;
	fontStyle?: string;
	color?: string;
}

// lineHeight가 DefaultText에 전달되는데 DefaultText는 lineHeight prop을 받지 않아 경고 문구 발생
// 그래서 lineHeight는 전달 prop에서 제외
// 참조: https://styled-components.com/docs/api#typescript
export default styled(DefaultText).withConfig({
	shouldForwardProp: prop => !['lineHeight'].includes(prop),
})<propTypes>`
	font-size: ${props => props.fontSize || '14px'};
	font-weight: ${props => props.fontWeight};
	line-height: ${props => props.lineheight};
	font-style: ${props => props.fontStyle};
	color: ${props => props.color};
`;
