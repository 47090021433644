import React, { useEffect } from 'react';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import ReportMentoringSummarySection from '@/components/report/mentoring/organisms/ReportMentoringSummarySection';
import ReportMentoringDataByCategorySection from '@/components/report/mentoring/organisms/ReportMentoringDataByCategorySection';
import ReportMentoringMonthlyUsageGraphSection from '@/components/report/mentoring/organisms/ReportMentoringMonthlyUsageGraphSection';
import ReportMentoringMonthlyUsageTableSection from '@/components/report/mentoring/organisms/ReportMentoringMonthlyUsageTableSection';
import ReportMentoringCheckDetailSection from '@/components/report/mentoring/organisms/ReportMentoringCheckDetailSection';
import ReportMentoringInsightSection from '@/components/report/mentoring/organisms/ReportMentoringInsightSection';
import ReportMentoringSatisfactionSection from '@/components/report/mentoring/organisms/ReportMentoringSatisfactionSection';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import { ReportMentoringMonthlyUsageProvider } from '@/contexts/ReportMentoringMonthlyUsage';

const MentoringHome = () => {
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportMentoringSummarySection />
			<ReportMentoringDataByCategorySection />
			<ReportMentoringMonthlyUsageProvider>
				<ReportMentoringMonthlyUsageGraphSection />
				<ReportMentoringMonthlyUsageTableSection />
			</ReportMentoringMonthlyUsageProvider>
			<ReportMentoringCheckDetailSection />
			<ReportMentoringInsightSection />
			<ReportMentoringSatisfactionSection />
		</DetailSectionContainer>
	);
};

export default MentoringHome;
