import React, { ReactNode } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

// 라이브러리에서 가져옴.
export declare type TooltipPlacement =
	| 'top'
	| 'left'
	| 'right'
	| 'bottom'
	| 'topLeft'
	| 'topRight'
	| 'bottomLeft'
	| 'bottomRight'
	| 'leftTop'
	| 'leftBottom'
	| 'rightTop'
	| 'rightBottom';

export interface propTypes {
	className?: string;
	tooltipContent?: ReactNode;
	tooltipColor: string;
	tooltipPlacement?: TooltipPlacement;
	iconColor: string;
	fontSize?: string;
}

const InfoCircleWithTooltip = ({
	className,
	tooltipPlacement,
	tooltipContent,
	tooltipColor,
	iconColor,
	fontSize,
}: propTypes) => {
	return (
		<Tooltip
			className={className}
			placement={tooltipPlacement || 'right'}
			title={tooltipContent}
			color={tooltipColor}
		>
			<InfoCircleOutlined style={{ color: iconColor, fontSize }} />
		</Tooltip>
	);
};

export default InfoCircleWithTooltip;
