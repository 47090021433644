import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCookie } from '@/utils/cookie';
import { COMENTO_TOKEN } from '@/utils/constants/cookies';
import { ROUTER_PATH } from '@/utils/constants/paths';
import { setPartnerNo } from '@/store/partnerSlice';

interface ExtendedAxiosInstance extends AxiosInstance {
	getFile(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<Blob>>;
}

export function getBaseUrl() {
	const BASE_URL_LIST = {
		test: 'TEST_BASE_URL',
		development: 'VITE_REACT_APP_LOCAL_BASE_URL',
		production: 'VITE_REACT_APP_PRODUCTION_BASE_URL',
	};
	const env = import.meta.env.MODE as keyof typeof BASE_URL_LIST;
	return import.meta.env[BASE_URL_LIST[env]];
}

function createAxiosInstance() {
	const instance = axios.create({
		baseURL: `${getBaseUrl()}/api`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	});

	const extendedInstance: ExtendedAxiosInstance = Object.assign(instance, {
		getFile(url: string, config?: AxiosRequestConfig) {
			return instance.get(url, { ...config, responseType: 'blob' });
		},
	});

	return extendedInstance;
}

const axiosClient: ExtendedAxiosInstance = createAxiosInstance();

axiosClient.interceptors.request.use(function (config) {
	if (config.headers) {
		config.headers.Authorization = `Bearer ${getCookie(COMENTO_TOKEN)}`;
	}
	return config;
});

axiosClient.interceptors.response.use(
	function (response) {
		return response;
	},
	async function (error) {
		if (error.response.status !== 401) {
			return Promise.reject(error);
		}

		if (window.location.href.includes('login')) {
			return Promise.reject(error);
		}

		if (error.request.responseURL.includes('/main/auth/check')) {
			window.location.href = `${window.location.origin}${ROUTER_PATH.LOGIN}`;
		}

		const { data: partnerNo } = await axiosClient.get('main/auth/check');
		setPartnerNo(partnerNo);

		return Promise.reject(error);
	},
);

export default axiosClient;
