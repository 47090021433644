import React from 'react';
import InfoBox from '@/components/common/organisms/InfoBox';
import InfoTitleWithInfoCircle from '@/components/common/molecules/InfoTitleWithInfoCircle';
import { Skeleton, Typography } from 'antd';
import styled from 'styled-components';
import CouponInfoData from '@/components/report/eduCampCoupon/CouponInfoData';
import { flexAlignCenter } from '@/styles/styles';
import { mixin } from '@/styles/theme';
import { numberToLocalString } from '@/utils/number';
import { InfoBoxWithTitleProps } from '@/components/common/organisms/InfoBoxWithTitle/types';

const { Text } = Typography;

const StyledInfoTitleWithInfoCircle = styled(InfoTitleWithInfoCircle)`
	margin-bottom: 4px;
`;

const StyledText = styled(Text)`
	font-size: 24px;
	line-height: 32px;
	color: ${props => props.theme['gray-9']};
`;

const StyledEmptyText = styled(Text)`
	font-size: 14px;
	line-height: 32px;
	color: #d9d9d9;
`;

const StyledSkeleton = styled(Skeleton)`
	.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
		margin-top: 0;
	}
	.ant-skeleton {
		&-title {
			margin: 0;
			height: 16px;
		}
		&-paragraph {
			& > li {
				margin-top: 4px;
				height: 29px;
			}
		}
	}
`;

const StyledContentBox = styled.div`
	${flexAlignCenter};
	${mixin.justifyContent('space-between')};
`;
const InfoBoxWithTitleWithEmpty = ({
	title,
	content,
	tooltipContent,
	couponData = null,
	render,
}: InfoBoxWithTitleProps) => {
	const getSummaryContent = (content: string | number) => {
		const numericContent = Number(content);
		if (isNaN(numericContent)) return content;
		return numberToLocalString(Number(numericContent));
	};

	return (
		<>
			<StyledInfoTitleWithInfoCircle title={title} tooltipContent={tooltipContent} />
			<StyledContentBox>
				{content ? (
					render ? (
						<StyledText>{render(content)}</StyledText>
					) : (
						<StyledText>{getSummaryContent(content)}</StyledText>
					)
				) : (
					<StyledEmptyText>아직 보고할 결과가 없습니다.</StyledEmptyText>
				)}
				{couponData && <CouponInfoData couponInfo={couponData} />}
			</StyledContentBox>
		</>
	);
};

const InfoBoxWithTitle = (props: InfoBoxWithTitleProps) => {
	return (
		<InfoBox>
			{props.isLoading ? (
				<StyledSkeleton paragraph={{ rows: 1 }} active />
			) : (
				<InfoBoxWithTitleWithEmpty {...props} />
			)}
		</InfoBox>
	);
};

export default InfoBoxWithTitle;
