import React from 'react';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import DataTable from '@/components/common/organisms/DataTable';
import styled from 'styled-components';
import { Applicant, ApplicantData } from '@/types/eduCamp/campCommon';

const StyledText = styled.span`
	color: ${props => props.theme['gray-6']};
`;

const StyledTitle = styled.p`
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const StyledSection = styled.section`
	margin-bottom: 60px;
`;

const ReportCampCouponDetailSummary = ({
	applicantData,
	isLoading,
}: {
	applicantData: ApplicantData;
	isLoading: boolean;
}) => {
	const getApplicantAttendance = (array: Applicant[], week: number) => {
		const findWeek = [...array].some(item => item.week === week);
		return findWeek ? 'O' : 'X';
	};
	const data = [
		{
			xAxisTitle: '출석',
			'1차': getApplicantAttendance(applicantData.attendance, 1),
			'2차': <StyledText>라이브 세션 없음</StyledText>,
			'3차': getApplicantAttendance(applicantData.attendance, 3),
			'4차': <StyledText>라이브 세션 없음</StyledText>,
			'5차': getApplicantAttendance(applicantData.attendance, 5),
		},
		{
			xAxisTitle: '과제',
			'1차': getApplicantAttendance(applicantData.assignment, 1),
			'2차': getApplicantAttendance(applicantData.assignment, 2),
			'3차': getApplicantAttendance(applicantData.assignment, 3),
			'4차': getApplicantAttendance(applicantData.assignment, 4),
			'5차': <StyledText>과제 없음</StyledText>,
		},
	];
	// 둘 중 하나
	// 모든 아이템에 key가 빠지지 않고 일정하게 들어간다면 노이슈
	const keyList = Object.keys(data[0]);
	return (
		<StyledSection>
			<StyledTitleRowContainer>
				<StyledTitle>요약정보</StyledTitle>
			</StyledTitleRowContainer>
			<DataTable data={data} keyList={keyList} isLoading={isLoading} width={'178px'} />
		</StyledSection>
	);
};

export default ReportCampCouponDetailSummary;
