export const LOGIN_PATH = '/login';
export const HOME_PATH = '/home';
export const LOG_PATH = '/log';
export const LOGOUT_PATH = '/logout';
export const USER_LIST_PATH = '/user-list';
export const CAMP_LIST_PATH = '/camp-list';
export const COUPON_LIST_PATH = '/coupon-list';
export const ASSIGNMENT_PATH = '/:applicantNo/assignment';

export const CONTENT = 'CONTENT';
export const MENTORING = 'MENTORING';
export const AI = 'AI';
export const EDU_CAMP_PRIVATE = 'EDU_CAMP_PRIVATE';
export const EDU_CAMP_COUPON = 'EDU_CAMP_COUPON';
export const EDU_CAMP_EXTERNSHIP = 'EDU_CAMP_EXTERNSHIP';
export const VOD = 'VOD';
export const EDU_PATH = 'EDU_PATH';
export const EDU_PATH_V2 = 'EDU_PATH_V2';

export const PARTNERS_PATH = `/:partnerId`;
export const PARTNERS_REPORT_PATH = `${PARTNERS_PATH}/report/:contractId`;
export const APPLICANT_NO_PATH = '/:applicantNo';

// 보고서
export const PARTNERS_REPORT_HOME_PATH = `${PARTNERS_PATH}/report${HOME_PATH}`;
export const PARTNERS_REPORT_CONTENT_PATH = `${PARTNERS_REPORT_PATH}/content`;
export const PARTNERS_REPORT_MENTORING_PATH = `${PARTNERS_REPORT_PATH}/mentoring`;
export const PARTNERS_REPORT_AI_PATH = `${PARTNERS_REPORT_PATH}/ai`;
export const PARTNERS_REPORT_EDU_CAMP_PATH = `${PARTNERS_REPORT_PATH}/edu-camp`;
export const PARTNERS_REPORT_EDU_CAMP_JUMP_UP_PATH = `${PARTNERS_REPORT_EDU_CAMP_PATH}/jump-up`;
export const PARTNERS_REPORT_EDU_CAMP_PRIVATE_PATH = `${PARTNERS_REPORT_EDU_CAMP_PATH}/private`;
export const PARTNERS_REPORT_EDU_CAMP_COUPON_PATH = `${PARTNERS_REPORT_EDU_CAMP_PATH}/coupon`;
export const PARTNERS_REPORT_EDU_CAMP_EXTERNSHIP_PATH = `${PARTNERS_REPORT_EDU_CAMP_PATH}/externship`;
export const PARTNERS_REPORT_EDU_PATH = `${PARTNERS_REPORT_PATH}/edu-path`;
export const PARTNERS_REPORT_EDU_PATH_V2_PATH = `${PARTNERS_REPORT_PATH}/edu-path-v2`;
export const PARTNERS_REPORT_VOD_PATH = `${PARTNERS_REPORT_PATH}/vod`;
export const PARTNERS_REPORT_DETAIL_PATH = `/detail/:id`;

// 커뮤니티
export const PARTNERS_BBS_HOME_PATH = `${PARTNERS_PATH}/bbs${HOME_PATH}`;

export const CONTENT_HOME_PATH = `/content${HOME_PATH}`;

export const ROUTER_PATH = {
	LOGIN: `${LOGIN_PATH}`,
	LOGOUT: `${LOGOUT_PATH}`,
	REPORT: {
		HOME: `${PARTNERS_REPORT_HOME_PATH}`,
		CONTENT: {
			HOME: `${PARTNERS_REPORT_CONTENT_PATH}${HOME_PATH}`,
			LOG: `${PARTNERS_REPORT_CONTENT_PATH}${LOG_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_CONTENT_PATH}${USER_LIST_PATH}`,
		},
		MENTORING: {
			HOME: `${PARTNERS_REPORT_MENTORING_PATH}${HOME_PATH}`,
			LOG: `${PARTNERS_REPORT_MENTORING_PATH}${LOG_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_MENTORING_PATH}${USER_LIST_PATH}`,
			DETAIL: `${PARTNERS_REPORT_MENTORING_PATH}${LOG_PATH}${PARTNERS_REPORT_DETAIL_PATH}`,
		},
		AI: {
			HOME: `${PARTNERS_REPORT_AI_PATH}${HOME_PATH}`,
			LOG: `${PARTNERS_REPORT_AI_PATH}${LOG_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_AI_PATH}${USER_LIST_PATH}`,
		},
		EDU_CAMP_JUMP_UP: {
			HOME: `${PARTNERS_REPORT_EDU_CAMP_JUMP_UP_PATH}${HOME_PATH}`,
			CAMP_LIST: `${PARTNERS_REPORT_EDU_CAMP_JUMP_UP_PATH}${CAMP_LIST_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_EDU_CAMP_JUMP_UP_PATH}${USER_LIST_PATH}`,
			ASSIGNMENT_LIST: `${PARTNERS_REPORT_EDU_CAMP_JUMP_UP_PATH}${USER_LIST_PATH}${ASSIGNMENT_PATH}`,
		},
		EDU_CAMP_PRIVATE: {
			HOME: `${PARTNERS_REPORT_EDU_CAMP_PRIVATE_PATH}${HOME_PATH}`,
			CAMP_LIST: `${PARTNERS_REPORT_EDU_CAMP_PRIVATE_PATH}${CAMP_LIST_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_EDU_CAMP_PRIVATE_PATH}${USER_LIST_PATH}`,
			ASSIGNMENT_LIST: `${PARTNERS_REPORT_EDU_CAMP_PRIVATE_PATH}${USER_LIST_PATH}${ASSIGNMENT_PATH}`,
		},
		EDU_CAMP_COUPON: {
			HOME: `${PARTNERS_REPORT_EDU_CAMP_COUPON_PATH}${HOME_PATH}`,
			COUPON_LIST: `${PARTNERS_REPORT_EDU_CAMP_COUPON_PATH}${COUPON_LIST_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_EDU_CAMP_COUPON_PATH}${USER_LIST_PATH}`,
			ASSIGNMENT_LIST: `${PARTNERS_REPORT_EDU_CAMP_COUPON_PATH}${USER_LIST_PATH}${ASSIGNMENT_PATH}`,
		},
		EDU_CAMP_EXTERNSHIP: {
			HOME: `${PARTNERS_REPORT_EDU_CAMP_EXTERNSHIP_PATH}${HOME_PATH}`,
			CAMP_LIST: `${PARTNERS_REPORT_EDU_CAMP_EXTERNSHIP_PATH}${CAMP_LIST_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_EDU_CAMP_EXTERNSHIP_PATH}${USER_LIST_PATH}`,
			ASSIGNMENT_LIST: `${PARTNERS_REPORT_EDU_CAMP_EXTERNSHIP_PATH}${USER_LIST_PATH}${ASSIGNMENT_PATH}`,
		},
		EDU_PATH: {
			HOME: `${PARTNERS_REPORT_EDU_PATH}${HOME_PATH}`,
			CAMP_LIST: `${PARTNERS_REPORT_EDU_PATH}${CAMP_LIST_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_EDU_PATH}${USER_LIST_PATH}`,
			ASSIGNMENT_LIST: `${PARTNERS_REPORT_EDU_PATH}${USER_LIST_PATH}${ASSIGNMENT_PATH}`,
			COUPON_LIST: `${PARTNERS_REPORT_EDU_PATH}${COUPON_LIST_PATH}`,
			USER_DETAIL: `${PARTNERS_REPORT_EDU_PATH}${USER_LIST_PATH}${APPLICANT_NO_PATH}`,
		},
		EDU_PATH_V2: {
			HOME: `${PARTNERS_REPORT_EDU_PATH_V2_PATH}${HOME_PATH}`,
			CAMP_LIST: `${PARTNERS_REPORT_EDU_PATH_V2_PATH}${CAMP_LIST_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_EDU_PATH_V2_PATH}${USER_LIST_PATH}`,
			ASSIGNMENT_LIST: `${PARTNERS_REPORT_EDU_PATH_V2_PATH}${USER_LIST_PATH}${ASSIGNMENT_PATH}`,
			COUPON_LIST: `${PARTNERS_REPORT_EDU_PATH_V2_PATH}${COUPON_LIST_PATH}`,
			USER_DETAIL: `${PARTNERS_REPORT_EDU_PATH_V2_PATH}${USER_LIST_PATH}${APPLICANT_NO_PATH}`,
		},
		VOD: {
			HOME: `${PARTNERS_REPORT_VOD_PATH}${HOME_PATH}`,
			LOG: `${PARTNERS_REPORT_VOD_PATH}${LOG_PATH}`,
			USER_LIST: `${PARTNERS_REPORT_VOD_PATH}${USER_LIST_PATH}`,
		},
	},
	PARTNERS_BBS_HOME: `${PARTNERS_BBS_HOME_PATH}`,
} as const;

interface dynamicRoutePathProps {
	partnerId?: string | number;
	contractId?: string | number;
	id?: string | number;
	applicantNo?: string | number | null;
}

export const dynamicRoutePath = (path: string, { partnerId, contractId, id, applicantNo }: dynamicRoutePathProps) => {
	let initialPath = path;
	if (partnerId) {
		initialPath = initialPath.replace(':partnerId', String(partnerId));
	}
	if (contractId) {
		initialPath = initialPath.replace(':contractId', String(contractId));
	}
	if (id) {
		initialPath = initialPath.replace(':id', String(id));
	}
	if (applicantNo) {
		initialPath = initialPath.replace(':applicantNo', String(applicantNo));
	}
	return initialPath;
};

export const COMENTO_PATH = 'https://comento.kr';
