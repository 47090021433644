import React, { createContext } from 'react';
import { useCookies } from 'react-cookie';
import { COMENTO_TOKEN } from '@/utils/constants/cookies';

interface IAuthProviderProps {
	children: React.ReactNode;
}

const AuthContext = createContext({
	isLogin: false,
});

const AuthProvider = ({ children }: IAuthProviderProps) => {
	const [cookies] = useCookies([COMENTO_TOKEN]);

	return <AuthContext.Provider value={{ isLogin: !!cookies[COMENTO_TOKEN] }}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
