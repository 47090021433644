import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import ReportCampCouponSummarySection from '@/components/report/eduCampCoupon/organisms/ReportCampCouponSummarySection';
import ReportCampCouponCheckDetailSection from '@/components/report/eduCampCoupon/organisms/ReportCampCouponCheckDetailSection';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import ReportCampCouponSatisfactionSection from '@/components/report/eduCampCoupon/organisms/ReportCampCouponSatisfactionSection';
import useContractId from '@/hooks/useContractId';
import { debounce } from 'lodash';
import { useEduCampSatisfaction, useEduCampSummaryQuery } from '@/queries/report/eduCampCoupon/useEduCampCouponQuery';
import { EDU_COUPON_SUMMARY_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCoupon';
import { SATISFACTION_INITIAL_DATA } from '@/utils/constants/common';

const EduCampCouponHome = () => {
	const contractInfo = useSelector(getContractInfo);
	const contractId = useContractId();
	const dispatch = useDispatch();
	const [page, setPage] = useState<number>(1);
	const { data: couponData, isLoading: couponSummaryLoading } = useEduCampSummaryQuery(contractId);
	const couponSummary = couponData?.data.data ?? EDU_COUPON_SUMMARY_INITIAL_DATA;
	const { data: satisfactionData, isLoading: couponSatisfactionLoading } = useEduCampSatisfaction(contractId, page);
	const couponSatisfaction = satisfactionData?.data ?? SATISFACTION_INITIAL_DATA;

	const changePage = useCallback(
		debounce((page: number) => {
			setPage(page);
		}, 3000),
		[],
	);

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportCampCouponSummarySection couponSummary={couponSummary} isLoading={couponSummaryLoading} />
			<ReportCampCouponCheckDetailSection />
			<ReportCampCouponSatisfactionSection
				isLoading={couponSatisfactionLoading}
				satisfactionData={couponSatisfaction}
				page={page}
				changePage={changePage}
			/>
		</DetailSectionContainer>
	);
};

export default EduCampCouponHome;
