import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';

export interface commonState {
	tabs: string;
	sideTab: string;
}

const initialState: commonState = {
	tabs: '1',
	sideTab: '1',
};

const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		changeTabs: (state, action: PayloadAction<string>) => {
			state.tabs = action.payload;
		},
		changeSideTabs: (state, action: PayloadAction<string>) => {
			state.sideTab = action.payload;
		},
	},
});

export const { changeTabs, changeSideTabs } = commonSlice.actions;

export const selectedTabs = (state: RootState) => state.common.tabs;
export const selectedSideTab = (state: RootState) => state.common.sideTab;

export default commonSlice.reducer;
