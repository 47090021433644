export const defaultTablePaginationConfig = {
	showSizeChanger: false,
	position: ['bottomCenter' as const],
	pageSize: 25,
};

export const MAXIMUM_ITEM_COLUMN = {
	FIVE_COLUMN: 5,
	FOUR_COLUMN: 4,
	THREE_COLUMN: 3,
};
