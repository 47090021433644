import React from 'react';
import styled from 'styled-components';
import { mixin } from '@/styles/theme';
import SatisfactionItem from '@/components/common/organisms/SatisfactionItem';
import { StyledScoreContainer } from '@/components/common/styledComponents';
import { flexCenter } from '@/styles/styles';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import SECTION_TITLE from '@/utils/constants/section-title';
import ScoreWithRate from '@/components/common/molecules/ScoreWithRate';
import RateWithProgressBar from '@/components/common/organisms/RateWithProgressBar';
import { Pagination } from 'antd';
import { SatisfactionData } from '@/types/eduCamp/campCommon';

const StyledScoreWrapper = styled.div.attrs({ className: 'd-flex align-center' })`
	${mixin.justifyContent('center')}
	background-color: ${props => props.theme['gray-2']};
	padding: 16px 0;
	border-radius: 2px;
`;

const StyledSatisfactionItem = styled(SatisfactionItem)`
	margin-top: 16px;
`;

const StyledScoreContainerWithMargin = styled(StyledScoreContainer)`
	margin-right: 32px;
`;

const StyledEmptySatisfactionSection = styled.div`
	${flexCenter};
	${mixin.flexDirection('column')};
	background-color: ${props => props.theme['gray-2']};
	align-items: flex-start;
	padding: 16px 32px;
	border-radius: 2px;
`;

const StyledEmptyBoldText = styled.p`
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
`;

const StyledEmptyText = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
`;

const StyledPaginationWrapper = styled.div`
	${flexCenter};
	width: 100%;
	margin-top: 16px;
`;

const ReportCampExternshipSatisfactionSection = ({
	satisfactionData,
	page,
	changePage,
	isLoading,
}: {
	satisfactionData: SatisfactionData;
	page: number;
	isLoading: boolean;
	changePage: (page: number) => void;
}) => {
	const getSatisfactionPercentage = (grade: string, data: SatisfactionData) => {
		const gradeValue = data.grade[`${grade}`];
		return Math.round((100 * gradeValue) / data.all);
	};

	const data = [
		{
			value: 5,
			percent: getSatisfactionPercentage('five', satisfactionData),
		},
		{
			value: 4,
			percent: getSatisfactionPercentage('four', satisfactionData),
		},
		{
			value: 3,
			percent: getSatisfactionPercentage('three', satisfactionData),
		},
		{
			value: 2,
			percent: getSatisfactionPercentage('two', satisfactionData),
		},
		{
			value: 1,
			percent: getSatisfactionPercentage('one', satisfactionData),
		},
	];

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SATISFACTION_SECTION_TITLE}</StyledSectionTitle>
			<>
				{!satisfactionData.all && !isLoading ? (
					<StyledEmptySatisfactionSection>
						<StyledEmptyBoldText>응답자가 없습니다.</StyledEmptyBoldText>
						<StyledEmptyText>데이터가 수집될 때까지 기다려주세요.</StyledEmptyText>
					</StyledEmptySatisfactionSection>
				) : (
					<StyledScoreWrapper>
						<StyledScoreContainerWithMargin>
							<ScoreWithRate score={satisfactionData.avg} isLoading={isLoading} />
						</StyledScoreContainerWithMargin>
						<StyledScoreContainer>
							{data &&
								data.map(item => (
									<RateWithProgressBar
										isLoading={isLoading}
										value={item.value}
										percent={item.percent}
										key={`satisfaction-progress-bar-${item.value}`}
									/>
								))}
						</StyledScoreContainer>
					</StyledScoreWrapper>
				)}
				{!!satisfactionData.all && (
					<>
						{satisfactionData.list.map((item, index) => (
							<StyledSatisfactionItem item={item} key={`satisfactionItem-${index}`} />
						))}
						<StyledPaginationWrapper>
							<Pagination
								defaultCurrent={page}
								onChange={changePage}
								total={satisfactionData.all}
								pageSize={10}
							/>
						</StyledPaginationWrapper>
					</>
				)}
			</>
		</section>
	);
};

export default ReportCampExternshipSatisfactionSection;
