import { contentRegistrantsRequest, RegistrantsRequestParams } from '@/api/report/content';
import { useQuery } from '@tanstack/react-query';
import { REPORT_CONTRACT_INITIAL_DATA } from '@/utils/constants/common';
import { ReportDataResult, UserListResult } from '@/types/common';
import { affListRequest } from '@/api/common';

export const useUserListQuery = (contractId: number, params: RegistrantsRequestParams) => {
	return useQuery<UserListResult>(
		['userList', contractId, params.startDate, params.endDate, params.page],
		() => contentRegistrantsRequest(contractId, params),
		{ onError: error => console.error(error) },
	);
};

export const useReportContractQuery = (partnerId: number) => {
	return useQuery<ReportDataResult>(
		['report', 'contract', partnerId],
		() => affListRequest({ affPartner: partnerId }),
		{ initialData: REPORT_CONTRACT_INITIAL_DATA, onError: error => console.error(error) },
	);
};
