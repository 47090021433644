import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import InfoTitle from '@/components/common/atoms/InfoTitle';
import { InfoTitleWithInfoCircleStyledProps } from '@/components/common/molecules/InfoTitleWithInfoCircle/types';

export const StyledInfoTitle = styled(InfoTitle)<InfoTitleWithInfoCircleStyledProps>`
	line-height: 22px;
	${props => props.tooltipContent && 'margin-right: 4px'}
	${props => props.content && 'margin-bottom: 4px'}
`;

export const InfoTitleWithInfoCircleContainer = styled.div`
	${flexAlignCenter}
`;
