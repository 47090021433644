export const blobResponseType = { responseType: 'blob' as const };

export const downloadFile = (data: Blob, blobType: string, fullFileName: string) => {
	const blob = new Blob([data], { type: blobType });
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.download = fullFileName;
	link.click();
	link.remove();
	URL.revokeObjectURL(url);
};
export const downloadExcelFile = (data: Blob, fileName: string) =>
	downloadFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `${fileName}.xlsx`);
