import React, { createContext } from 'react';
import useContractId from '@/hooks/useContractId';
import { useMentoringGraphDataQuery } from '@/queries/report/mentoring/useMentoringQuery';
import { defaultValue, MONTHLY_USAGE_INITIAL_DATA } from '@/utils/constants/mentoring';

const ReportMentoringMonthlyUsageContext = createContext({
	reportMentoringMonthlyUsage: { ...defaultValue },
	isLoading: true,
});
interface ReportMentoringMonthlyUsageProviderProps {
	children: React.ReactNode;
}

const ReportMentoringMonthlyUsageProvider = ({ children }: ReportMentoringMonthlyUsageProviderProps) => {
	const contractId = useContractId();
	const { data, isLoading } = useMentoringGraphDataQuery(contractId);
	const { month } = data?.data.result ?? MONTHLY_USAGE_INITIAL_DATA;
	const reportMentoringMonthlyUsage = month;

	return (
		<ReportMentoringMonthlyUsageContext.Provider value={{ reportMentoringMonthlyUsage, isLoading }}>
			{children}
		</ReportMentoringMonthlyUsageContext.Provider>
	);
};

export { ReportMentoringMonthlyUsageProvider, ReportMentoringMonthlyUsageContext };
