import React from 'react';
import { Column } from '@ant-design/plots';
import theme from '@/styles/theme';

const ReportMentoringMonthlyUsageGraphSection = () => {
	const data = [
		{
			date: '2020-03',
			value: 17,
			type: '상담 요청',
		},
		{
			date: '2020-04',
			value: 79,
			type: '상담 요청',
		},
		{
			date: '2020-05',
			value: 38,
			type: '상담 요청',
		},
		{
			date: '2020-06',
			value: 55,
			type: '상담 요청',
		},
		{
			date: '2020-07',
			value: 40,
			type: '상담 요청',
		},
		{
			date: '2020-08',
			value: 57,
			type: '상담 요청',
		},
		{
			date: '2020-09',
			value: 108,
			type: '상담 요청',
		},
		{
			date: '2020-10',
			value: 63,
			type: '상담 요청',
		},
		{
			date: '2020-11',
			value: 72,
			type: '상담 요청',
		},
		{
			date: '2020-12',
			value: 72,
			type: '상담 요청',
		},
		{
			date: '2021-01',
			value: 60,
			type: '상담 요청',
		},
		{
			date: '2021-02',
			value: 62,
			type: '상담 요청',
		},
		{
			date: '2021-03',
			value: 91,
			type: '상담 요청',
		},
		{
			date: '2021-04',
			value: 30,
			type: '상담 요청',
		},
		{
			date: '2020-03',
			value: 63,
			type: '자소서 첨삭',
		},
		{
			date: '2020-04',
			value: 179,
			type: '자소서 첨삭',
		},
		{
			date: '2020-05',
			value: 57,
			type: '자소서 첨삭',
		},
		{
			date: '2020-06',
			value: 46,
			type: '자소서 첨삭',
		},
		{
			date: '2020-07',
			value: 50,
			type: '자소서 첨삭',
		},
		{
			date: '2020-08',
			value: 97,
			type: '자소서 첨삭',
		},
		{
			date: '2020-09',
			value: 379,
			type: '자소서 첨삭',
		},
		{
			date: '2020-10',
			value: 69,
			type: '자소서 첨삭',
		},
		{
			date: '2020-11',
			value: 50,
			type: '자소서 첨삭',
		},
		{
			date: '2020-12',
			value: 45,
			type: '자소서 첨삭',
		},
		{
			date: '2021-01',
			value: 22,
			type: '자소서 첨삭',
		},
		{
			date: '2021-02',
			value: 58,
			type: '자소서 첨삭',
		},
		{
			date: '2021-03',
			value: 141,
			type: '자소서 첨삭',
		},
		{
			date: '2021-04',
			value: 26,
			type: '자소서 첨삭',
		},
		{
			date: '2020-03',
			value: 1,
			type: '면접 질문',
		},
		{
			date: '2020-04',
			value: 6,
			type: '면접 질문',
		},
		{
			date: '2020-05',
			value: 24,
			type: '면접 질문',
		},
		{
			date: '2020-06',
			value: 23,
			type: '면접 질문',
		},
		{
			date: '2020-07',
			value: 44,
			type: '면접 질문',
		},
		{
			date: '2020-08',
			value: 2,
			type: '면접 질문',
		},
		{
			date: '2020-09',
			value: 18,
			type: '면접 질문',
		},
		{
			date: '2020-10',
			value: 34,
			type: '면접 질문',
		},
		{
			date: '2020-11',
			value: 61,
			type: '면접 질문',
		},
		{
			date: '2020-12',
			value: 22,
			type: '면접 질문',
		},
		{
			date: '2021-01',
			value: 9,
			type: '면접 질문',
		},
		{
			date: '2021-02',
			value: 16,
			type: '면접 질문',
		},
		{
			date: '2021-03',
			value: 17,
			type: '면접 질문',
		},
		{
			date: '2021-04',
			value: 0,
			type: '면접 질문',
		},
	];
	const config = {
		data,
		xField: 'date',
		yField: 'value',
		seriesField: 'type',
		isGroup: true,
		color: [theme['gray-4'], theme['gray-4'], theme['gray-4']],
	};

	return <Column {...config} />;
};

export default ReportMentoringMonthlyUsageGraphSection;
