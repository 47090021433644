import { createGlobalStyle, css } from 'styled-components';
import { reset as resetStyle } from 'styled-reset';

const webFonts = css`
      'Pretendard',
      'Apple SD Gothic Neo',
      'Helvetica Neue', Helvetica, Arial,
      'Trebuchet MS',
      sans-serif;
`;

const GlobalStyle = createGlobalStyle`
  ${resetStyle};
  @font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }
  body {
    background-color: #F0F2F5;
    font-family: ${webFonts};
  }
  .ant-tooltip {
    &-inner {
      white-space: pre-wrap;
    }
  }
  // 테이블과 pagination 사이 간격
  .ant-table-pagination.ant-pagination {
    margin: 24px 0 0 0;
  }
`;

export default GlobalStyle;
