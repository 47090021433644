import React, { useContext } from 'react';
import MixedChart from '@/components/common/MixedChart';
import SECTION_TITLE from '@/utils/constants/section-title';
import { ReportContentMonthlyUsageContext } from '@/contexts/ReportContentMonthlyUsage';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';

const ReportContentMonthlyUsageGraphSection = () => {
	const { reportContentMonthlyUsage } = useContext(ReportContentMonthlyUsageContext);

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.MONTHLY_USAGE_GRAPH_SECTION_TITLE}</StyledSectionTitle>
			<MixedChart
				columnPlotData={reportContentMonthlyUsage.barGraph}
				linePlotData={reportContentMonthlyUsage.lineGraph}
			/>
		</section>
	);
};

export default ReportContentMonthlyUsageGraphSection;
