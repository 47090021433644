import axiosClient from '@/api';
import { SatisfactionType } from '@/components/common/SatisfactionContent';

const CONTENT_PREFIX = '/content';
const SUMMARY_PREFIX = '/summary';
const MONTHLY_PREFIX = '/monthly';
const INSIGHT_PREFIX = '/insight';
const SATISFACTION_PREFIX = '/satisfaction';
const REGISTRANTS_PREFIX = '/registrants';

export const contentSummaryRequest = async (contractId: number) => {
	return await axiosClient.get(`${CONTENT_PREFIX}${SUMMARY_PREFIX}/${contractId}`);
};

export const contentMonthlyUsageRequest = async (contractId: number) => {
	return await axiosClient.get(`${CONTENT_PREFIX}${MONTHLY_PREFIX}/${contractId}`);
};

export const contentMonthlyUsageExcelRequest = async (contractId: number) => {
	return await axiosClient.get(`${CONTENT_PREFIX}${MONTHLY_PREFIX}/${contractId}?excel=1`, { responseType: 'blob' });
};

export type Period = 'default' | '6monthly' | '3monthly' | 'monthly' | 'weekly';
export interface InsightRequestParams {
	type: 'content' | 'ai' | 'mentoring';
	period: Period;
}
export const contentInsightRequest = async (contractId: number, params: InsightRequestParams) => {
	return await axiosClient.get(`${CONTENT_PREFIX}${INSIGHT_PREFIX}/${contractId}`, { params });
};

export interface SatisfactionRequestParams {
	// readCase: 콘텐츠
	// readAnswer: 멘토링
	// analyticsResult: AI
	// vodList: VOD
	type: SatisfactionType;
}
export const contentSatisfactionRequest = async (contractId: number, params: SatisfactionRequestParams) => {
	return await axiosClient.get(`${CONTENT_PREFIX}${SATISFACTION_PREFIX}/${contractId}`, { params });
};

export interface RegistrantsRequestParams {
	startDate?: string;
	endDate?: string;
	page?: number;
}
type ContentRegistrantsRequestParams = RegistrantsRequestParams;
export const contentRegistrantsRequest = async (contractId: number, params: ContentRegistrantsRequestParams) => {
	return await axiosClient.get(`${CONTENT_PREFIX}${REGISTRANTS_PREFIX}/${contractId}`, { params });
};

type ContentLogRequestParams = RegistrantsRequestParams;
export const contentLogRequest = async (contractId: number, params: ContentLogRequestParams) => {
	return await axiosClient.get(`${CONTENT_PREFIX}/used/status/${contractId}`, { params });
};

type ContentLogExcelRequestParams = RegistrantsRequestParams;
export const contentLogExcelRequest = async (contractId: number, params: ContentLogExcelRequestParams) => {
	return await axiosClient.get(`${CONTENT_PREFIX}/used/status/${contractId}?excel=1`, {
		params,
		responseType: 'blob',
	});
};

type ContentRegistrantsExcelRequestParams = RegistrantsRequestParams;
export const contentRegistrantsExcelRequest = async (
	contractId: number,
	params: ContentRegistrantsExcelRequestParams,
) => {
	return await axiosClient.getFile(`${CONTENT_PREFIX}${REGISTRANTS_PREFIX}/${contractId}?excel=1`, {
		params,
	});
};
