import { useUpdateListHeader } from '@/hooks/useUpdateListHeader';
// import EduPathV2AttendanceSection from '@/components/report/eduPathV2CampCoupon/organisms/EduPathV2AttendanceSection';
import { useEduCampPathV2AttendanceListData } from '@/hooks/useEduCampPathV2AttendanceListData';
import { Link, useParams } from 'react-router-dom';
import { EduPathV2Attendance } from '@/types/eduCamp/path';
import { booleanToOX } from '@/utils/data';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import React from 'react';
import { StyledBasicTable, StyledTitleRowContainer } from '@/components/common/styledComponents';
import { Button, Col, Row } from 'antd';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import styled from 'styled-components';

const StyledTitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const StyledButton = styled(Button)`
	color: ${props => props.theme['link']};
	padding: 0;
`;

const EduPathV2PrivateUserListDetail = () => {
	const params = useParams();

	useUpdateListHeader('수강 현황');

	const { data, isLoading } = useEduCampPathV2AttendanceListData();

	const columnList = [
		{
			title: '캠프명',
			key: 'camp_title',
			dataIndex: 'camp_title',
			width: '420px',
			align: 'left' as const,
			render: (camp_title: EduPathV2Attendance['camp_title']) => {
				return <p>{camp_title ?? '아직 신청한 실무과제가 없어요.'}</p>;
			},
		},
		{
			title: 'VOD 시청',
			dataIndex: 'vod',
			key: 'vod',
			width: '198px',
			align: 'center' as const,
			render: (value: EduPathV2Attendance['vod']) => {
				// TODO: booleanToOX 다른 곳에도 적용
				return <p>{booleanToOX(value)}</p>;
			},
		},
		{
			title: '과제 제출',
			key: 'assignment_date',
			dataIndex: 'assignment_date',
			width: '198px',
			render: (assignment_date: EduPathV2Attendance['assignment_date']) => {
				return <p>{assignment_date ? formatDate(assignment_date, DATE_FORMAT.FULL_DATE) : '-'}</p>;
			},
		},
		{
			title: '과제 조회',
			width: '198px',
			render: (attendance: EduPathV2Attendance) => {
				return (
					<>
						{attendance.assignment_no ? (
							<Link
								to={dynamicRoutePath(
									`${ROUTER_PATH.REPORT.EDU_PATH_V2.ASSIGNMENT_LIST}/${attendance.applicant_no}`,
									params,
								)}
							>
								<StyledButton type={'link'}>조회하기</StyledButton>
							</Link>
						) : (
							''
						)}
					</>
				);
			},
		},
	];

	return (
		<section>
			<StyledTitleRowContainer>
				<StyledTitle>수강 현황</StyledTitle>
			</StyledTitleRowContainer>
			<Row>
				<Col span={24}>
					<StyledBasicTable
						dataSource={data}
						columns={columnList}
						pagination={false}
						loading={isLoading}
						rowKey={'no'}
						locale={{
							emptyText: <EmptyTableSection />,
						}}
					/>
				</Col>
			</Row>
		</section>
	);
};

export default EduPathV2PrivateUserListDetail;
