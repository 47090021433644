import React from 'react';
import { Layout, Menu } from 'antd';
import {
	FileOutlined,
	FlagOutlined,
	FundOutlined,
	MessageOutlined,
	UsergroupAddOutlined,
	YoutubeOutlined,
	BankOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	dynamicRoutePath,
	PARTNERS_REPORT_AI_PATH,
	PARTNERS_REPORT_CONTENT_PATH,
	PARTNERS_REPORT_EDU_CAMP_COUPON_PATH,
	PARTNERS_REPORT_EDU_CAMP_EXTERNSHIP_PATH,
	PARTNERS_REPORT_EDU_CAMP_PRIVATE_PATH,
	PARTNERS_REPORT_MENTORING_PATH,
	PARTNERS_REPORT_VOD_PATH,
	PARTNERS_REPORT_EDU_PATH,
	ROUTER_PATH,
	PARTNERS_REPORT_EDU_CAMP_JUMP_UP_PATH,
} from '@/utils/constants/paths';
import { useDispatch, useSelector } from 'react-redux';
import { changeSideTabs } from '@/store/commonSlice';
import type { Params } from 'react-router-dom';
import Logo from '@/components/common/molecules/Logo';
import { getAllowProduct } from '@/store/contractSlice';

const { Sider } = Layout;

const SideMenu: React.FC = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const allowProduct = useSelector(getAllowProduct);

	const getRoutePath = (pathname: string, params: Params): string => {
		if (!Object.keys(params).length) return pathname;

		let path = pathname;
		Object.entries(params).forEach(([paramName, paramValue]) => {
			if (paramValue) {
				path = path.replace(paramValue, `:${paramName}`);
			}
		});
		return path;
	};

	const currentUrlPattern = getRoutePath(pathname, params);

	const findPath = (): string => {
		const target = SIDE_TAB_MENU.find(menu => currentUrlPattern.includes(menu.keyPattern));
		return target ? String(target.id) : '0';
	};

	return (
		<Sider
			width={236}
			style={{
				overflow: 'auto',
				height: '100vh',
				position: 'fixed',
				left: 0,
				top: 0,
				bottom: 0,
			}}
		>
			<Logo />
			<Menu
				theme="dark"
				defaultSelectedKeys={[findPath()]}
				mode="inline"
				onSelect={tab => dispatch(changeSideTabs(tab.key))}
			>
				{SIDE_TAB_MENU.filter(item => allowProduct.includes(item.tabName)).map(item => (
					<Menu.Item key={item.id} onClick={() => navigate(dynamicRoutePath(item.path, params))}>
						{item.icon}
						<span>{item.tabName}</span>
					</Menu.Item>
				))}
			</Menu>
		</Sider>
	);
};

export default SideMenu;

const SIDE_TAB_MENU = [
	{
		id: 1,
		tabName: '콘텐츠',
		icon: <FileOutlined />,
		path: ROUTER_PATH.REPORT.CONTENT.HOME,
		keyPattern: PARTNERS_REPORT_CONTENT_PATH,
	},
	{
		id: 2,
		tabName: '멘토링',
		icon: <MessageOutlined />,
		path: ROUTER_PATH.REPORT.MENTORING.HOME,
		keyPattern: PARTNERS_REPORT_MENTORING_PATH,
	},
	{
		id: 3,
		tabName: 'AI 자기소개서 분석기',
		icon: <FundOutlined />,
		path: ROUTER_PATH.REPORT.AI.HOME,
		keyPattern: PARTNERS_REPORT_AI_PATH,
	},
	{
		id: 4,
		tabName: '직무부트캠프(개설형)',
		icon: <FlagOutlined />,
		path: ROUTER_PATH.REPORT.EDU_CAMP_PRIVATE.HOME,
		keyPattern: PARTNERS_REPORT_EDU_CAMP_PRIVATE_PATH,
	},
	{
		id: 5,
		tabName: '직무부트캠프(쿠폰형)',
		icon: <FlagOutlined />,
		path: ROUTER_PATH.REPORT.EDU_CAMP_COUPON.HOME,
		keyPattern: PARTNERS_REPORT_EDU_CAMP_COUPON_PATH,
	},
	{
		id: '5b',
		tabName: '직무부트캠프(점프업)',
		icon: <FlagOutlined />,
		path: ROUTER_PATH.REPORT.EDU_CAMP_JUMP_UP.HOME,
		keyPattern: PARTNERS_REPORT_EDU_CAMP_JUMP_UP_PATH,
	},
	{
		id: 6,
		tabName: '직무 VOD',
		icon: <YoutubeOutlined />,
		path: ROUTER_PATH.REPORT.VOD.HOME,
		keyPattern: PARTNERS_REPORT_VOD_PATH,
	},
	{
		id: 7,
		tabName: '익스턴십',
		icon: <UsergroupAddOutlined />,
		path: ROUTER_PATH.REPORT.EDU_CAMP_EXTERNSHIP.HOME,
		keyPattern: PARTNERS_REPORT_EDU_CAMP_EXTERNSHIP_PATH,
	},
	{
		id: 8,
		tabName: '진로부트캠프',
		icon: <BankOutlined />,
		path: ROUTER_PATH.REPORT.EDU_PATH.HOME,
		keyPattern: PARTNERS_REPORT_EDU_PATH,
	},
];
