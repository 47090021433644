import React, { useContext, useState } from 'react';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import Title from '@/components/common/Typography/Title';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import DataTable from '@/components/common/organisms/DataTable';
import SECTION_TITLE from '@/utils/constants/section-title';
import { ReportMentoringMonthlyUsageContext } from '@/contexts/ReportMentoringMonthlyUsage';
import useContractId from '@/hooks/useContractId';
import usePartnerName from '@/hooks/usePartnerName';
import { mentoringMonthlyUsageExcelRequest } from '@/api/report/mentoring';
import { downloadExcelFile } from '@/utils/download';
import { PRODUCTS } from '@/utils/constants/products';
import { defaultMonthlyUsageItem } from '@/utils/constants/mentoring';

const ReportMentoringMonthlyUsageTableSection = () => {
	const {
		reportMentoringMonthlyUsage: { table },
		isLoading,
	} = useContext(ReportMentoringMonthlyUsageContext);
	const contractId = useContractId();
	const partnerName = usePartnerName();
	const [isLoadingDownload, setLoadingDownload] = useState(false);

	const handleClickDownloadButton = async () => {
		try {
			setLoadingDownload(true);
			const { data } = await mentoringMonthlyUsageExcelRequest(contractId);
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.MENTORING}-월별 이용 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	const covertDataToTableData = (data: any) => {
		if (data && data.qa) {
			if (data.qa[0] == defaultMonthlyUsageItem) {
				// loading시에 배경으로 보여줄 레이아웃
				// return new Array(4).fill({});
				return [];
			} else {
				return [
					{
						xAxisTitle: 'Q&A',
						...data.qa,
					},
					{
						xAxisTitle: '자소서 첨삭',
						...data.ce,
					},
					{
						xAxisTitle: '면접 질문',
						...data.in,
					},
					{
						xAxisTitle: '합계',
						...data.total,
					},
				];
			}
		} else {
			return [];
		}
	};

	const convertedData = covertDataToTableData(table);
	const keyList = convertedData.length === 0 ? [] : Object.keys(convertedData[0]);

	return (
		<section>
			<StyledTitleRowContainer>
				<Title>{SECTION_TITLE.MONTHLY_USAGE_TABLE_SECTION_TITLE}</Title>
				<DownloadButton loading={isLoadingDownload} onClick={handleClickDownloadButton}>
					다운로드
				</DownloadButton>
			</StyledTitleRowContainer>
			<DataTable data={convertedData} keyList={keyList} isLoading={isLoading} />
		</section>
	);
};

export default ReportMentoringMonthlyUsageTableSection;
