import React from 'react';
import styled from 'styled-components';
import { flexCenter } from '@/styles/styles';
import { mixin } from '@/styles/theme';

export const EMPTY_TABLE_SECTION_HEIGHT = {
	DEFAULT: '619px',
	ASSIGNMENT: '216px',
} as const;

const StyledEmptyTable = styled.div<EmptyTableSectionStyledProps>`
	${flexCenter};
	height: ${props => props.height};
`;

const StyledEmptyTextContainer = styled.div`
	${mixin.flexBox};
	${mixin.flexDirection('column')};
`;

interface EmptyTableSectionStyledProps {
	height?: string;
}
type EmptyTableSectionProps = EmptyTableSectionStyledProps;

const EmptyTableSection = ({ height = EMPTY_TABLE_SECTION_HEIGHT.DEFAULT }: EmptyTableSectionProps) => {
	return (
		<StyledEmptyTable height={height}>
			<StyledEmptyTextContainer>
				<p>보고할 데이터가 없습니다</p>
				<p>데이터가 수집될 때까지 기다리거나, 필터 조건을 변경해보세요</p>
			</StyledEmptyTextContainer>
		</StyledEmptyTable>
	);
};

export default EmptyTableSection;
