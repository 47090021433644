import React from 'react';
import styled from 'styled-components';
import SECTION_TITLE from '@/utils/constants/section-title';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import SatisfactionSummary from '@/components/common/organisms/SatisfactionSummary';
import useContractId from '@/hooks/useContractId';
import { useVodSatisfaction } from '@/queries/report/vod/useVodQuery';
import { VOD_SATISFACTION_INITIAL_DATA } from '@/utils/constants/vod';

const StyledSatisfactionSummaryContainer = styled.div`
	margin-bottom: 16px;
`;

const ReportVODSatisfactionSection = () => {
	const contractId = useContractId();
	const { data, isLoading } = useVodSatisfaction(contractId);
	const { avg, satisfaction } = data ?? VOD_SATISFACTION_INITIAL_DATA;

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SATISFACTION_SECTION_TITLE}</StyledSectionTitle>
			<StyledSatisfactionSummaryContainer>
				<SatisfactionSummary score={avg} data={satisfaction} isLoading={isLoading} />
			</StyledSatisfactionSummaryContainer>
		</section>
	);
};

export default ReportVODSatisfactionSection;
