import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectedTabs } from '@/store/commonSlice';
import HeaderLayout from '@/layouts/HeaderLayout';
import { Community, Report } from '@/components/TabContent';
import HomeHeader from '@/components/Header/HomeHeader';

const { Content } = Layout;

export interface TabTypes {
	[index: string]: JSX.Element;
}

const Home: React.FC = () => {
	const tabs = useSelector(selectedTabs);

	const tabItems: TabTypes = {
		'1': <Report />,
		'2': <Community />,
	};

	return (
		<>
			<HeaderLayout isGhostStyle={true}>
				<HomeHeader />
			</HeaderLayout>
			<HomeContent>{tabItems[tabs]}</HomeContent>
		</>
	);
};

export default Home;

const HomeContent = styled(Content)`
	padding: 0 50px;
	margin-top: 64px;
	tr.ant-table-row {
		cursor: pointer;
	}
`;
