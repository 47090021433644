import React from 'react';
import { useEduCampPathV2SummaryData } from '@/hooks/useEduCampPathV2Data';
import useContractId from '@/hooks/useContractId';
import ReportInfoSection from '@/components/common/organisms/ReportInfoSection';

const EduPathV2GraduateSection = () => {
	const contractId = useContractId();
	const { graduateStatistics, isFetching } = useEduCampPathV2SummaryData(contractId);

	const data = [
		{
			title: 'VOD 1개 이상 수강 인원',
			key: 'vod_users_count',
			content: graduateStatistics.vod_users_count,
		},
		{
			title: '과제 1개 이상 신청 인원',
			key: 'applicants_assignment_count',
			content: graduateStatistics.applicants_assignment_count,
		},
		{
			title: '과제 1개 이상 제출 인원(수료 인원)',
			key: 'completed_count',
			content: graduateStatistics.completed_count,
		},
	];

	return <ReportInfoSection title={'수료자 현황'} data={data} isLoading={isFetching} />;
};

export default EduPathV2GraduateSection;
