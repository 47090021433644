import React, { useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { matchRoutes, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getHeaderSubTitle, getHeaderTitle } from '@/store/headerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { contractInfoRequest } from '@/api/common';
import { convertParamsIntoNumber } from '@/utils/params';
import { setContractInfo } from '@/store/contractSlice';
import { Skeleton } from 'antd';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { flexAlignCenter } from '@/styles/styles';

const DetailHeaderTitle = styled.h1`
	margin: 0 12px 0 15px;
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const DetailSubTitle = styled.h2`
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	color: ${props => props.theme['gray-7']};
`;

const StyledSkeleton = styled(Skeleton)`
	.ant-skeleton-title {
		margin-top: 0;
		height: 28px;
	}
`;

const StyledBackButtonWrapper = styled.div`
	cursor: pointer;
	${flexAlignCenter}
`;

const DetailHeader = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const contractId = convertParamsIntoNumber(params.contractId);
	const headerTitle = useSelector(getHeaderTitle);
	const headerSubTitle = useSelector(getHeaderSubTitle);
	const location = useLocation();
	const isHome = matchRoutes(
		[
			{ path: ROUTER_PATH.REPORT.CONTENT.HOME },
			{ path: ROUTER_PATH.REPORT.MENTORING.HOME },
			{ path: ROUTER_PATH.REPORT.AI.HOME },
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_PRIVATE.HOME },
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_COUPON.HOME },
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_EXTERNSHIP.HOME },
			{ path: ROUTER_PATH.REPORT.EDU_PATH.HOME },
			{ path: ROUTER_PATH.REPORT.VOD.HOME },
		],
		location,
	);
	const isContentLog = matchRoutes(
		[{ path: ROUTER_PATH.REPORT.CONTENT.LOG }, { path: ROUTER_PATH.REPORT.CONTENT.USER_LIST }],
		location,
	);
	const isMentoringLog = matchRoutes(
		[{ path: ROUTER_PATH.REPORT.MENTORING.LOG }, { path: ROUTER_PATH.REPORT.MENTORING.USER_LIST }],
		location,
	);
	const isAILog = matchRoutes(
		[{ path: ROUTER_PATH.REPORT.AI.LOG }, { path: ROUTER_PATH.REPORT.AI.USER_LIST }],
		location,
	);
	const isCampCouponLog = matchRoutes(
		[
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_COUPON.COUPON_LIST },
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_COUPON.USER_LIST },
		],
		location,
	);
	const isEduPathCampCouponLog = matchRoutes(
		[{ path: ROUTER_PATH.REPORT.EDU_PATH.COUPON_LIST }, { path: ROUTER_PATH.REPORT.EDU_PATH.USER_LIST }],
		location,
	);
	const isCampPrivateLog = matchRoutes(
		[
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_PRIVATE.CAMP_LIST },
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_PRIVATE.USER_LIST },
		],
		location,
	);
	const isCampExternshipLog = matchRoutes(
		[
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_EXTERNSHIP.CAMP_LIST },
			{ path: ROUTER_PATH.REPORT.EDU_CAMP_EXTERNSHIP.USER_LIST },
		],
		location,
	);
	const isVODLog = matchRoutes(
		[{ path: ROUTER_PATH.REPORT.VOD.LOG }, { path: ROUTER_PATH.REPORT.VOD.USER_LIST }],
		location,
	);

	useEffect(() => {
		let isComponentMounted = true;
		const fetchContractInfo = async (contractId: number) => {
			try {
				const { data } = await contractInfoRequest(contractId);
				if (isComponentMounted) {
					dispatch(setContractInfo(data));
				}
			} catch (error) {
				console.error(error);
			}
		};
		fetchContractInfo(contractId);
		return () => {
			isComponentMounted = false;
		};
	}, [contractId]);

	const handleClickBackButton = () => {
		if (isHome) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.HOME, params));
		if (isContentLog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.CONTENT.HOME, params));
		if (isMentoringLog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.MENTORING.HOME, params));
		if (isAILog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.AI.HOME, params));
		if (isCampCouponLog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.EDU_CAMP_COUPON.HOME, params));
		if (isEduPathCampCouponLog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.EDU_PATH.HOME, params));
		if (isCampPrivateLog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.EDU_CAMP_PRIVATE.HOME, params));
		if (isCampExternshipLog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.EDU_CAMP_EXTERNSHIP.HOME, params));
		if (isVODLog) return navigate(dynamicRoutePath(ROUTER_PATH.REPORT.VOD.HOME, params));
		return navigate(-1);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<StyledBackButtonWrapper onClick={handleClickBackButton}>
				<ArrowLeftOutlined style={{ fontSize: '24px' }} />
			</StyledBackButtonWrapper>
			{headerTitle ? (
				<DetailHeaderTitle>{headerTitle}</DetailHeaderTitle>
			) : (
				<StyledSkeleton paragraph={false} active style={{ width: '200px', margin: '0 12px' }} />
			)}
			{headerSubTitle ? (
				<DetailSubTitle>{headerSubTitle}</DetailSubTitle>
			) : (
				<StyledSkeleton paragraph={false} active style={{ width: '400px' }} />
			)}
		</div>
	);
};

export default DetailHeader;
