import React from 'react';
import styled from 'styled-components';
import { Rate } from 'antd';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { SatisfactionList } from '@/types/eduCamp/campCommon';

const StyledSatisfactionItemContainer = styled.div`
	background-color: ${props => props.theme['gray-1']};
	border: 1px solid ${props => props.theme['gray-4']};
	padding: 16px 32px;
`;

const StyledRate = styled(Rate)`
	margin-right: 4px;
	.ant-rate-star:not(:last-child) {
		margin-right: 4.15px;
	}
`;

const StyledRateInfoContainer = styled.div`
	margin-bottom: 8px;
`;

const StyledRateContainer = styled.div`
	margin-bottom: 12px;
`;

interface propTypes {
	className?: string;
	item: SatisfactionList;
}

const getRateText = (score: number) => {
	switch (parseInt(String(score), 10)) {
		case 5:
			return '매우 추천해요!';
		case 4:
			return '추천해요!';
		case 3:
			return '보통이에요!';
		case 2:
			return '별로예요!';
		case 1:
			return '매우 별로예요!';
	}
};

const StyledSatisfactionItemText = styled(Text)`
	color: ${props => props.theme['gray-7']};
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
`;

const StyledSatisfactionUserText = styled(Text)`
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
	font-weight: 400;
`;

const SatisfactionItem = ({ className, item }: propTypes) => {
	return (
		<StyledSatisfactionItemContainer className={className}>
			<StyledRateInfoContainer>
				<StyledRate value={item.rating} />
				<StyledSatisfactionItemText color={theme['gray-7']}>
					{getRateText(item.rating)}
				</StyledSatisfactionItemText>
			</StyledRateInfoContainer>
			<StyledRateContainer>
				<StyledSatisfactionUserText>{item.answer}</StyledSatisfactionUserText>
			</StyledRateContainer>
			<div>
				<StyledSatisfactionItemText>
					{item.name} | {formatDate(item.created_at, DATE_FORMAT.FULL_DATE)}
				</StyledSatisfactionItemText>
			</div>
		</StyledSatisfactionItemContainer>
	);
};

export default SatisfactionItem;
