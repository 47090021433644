import { useQuery } from '@tanstack/react-query';
import {
	getEduPathApplicantList,
	getEduPathApplicantStatus,
	getEduPathAssignments,
	getEduPathCouponList,
	getEduPathInfo,
} from '@/api/report/eduPath';
import { EduPathApplicantListResult, EduPathAttendanceResult, EduPathSummaryResult } from '@/types/eduCamp/path';
import { unUsedCouponListDataResult } from '@/types/eduCamp/coupon';
import { AssignmentDataQueryResult } from '@/types/eduCamp/campCommon';

export const useEduCampPathSummary = (contractId: number) => {
	return useQuery<EduPathSummaryResult>(['edu', 'path', 'home', contractId], () => getEduPathInfo(contractId), {
		onError: error => console.error(error),
	});
};

export const useEduCampPathCouponList = (contractId: number) => {
	return useQuery<unUsedCouponListDataResult>(
		['edu', 'path', 'coupon', 'unUsed', contractId],
		() => getEduPathCouponList(contractId),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPathApplicantList = (contractId: number) => {
	return useQuery<EduPathApplicantListResult>(
		['edu', 'path', 'applicant', 'list', contractId],
		() => getEduPathApplicantList(contractId),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPathAttendance = (contractId: number, userList?: string) => {
	return useQuery<EduPathAttendanceResult>(
		['edu', 'path', 'list', 'detail', contractId, userList],
		() => getEduPathApplicantStatus({ contractId, userList }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampAssignment = (contractId: number, assignmentNo?: string) => {
	return useQuery<AssignmentDataQueryResult>(
		['edu', 'path', 'assignment', contractId, assignmentNo],
		() => getEduPathAssignments({ contractId, eduApplicant: assignmentNo }),
		{
			onError: error => console.error(error),
		},
	);
};
