import { useCallback, useEffect, useMemo } from 'react';
import TableHeaderWithInfoCircle from '@/components/common/molecules/tableHeaderWithInfoCircle';
import { Col, ConfigProvider, Row } from 'antd';
import { StyledUserCountContainer } from '@/components/UserList/UserList';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { toFixedWithoutTrailingZeroes } from '@/utils/number';
import { StyledBasicTable } from '@/components/common/styledComponents';
import styled from 'styled-components';
import useContractId from '@/hooks/useContractId';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { ColumnsType } from 'antd/lib/table';
import ProgressCard from '@/components/common/atoms/ProgressCard';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { Link, useParams } from 'react-router-dom';
import EduPathUserListDownloadButtons from '@/components/TabContent/EduPath/Coupon/UserList/EduPathUserListDownloadButtons';
import { useEduCampPathApplicantList } from '@/queries/report/eduCampPath/eduCampPathQuery';
import { EduPathApplicantList } from '@/types/eduCamp/path';

const StyledDateString = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const StyledTitleBox = styled.div`
	display: flex;
	align-items: flex-start;
	${mixin.ellipsis(2)}
`;

const StyledTableWrapper = styled.div`
	.coupon-code {
		padding: 12px;
	}

	.ant-table-row {
		& .ant-table-cell {
			border-bottom: none;
		}

		&.border-bottom .ant-table-cell,
		.ant-table-cell[rowspan='3'] {
			border-bottom: 1px solid #f0f0f0;
		}
	}
`;

const roundTooltipScript = '수강생이 신청한 진로부트캠프의 수강 순서입니다.';
const infoTooltipScript = (
	<>
		수료: VOD시청 및 과제 제출을 모두 완료하였습니다.
		<br />
		미수료: VOD와 과제 중 한 가지 이상을 완료하지 않았습니다.
	</>
);

const getDateString = (startDate?: string, endDate?: string) => {
	return `${formatDate(startDate, DATE_FORMAT.FULL_DATE)} ~ ${formatDate(endDate, DATE_FORMAT.FULL_DATE)}`;
};

const EduPathUserList = () => {
	const contractId = useContractId();
	const params = useParams();
	const dispatch = useDispatch();
	const { data, isLoading } = useEduCampPathApplicantList(contractId);
	const userList = data?.data ?? [];

	const getApplicantData = useCallback(data => {
		return data.reduce(
			(
				applicantList: EduPathApplicantList[],
				camps: EduPathApplicantList[] | Record<number, EduPathApplicantList>,
			) => {
				Object.keys(camps).forEach((key: string) => applicantList.push(camps[+key]));
				return applicantList;
			},
			[],
		);
	}, []);
	const applicantList = getApplicantData(userList);

	const tableColumns: ColumnsType<any> = useMemo(
		() => [
			{
				title: '쿠폰 번호',
				dataIndex: 'coupon_code',
				key: 'coupon_codes',
				width: 112,
				className: 'coupon-code',
				align: 'center' as const,
				onCell: (_, index) => {
					if (index === undefined) {
						return {};
					}
					return { rowSpan: index % 3 === 0 ? 3 : 0 };
				},
			},
			{
				title: '이름',
				dataIndex: 'name',
				key: 'user_name',
				width: '80px',
				align: 'center' as const,
				onCell: (_, index) => {
					if (index === undefined) {
						return {};
					}
					return { rowSpan: index % 3 === 0 ? 3 : 0 };
				},
			},
			{
				title: '휴대폰 번호',
				dataIndex: 'mobile',
				key: 'mobile',
				width: '106px',
				align: 'center' as const,
				render: (mobile: string) => <StyledDateString>{getPhoneNumberFormat(mobile)}</StyledDateString>,
				onCell: (_, index) => {
					if (index === undefined) {
						return {};
					}
					return { rowSpan: index % 3 === 0 ? 3 : 0 };
				},
			},
			{
				title: () => (
					<TableHeaderWithInfoCircle
						title={'차수'}
						tooltipContent={roundTooltipScript}
						tooltipPlacement={'bottom'}
					/>
				),
				key: 'round',
				width: '80px',
				align: 'center' as const,
				render: (_, __, index) => {
					return `${(index % 3) + 1}차`;
				},
			},
			{
				title: '캠프',
				dataIndex: 'camp_title',
				key: 'applied_camp',
				width: '240px',
				align: 'left' as const,
				render: (title: string) => (
					<StyledTitleBox>
						<StyledDateString>{title}</StyledDateString>
					</StyledTitleBox>
				),
			},
			{
				title: '기간',
				dataIndex: 'date',
				key: 'date',
				width: '122px',
				align: 'center' as const,
				render: (_: string, record: EduPathApplicantList) => (
					<StyledDateString>{getDateString(record.paid_at, record.expire_at)}</StyledDateString>
				),
			},
			{
				title: 'VOD',
				dataIndex: 'progress',
				key: 'progress',
				width: '100px',
				align: 'center' as const,
				render: (_, record: EduPathApplicantList) => {
					const status = [record.assignment];
					const vodStatus = [record.attendance];
					return (
						<ProgressCard
							progressState={[
								{
									title: 'VOD',
									status: vodStatus,
								},
								{
									title: '과제',
									status,
								},
							]}
							dotPosition={'right'}
						/>
					);
				},
			},
			{
				title: () => (
					<TableHeaderWithInfoCircle
						title={'상태'}
						tooltipContent={infoTooltipScript}
						tooltipPlacement={'bottom'}
					/>
				),
				dataIndex: 'action',
				key: 'action',
				width: '80px',
				align: 'center' as const,
				render: (_: string, record: EduPathApplicantList) =>
					record.assignment && record.attendance ? '수료' : '미수료',
			},
			{
				title: '내역 조회',
				dataIndex: 'user_no',
				key: 'user_no',
				width: 90,
				align: 'center' as const,
				render: (userNo, _, index) => {
					return index % 3 === 0 ? (
						<Link
							to={dynamicRoutePath(ROUTER_PATH.REPORT.EDU_PATH.USER_DETAIL, {
								...params,
								applicantNo: userNo,
							})}
						>
							수강 현황
						</Link>
					) : null;
				},
			},
		],
		[params],
	);

	const getPhoneNumberFormat = (value: string) => {
		return value
			.replace(/[^0-9]/g, '')
			.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, '$1-$2-$3')
			.replace('--', '-');
	};

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('캠프 신청자 현황')));
	}, []);

	return (
		<>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							총 신청자 수 :&nbsp;
							<Text color={theme['blue-7']}>
								{toFixedWithoutTrailingZeroes(applicantList.length / 3, 1)}
							</Text>
							명
						</Text>
						<EduPathUserListDownloadButtons />
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledTableWrapper>
							<StyledBasicTable
								dataSource={applicantList}
								columns={tableColumns}
								loading={isLoading}
								pagination={false}
								isLoading={isLoading}
								rowKey={'no'}
								rowClassName={(_, index) => (index % 3 === 2 ? 'border-bottom' : '')}
							/>
						</StyledTableWrapper>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};
export default EduPathUserList;
