import React from 'react';
import styled from 'styled-components';

const StyledChip = styled.span<ChipStyledProps>`
	border-radius: 4px;
	border: 1px solid ${props => props.color || props.theme['blue-7']};
	color: ${props => props.color || props.theme['blue-7']};
	padding: 1.5px 4px;
	line-height: 12px;
	font-size: 10px;
`;

interface ChipStyledProps {
	color?: string;
}

interface ChipProps extends ChipStyledProps {
	children: React.ReactNode;
}

const Chip = ({ children, color }: ChipProps) => {
	return <StyledChip color={color}>{children}</StyledChip>;
};

export default Chip;
