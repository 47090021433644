import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItemOmitKey } from '@/components/common/organisms/InfoBoxWithTitle/types';

export interface SummaryDataType {
	applicant: SummaryType;
	camp: SummaryType;
}

export interface SummaryType {
	finish: number;
	finish_ratio: number;
	ongoing: number;
	ready: number;
	total: number;
}

const ReportCampPrivateSummarySection = ({
	summaryData,
	isLoading,
}: {
	summaryData: SummaryDataType;
	isLoading: boolean;
}) => {
	const data = [
		{
			title: '총 캠프',
			content: `${summaryData.camp.total}`,
		},
		{
			title: '시작 전 캠프',
			content: `${summaryData.camp.ready}`,
		},
		{
			title: '진행 중 캠프',
			content: `${summaryData.camp.ongoing}`,
		},
		{
			title: '종료 캠프',
			content: `${summaryData.camp.finish}`,
		},
		{
			title: '완료율',
			content: `${summaryData.camp.finish_ratio}%`,
		},
		{
			title: '총 신청자 수',
			content: `${summaryData.applicant.total}`,
		},
		{
			title: '수강 전 인원',
			content: `${summaryData.applicant.ready}`,
		},
		{
			title: '수강 중 인원',
			content: `${summaryData.applicant.ongoing}`,
		},
		{
			title: '수료자 수',
			content: `${summaryData.applicant.finish}`,
		},
		{
			title: '수료율',
			content: `${summaryData.applicant.finish_ratio}%`,
		},
	];

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SUMMARY_SECTION_TITLE}</StyledSectionTitle>
			<StyledInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.FIVE_COLUMN}>
				{data &&
					data.map(({ title, tooltipContent, content }: InfoBoxWithTitleArrayItemOmitKey) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={content}
							key={title}
							isLoading={isLoading}
						/>
					))}
			</StyledInfoBoxContainer>
		</section>
	);
};

export default ReportCampPrivateSummarySection;
