import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import SECTION_TITLE from '@/utils/constants/section-title';
import { useParams } from 'react-router-dom';
import { convertParamsIntoNumber } from '@/utils/params';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItem } from '@/components/common/organisms/InfoBoxWithTitle/types';
import { useMentoringSummaryQuery } from '@/queries/report/mentoring/useMentoringQuery';
import { MentoringSummaryData } from '@/types/mentoring/mentoring';
import { MENTORING_SUMMARY_INITIAL_DATA } from '@/utils/constants/mentoring';

const ReportMentoringSummarySection = () => {
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	const { data, isLoading } = useMentoringSummaryQuery(contractId);
	const {
		coupon: { coupon },
		info,
	} = data?.data.result ?? MENTORING_SUMMARY_INITIAL_DATA;
	const { total_user, active_user, count_qa, count_answer } = info;
	const summaryData: MentoringSummaryData = {
		total_user,
		active_user,
		count_qa,
		count_answer,
		qa_coupon_count: coupon.qa,
		ce_coupon_count: coupon.ce,
		in_coupon_count: coupon.in,
		total_coupon_count: coupon.total,
	};
	const columnList = [
		{
			title: '등록자 수',
			tooltipContent: '코멘토 이용권을 등록한 사람 수 입니다.',
			content: '54,893',
			key: 'total_user',
		},
		{
			title: '사용자 수',
			tooltipContent: '등록자 중 멘토링을 1회 이상 사용한 사람 수로, 카테고리별 중복 사용자가 포함됩니다.',
			content: '2,243',
			key: 'active_user',
		},
		{
			title: '총 질문 횟수',
			tooltipContent: '멘토링 질문을 남긴 사용자의 총 질문 수입니다.',
			content: '69,261',
			key: 'count_qa',
		},
		{
			title: '총 답변 횟수',
			tooltipContent: '사용자의 질문에 남겨진 현직자의 총 답변 횟수입니다.',
			content: '28.6%',
			key: 'count_answer',
		},
		{
			title: 'Q&A 쿠폰 사용',
			content: '54,893',
			key: 'qa_coupon_count',
		},
		{
			title: '자소서 쿠폰 사용',
			content: '2,243',
			key: 'ce_coupon_count',
		},
		{
			title: '면접 쿠폰 사용',
			content: '69,261',
			key: 'in_coupon_count',
		},
		{
			title: '총 쿠폰 사용',
			tooltipContent:
				'사용자가 질문을 할 때 소모한 쿠폰의 수 입니다. Q&A는 질문당 1개, 자기소개서와 면접은 질문 250자당 1개가 카운트 됩니다.',
			content: '28.6%',
			key: 'total_coupon_count',
		},
	];
	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SUMMARY_SECTION_TITLE}</StyledSectionTitle>
			<StyledInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.FOUR_COLUMN}>
				{columnList &&
					columnList.map(({ title, tooltipContent, key }: InfoBoxWithTitleArrayItem) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={summaryData[key]}
							key={title}
							isLoading={isLoading}
						/>
					))}
			</StyledInfoBoxContainer>
		</section>
	);
};

export default ReportMentoringSummarySection;
