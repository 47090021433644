import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';

export interface ILoadingState {
	isLoading: boolean;
}

const initialState: ILoadingState = {
	isLoading: false,
};

const loadingSlice = createSlice({
	name: 'loading',
	initialState,
	reducers: {
		startLoading: state => {
			state.isLoading = true;
		},
		finishLoading: state => {
			state.isLoading = false;
		},
	},
});

export const { startLoading, finishLoading } = loadingSlice.actions;

export const getIsLoading = (state: RootState) => state.loading.isLoading;

export default loadingSlice.reducer;
