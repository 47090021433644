import React from 'react';
import { Dropdown, Button, Menu } from 'antd';
import styled from 'styled-components';
import { flexAlignCenter } from '@/styles/styles';
import { DownOutlined } from '@ant-design/icons';
import { Period } from '@/api/report/content';
import { mixin } from '@/styles/theme';

const StyledInsideButtonContainer = styled.div`
	width: 85px;
	${mixin.justifyContent('space-between')};
	${flexAlignCenter};
`;

const StyledDownOutlined = styled(DownOutlined)`
	width: 12px;
	height: 12px;
`;

export interface propTypes {
	value: IDropDownItem;
	items: itemType[];
	// eslint-disable-next-line no-unused-vars
	onClick: (value: IDropDownItem) => void;
}

type itemType = {
	value: string;
	label: string;
	key: string;
};

export interface IDropDownItem {
	value: Period;
	label: string;
}

// TODO : icon도 props로 받을 수 있을까?
const DropDownWithIcon = ({ value, items, onClick }: propTypes) => {
	const menu = () => (
		<Menu>
			{items.map(item => (
				<Menu.Item key={item.key} onClick={() => onClick(item as IDropDownItem)}>
					{item.label} <StyledDownOutlined />
				</Menu.Item>
			))}
		</Menu>
	);
	return (
		<Dropdown overlay={menu}>
			<Button>
				<StyledInsideButtonContainer>
					{value.label} <StyledDownOutlined />
				</StyledInsideButtonContainer>
			</Button>
		</Dropdown>
	);
};

export default DropDownWithIcon;
