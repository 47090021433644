import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import { store } from '@/store';
import { Provider } from 'react-redux';
import theme from '@/styles/theme';
import GlobalStyle from '@/styles/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import 'antd-css-utilities/utility.min.css';
import { AuthProvider } from '@/contexts/AuthProvider';
import ReactQueryClientProvider from '@/contexts/ReactQueryClientProvider';
import { initSentry } from '@/utils/sentry';

// 실제 API를 테스트하고 싶으면 아래 코드를 y 제거 또는 주석 처리
// import { worker } from './mocks/browser';
// if (process.env.NODE_ENV === 'development') {
// 	worker.start();
// }

if (process.env.NODE_ENV === 'production') {
	initSentry();
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<GlobalStyle />
			<ThemeProvider theme={theme}>
				<ReactQueryClientProvider>
					<AuthProvider>
						<Routes />
					</AuthProvider>
				</ReactQueryClientProvider>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);
