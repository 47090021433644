import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import ReportCampCouponCheckDetailSection from '@/components/report/eduPathCampCoupon/organisms/ReportCampCouponCheckDetailSection';
import ReportEduPathCampCouponGraduate from '@/components/report/eduPathCampCoupon/organisms/ReportEduCampCouponGraduate';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import ReportCampCouponSatisfactionSection from '@/components/report/eduPathCampCoupon/organisms/ReportCampCouponSatisfactionSection';
import useContractId from '@/hooks/useContractId';
import { debounce } from 'lodash';
import { SatisfactionData } from '@/types/eduCamp/campCommon';
import { useEduCampPathSummary } from '@/queries/report/eduCampPath/eduCampPathQuery';
import { SATISFACTION_INITIAL_DATA } from '@/utils/constants/common';
import { EDU_PATH_SUMMARY_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampPath';
import ReportCampCouponSummarySection from '@/components/report/eduPathCampCoupon/organisms/ReportCampCouponSummarySection';

const EduPathCampCouponHome = () => {
	const contractInfo = useSelector(getContractInfo);
	const contractId = useContractId();
	const dispatch = useDispatch();
	const [page, setPage] = useState<number>(1);
	// EduCampPath - 만족도 관련은 0으로 박아둠
	const [satisfactionData] = useState<SatisfactionData>(SATISFACTION_INITIAL_DATA);

	const { data, isLoading } = useEduCampPathSummary(contractId);
	const eduPathSummary = data?.data.coupon ?? EDU_PATH_SUMMARY_INITIAL_DATA.coupon;
	const eduPathApplicant = data?.data.certificatedNumber ?? EDU_PATH_SUMMARY_INITIAL_DATA.certificatedNumber;

	const changePage = useCallback(
		debounce((page: number) => {
			setPage(page);
		}, 3000),
		[],
	);

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportCampCouponSummarySection pathSummary={eduPathSummary} isLoading={isLoading} />
			<ReportEduPathCampCouponGraduate eduPathApplicant={eduPathApplicant} isLoading={isLoading} />
			<ReportCampCouponCheckDetailSection />
			<ReportCampCouponSatisfactionSection
				isLoading={false}
				satisfactionData={satisfactionData}
				page={page}
				changePage={changePage}
			/>
		</DetailSectionContainer>
	);
};

export default EduPathCampCouponHome;
