export const PRODUCTS = {
	CONTENT: '콘텐츠',
	MENTORING: '멘토링',
	AI: 'AI 자기소개서 분석기',
	EDU_CAMP: '직무부트캠프',
	EDU_CAMP_JUMP_UP: '직무부트캠프(재맞고)',
	EDU_CAMP_PRIVATE: '직무부트캠프(개설형)',
	EDU_CAMP_COUPON: '직무부트캠프(쿠폰형)',
	EDU_CAMP_EXTERNSHIP: '익스턴십',
	VOD: 'VOD',
	EDU_PATH: '진로부트캠프',
} as const;
