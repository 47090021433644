import React from 'react';
import { Table, Tag } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { dynamicRoutePath } from '@/utils/constants/paths';
import { useDispatch } from 'react-redux';
import { setPartnerName } from '@/store/partnerSlice';
import { convertParamsIntoNumber } from '@/utils/params';
import { formatDate, DATE_FORMAT } from '@/utils/date';
import { ReportContract, ReportRecord } from '@/types/common';
import { useReportContractQuery } from '@/queries/report/common/useCommonQuery';
import { getProductHomePathFromRecord } from '@/services/record';

const { Column } = Table;

const HeadLine1 = styled.h1`
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	margin-bottom: 24px;
	color: ${props => props.theme['gray-9']};
`;

const StyledTableWrapper = styled.div`
	.ant-table-cell {
		color: ${props => props.theme['gray-9']};
	}
`;

const COLUMN_DATA = [
	{
		title: '계약번호',
		dataIndex: 'id',
		key: 'id',
		width: '120px',
	},
	{
		title: '계약일',
		dataIndex: 'dates',
		width: '180px',
		key: 'dates',
		render: (dates: { startDate: Date; endDate: Date }) => (
			<div>
				{formatDate(dates.startDate, DATE_FORMAT.FULL_DATE)} ~{' '}
				{formatDate(dates.endDate, DATE_FORMAT.FULL_DATE)}
			</div>
		),
	},
	{
		title: '이용 서비스',
		dataIndex: 'allowProduct',
		width: '620px',
		key: 'allowProduct',
		render: (allowProduct: string[]) =>
			allowProduct.map((product, index) => <Tag key={`product-tag-${index}`}>{product}</Tag>),
	},
];

const Report = () => {
	const navigate = useNavigate();
	const params = useParams();
	const partnerId = convertParamsIntoNumber(params.partnerId);
	const dispatch = useDispatch();
	const {
		data: {
			data: { partnerName, contracts },
		},
	} = useReportContractQuery(partnerId);
	dispatch(setPartnerName(partnerName));
	const mappedContracts = contracts.map((contract: ReportContract) => {
		return {
			...contract,
			key: contract.id,
			dates: {
				startDate: contract.startDate,
				endDate: contract.endDate,
			},
		};
	});

	const navigateRecord = (record: ReportRecord) => {
		try {
			const homePath = getProductHomePathFromRecord(record);

			return {
				onClick: () =>
					navigate(
						dynamicRoutePath(homePath, {
							...params,
							contractId: String(record.id),
						}),
					),
			};
		} catch (error) {
			console.error('navigateRecord error', error);
			return {
				onClick: () => alert(`해당 페이지로 이동할 수 없습니다. 계약번호 ${record.id}`),
			};
		}
	};

	return (
		<>
			<HeadLine1>{partnerName} 제휴 보고서</HeadLine1>
			<StyledTableWrapper>
				<Table pagination={false} dataSource={mappedContracts} onRow={record => navigateRecord(record)}>
					{COLUMN_DATA.map((item, index) => (
						<Column
							title={item.title}
							key={`column-${index}`}
							dataIndex={item.dataIndex}
							align={item.dataIndex === 'id' ? 'center' : 'left'}
							width={item.width}
							render={item.render}
						/>
					))}
				</Table>
			</StyledTableWrapper>
		</>
	);
};

export default Report;
