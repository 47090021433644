import { useQuery } from '@tanstack/react-query';
import {
	GetEduPathApplicantListResponse,
	getEduPathAssignments,
	getEduPathV2ApplicantList,
	getEduPathV2Attendance,
	getEduPathV2CouponList,
	getEduPathV2Info,
	PaginationOptions,
} from '@/api/report/eduPath';
import { EduPathV2ApplicantDetail, EduPathV2Attendance, EduPathV2SummaryResult } from '@/types/eduCamp/path';
import { AssignmentDataQueryResult } from '@/types/eduCamp/campCommon';
import { EDU_PATH_V2_REPORT_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampPath';
import { unUsedCouponListDataResult } from '@/types/eduCamp/coupon';

export const useEduCampPathV2Summary = (contractId: number) => {
	return useQuery<EduPathV2SummaryResult>(
		['v2', 'edu', 'path', 'home', contractId],
		() => getEduPathV2Info(contractId),
		{
			initialData: EDU_PATH_V2_REPORT_INITIAL_DATA,
			onError: error => console.error(error),
		},
	);
};
function transformData(data: EduPathV2ApplicantDetail) {
	const { camps, ...rest } = data;
	if (camps === null) {
		return [{ ...rest, camp_title: null, assignment: null, vod: false, path_note: false, status: false }];
	}
	return camps.map((camp: any) => ({
		...camp,
		...rest,
	}));
}

export const useEduCampPathV2CouponList = (contractId: number) => {
	return useQuery<unUsedCouponListDataResult>(
		['v2', 'edu', 'path', 'coupon', 'unUsed', contractId],
		() => getEduPathV2CouponList(contractId),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPathV2ApplicantList = (contractId: number, paginationOptions: PaginationOptions) => {
	return useQuery<
		GetEduPathApplicantListResponse,
		Error,
		{ list: EduPathV2ApplicantDetail[][]; total: number; noteUrl: string | null; expireDate: string }
	>(
		['v2', 'edu', 'path', 'applicant', 'list', contractId, paginationOptions],
		() => getEduPathV2ApplicantList(contractId, paginationOptions),
		{
			select: data => {
				return {
					list: data.data.map(item => transformData(item)),
					total: data.meta.total,
					noteUrl: data.note_url,
					expireDate: data.expire_date,
				};
			},
			onError: error => console.error(error),
		},
	);
};

export const useEduCampPathV2Attendance = (contractId: number, userList?: string) => {
	return useQuery<EduPathV2Attendance[]>(
		['v2', 'edu', 'path', 'list', 'detail', contractId, userList],
		() => getEduPathV2Attendance({ contractId, userList }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPathV2Assignment = (contractId: number, assignmentNo?: string) => {
	return useQuery<AssignmentDataQueryResult>(
		['v2', 'edu', 'path', 'assignment', contractId, assignmentNo],
		() => getEduPathAssignments({ contractId, eduApplicant: assignmentNo }),
		{
			onError: error => console.error(error),
		},
	);
};
