import React, { useEffect } from 'react';
import ReportCampPrivateDetailSummary from '@/components/report/eduPathCampCoupon/organisms/ReportCampCouponDetailSummary';
import ReportCampPrivateDetailAssignment from '@/components/report/eduPathCampCoupon/organisms/ReportCampCouponDetailAssignment';
import { useParams } from 'react-router-dom';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import useContractId from '@/hooks/useContractId';
import { useEduCampPathAttendance } from '@/queries/report/eduCampPath/eduCampPathQuery';
import { EDU_PATH_ATTENDANCE_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampPath';

const EduCampPrivateUserListDetail = () => {
	const { applicantNo } = useParams();
	const contractId = useContractId();
	const dispatch = useDispatch();
	const { data, isLoading } = useEduCampPathAttendance(contractId, applicantNo);
	const applicantData = data?.data ?? EDU_PATH_ATTENDANCE_INITIAL_DATA;

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('수강 현황')));
	}, []);

	return (
		<>
			<ReportCampPrivateDetailSummary applicantData={applicantData} isLoading={isLoading} />
			<ReportCampPrivateDetailAssignment applicantData={applicantData} isLoading={isLoading} />
		</>
	);
};

export default EduCampPrivateUserListDetail;
