import { useQuery } from '@tanstack/react-query';
import {
	fetchVODLogs,
	fetchVODMonthlyUsage,
	fetchVODSatisfaction,
	fetchVODSummary,
	fetchVODUsers,
} from '@/api/report/vod';
import { VODMonthlyUsage, VODSatisfactionSummary, VODSummary, VODUserResponse } from '@/types/vod/vod';
import { VODLogResponse } from '@/components/TabContent/VOD/Log';

export const useVodSummary = (contractId: number) => {
	return useQuery<VODSummary>(['vod', 'summary', contractId], () => fetchVODSummary(contractId), {
		onError: error => console.error(error),
	});
};

export const useVodUsage = (contractId: number) => {
	return useQuery<VODMonthlyUsage[]>(['vod', 'usage', contractId], () => fetchVODMonthlyUsage(contractId), {
		onError: error => console.error(error),
	});
};

export const useVodSatisfaction = (contractId: number) => {
	return useQuery<VODSatisfactionSummary>(
		['vod', 'satisfaction', contractId],
		() => fetchVODSatisfaction(contractId),
		{ onError: error => console.error(error) },
	);
};

export const useVodLog = (contractId: number, page: number) => {
	return useQuery<VODLogResponse>(['vod', 'log', contractId, page], () => fetchVODLogs(contractId, page), {
		onError: error => console.error(error),
	});
};

export const useVodUserList = (contractId: number, page: number) => {
	return useQuery<VODUserResponse>(['vod', 'userList', contractId, page], () => fetchVODUsers(contractId, page), {
		onError: error => console.error(error),
	});
};
