import { css } from 'styled-components';
import { mixin } from '@/styles/theme';

export const flexAlignCenter = css`
	${mixin.flexBox()}
	${mixin.alignItems('center')}
`;

export const flexCenter = css`
	${mixin.flexBox()}
	${mixin.alignItems('center')}
	${mixin.justifyContent('center')}
`;

export const sectionTitleMarginBottom = css`
	margin-bottom: 16px;
`;
