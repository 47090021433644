import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { mixin } from '@/styles/theme';

const HeadLine1 = styled.h1`
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	margin-bottom: 4px;
`;

const SubTitle = styled.h4`
	margin-bottom: 24px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
`;

const ListItem = styled(List.Item)`
	${mixin.flexBox()};
	${mixin.justifyContent('space-between')}
	margin-bottom: 1px;
	background-color: #fff;
`;

const listData: listDataProps[] = [
	{
		title: 'hello',
		id: 3,
		client: '이화여자대학교',
	},
	{
		title: 'hello',
		id: 2,
		client: '이화여자대학교',
	},
	{
		title: 'hello',
		id: 1,
		client: '이화여자대학교',
	},
];

export interface listDataProps {
	title: string;
	id: number;
	client: string;
}

const Community = () => {
	return (
		<>
			<HeadLine1>제휴 대학/기관 커뮤니티</HeadLine1>
			<SubTitle>코멘토와 제휴 중인 다른 대학/기관 담당자와 자유롭게 소통하세요.</SubTitle>
			<List itemLayout="vertical" size="large" dataSource={listData}>
				{listData.map((item, index) => (
					<ListItem key={`list-item-${index}`}>
						{item.id}
						<div style={{ width: '57%' }}>{item.title}</div>
						{item.client}
					</ListItem>
				))}
			</List>
		</>
	);
};

export default Community;
