import React, { useEffect } from 'react';
import { Col, ConfigProvider, Row } from 'antd';
import { numberToLocalString } from '@/utils/number';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { StyledBasicTable } from '@/components/common/styledComponents';
import { StyledUserCountContainer, createColumns } from '@/components/UserList/UserList';
import styled from 'styled-components';
import { downloadCampPrivateListToExcel } from '@/api/report/campPrivate';
import { formatDate } from '@/utils/date';
import useContractId from '@/hooks/useContractId';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { downloadExcelFile } from '@/utils/download';
import usePartnerName from '@/hooks/usePartnerName';
import { PRODUCTS } from '@/utils/constants/products';
import { useEduCampPrivateCampListQuery } from '@/queries/report/eduCampPrivate/useEduCampPrivateQuery';
import { EDU_CAMP_APPLICANT_LIST_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCommon';

const StyledContentTitle = styled(Text)`
	${mixin.ellipsis(2)};
`;

const StyledNavigate = styled.a`
	cursor: pointer;
`;

const StyledDateString = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const EduCampPrivateCampList = () => {
	const contractId = useContractId();
	const dispatch = useDispatch();
	const partnerName = usePartnerName();
	const { data, isLoading } = useEduCampPrivateCampListQuery(contractId);
	const campList = data?.data ?? EDU_CAMP_APPLICANT_LIST_INITIAL_DATA;
	const list = [
		{
			title: '캠프명',
			key: 'title',
			dataIndex: ['edu_camp_group', 'title'],
			width: '24%',
			align: 'left' as const,
			render: (title: string) => <StyledContentTitle>{title}</StyledContentTitle>,
		},
		{
			title: '리드멘토',
			key: 'lead_mentor',
			dataIndex: ['edu_camp_group', 'edu_mentor', 'nickname'],
			width: '19%',
			align: 'center' as const,
		},
		{
			title: '시작일',
			key: 'start_date',
			width: '19%',
			align: 'center' as const,
			render: (startDate: string) => <StyledDateString>{formatDate(startDate)}</StyledDateString>,
		},
		{
			title: '종료일',
			key: 'end_date',
			width: '19%',
			align: 'center' as const,
			render: (endDate: string) => <StyledDateString>{formatDate(endDate)}</StyledDateString>,
		},
		{
			title: '모집 공고',
			key: 'url',
			align: 'center' as const,
			width: '19%',
			render: (item: string) => (
				<>
					{!!item && (
						<StyledNavigate color={theme['link']} href={item} target={'_blank'}>
							조회하기
						</StyledNavigate>
					)}
				</>
			),
		},
	];
	const downloadCampPrivateList = async () => {
		const params = {
			contractNo: contractId,
		};
		const { data } = await downloadCampPrivateListToExcel(params);
		downloadExcelFile(data, `${partnerName}-${PRODUCTS.EDU_CAMP_PRIVATE}-캠프 리스트`);
	};

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('개설 캠프 목록')));
	}, []);

	return (
		<>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							총 캠프 : <Text color={theme['blue-7']}>{numberToLocalString(campList?.count ?? 0)} </Text>{' '}
							개
						</Text>
						<DownloadButton onClick={downloadCampPrivateList} />
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledBasicTable
							dataSource={campList.list}
							columns={createColumns(list)}
							pagination={false}
							loading={isLoading}
							isLoading={isLoading}
							rowKey={'key'}
						/>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};
export default EduCampPrivateCampList;
