export const booleanToOX = (value: boolean) => (value ? 'O' : 'X');
/**
 * 연속된 동일한 숫자를 0으로 대체하는 함수입니다. ex) [1,1,1,2,2,2,2,2,2,3,3,3,3,3,3] ->[1,1,1,2,0,2,0,2,0,3,0,0,3,0,0]
 * @param arr 숫자 배열
 * @returns 변환된 숫자 배열
 */

export const removeConsecutiveDuplicates = (arr: Array<number>) => {
	const result = [];
	for (let i = 0; i < arr.length; ) {
		const current = arr[i];
		result.push(current);
		for (let j = 0; j < current - 1; j++) {
			result.push(0);
		}
		i = i + current;
	}
	return result;
};

export const generateArrayFilledWithLength = (rawData: unknown[][] | undefined): number[] =>
	rawData?.flatMap(data => new Array(data.length).fill(data.length)) || [];

/**
 * 현재 index가 마지막 row인지 체크
 * [ 1, 1, 3, 0, 0, 1, 3, 0, 0, 1, 3, 0, 0, 1 ] 이런식으로 rowSpans가 주어졌을 때
 * index가 0, 1, 5, 9, 13 일 때 true를 반환
 */
export const checkIsLastRowSpan = (index: number, rowSpans: number[]) => {
	// rowSpans가 1이면 뒤에 0이 없으므로 마지막 row임
	if (rowSpans[index] === 1) return true;
	return index <= rowSpans.length - 1 && rowSpans[index] === 0 && rowSpans[index + 1] !== 0;
};
