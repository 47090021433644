import React from 'react';
import { SatisfactionType } from '@/components/common/SatisfactionContent';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';
import styled from 'styled-components';

interface SatisfactionHeaderProps {
	type: SatisfactionType;
	total: number;
}

const StyledText = styled(Text)`
	margin-left: 4px;
`;

const SatisfactionHeader = ({ type, total }: SatisfactionHeaderProps) => {
	const getRespondentCountComponent = (total: number) => (
		<StyledText color={theme['gray-7']}>(응답:{total}명)</StyledText>
	);
	switch (String(type)) {
		case 'readCase':
			return <>Q. 코멘토 상담사례가 고민 해결에 도움이 되나요? {getRespondentCountComponent(total)}</>;
		case 'readAnswer':
			return <>Q. 현직자 멘토링 답변은 고민해결에 도움이 되나요? {getRespondentCountComponent(total)}</>;
		case 'analyticsResult':
			return (
				<>Q. AI 분석 결과는 자기소개서 수정 방향 이해에 도움이 되나요? {getRespondentCountComponent(total)}</>
			);
		default:
			return <></>;
	}
};

export default SatisfactionHeader;
