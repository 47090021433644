import styled from 'styled-components';
import { flexAlignCenter, flexCenter, sectionTitleMarginBottom } from '@/styles/styles';
import { Collapse, Table } from 'antd';
import Text from '@/components/common/atoms/Text';
import { mixin } from '@/styles/theme';

export const StyledInfoBoxContainer = styled.div<{ column: number }>`
	display: grid;
	grid-template-columns: repeat(${props => props.column}, 1fr);
	grid-auto-rows: 1fr;
	grid-gap: 24px;
`;

export const StyledTitleRowContainer = styled.div`
	${mixin.justifyContent('space-between')}
	${sectionTitleMarginBottom}
	${flexAlignCenter}
`;

export const StyledCollapse = styled(Collapse)`
	.ant-collapse-header {
		background-color: ${props => props.theme['gray-1']};
	}
	.ant-collapse-content {
		background-color: ${props => props.theme['gray-2']};
		& > .ant-collapse-content-box {
			padding: 16px 32px;
		}
	}
`;

export const StyledScoreContainer = styled.div`
	flex-direction: column;
	${flexAlignCenter}
`;

export const StyledBasicTable = styled(Table)<{ isLoading?: boolean }>`
	.ant-table-thead .ant-table-cell {
		text-align: center;
		font-weight: 600;
	}
	.ant-table-row .ant-table-cell {
		vertical-align: middle;
		text-align: center;
	}
	// table head 항목 사이에 divider 나오는거 제거
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
		display: none;
	}

	${props =>
		props.isLoading &&
		`	.ant-table-tbody > tr > td {
		border: none;
	}`}
`;

export const StyledTable = styled(Table)`
	.ant-table-thead .ant-table-cell {
		&:first-child {
			background-color: ${props => props.theme['gray-4']};
		}
		&:not(:first-child) {
			text-align: center;
			font-weight: 600;
		}
	}
	.ant-table-row .ant-table-cell {
		&:first-child {
			background-color: ${props => props.theme['gray-2']};
			text-align: right;
			font-weight: 600;
		}
		&:not(:first-child) {
			text-align: center;
		}
	}
	// table head 항목 사이에 divider 나오는거 제거
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
		display: none;
	}
`;

export const StyledCheckDetailTable = styled(Table)`
	tr.ant-table-row {
		cursor: pointer;
	}
	td.ant-table-cell {
		vertical-align: middle;
	}
	// table 가운데 border 제거
	.ant-table.ant-table-bordered
		> .ant-table-container
		> .ant-table-content
		> table
		> tbody
		> tr
		> td:not(:last-child) {
		border-right: none;
	}
`;

export const StyledContentTitle = styled(Text)`
	color: ${props => props.theme['primary']};
	${mixin.ellipsis(2)};
`;

export const StyledFlexCenterContainer = styled.div`
	${flexCenter}
`;

export const StyledCompletionState = styled.p<{ completion: string }>`
	white-space: pre-wrap;
	color: ${props =>
		props.completion === '미수료' || props.completion === '취소'
			? props.theme['volcano-6']
			: props.theme['gray-9']};
`;
