import { removeCookie, setCookie } from '@/utils/cookie';
import { COMENTO_TOKEN } from '@/utils/constants/cookies';

export const authCookieOption = {
	path: '/',
	secure: true,
	maxAge: 60 * 60 * 24,
};

// 유지기간 하루
export const setAuthCookie = (token: string) => {
	setCookie(COMENTO_TOKEN, token, authCookieOption);
};

export const removeAuthCookie = () => {
	removeCookie(COMENTO_TOKEN, authCookieOption);
};
