import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItemOmitKey } from '@/components/common/organisms/InfoBoxWithTitle/types';
import { PathSummary } from '@/types/eduCamp/campCommon';

const ReportCampCouponSummarySection = ({
	pathSummary,
	isLoading,
}: {
	pathSummary: PathSummary;
	isLoading: boolean;
}) => {
	const data = [
		{
			title: '발급된 쿠폰',
			key: 'publishedCoupon',
			content: pathSummary.all,
		},
		{
			title: '사용된 쿠폰',
			key: 'usedCoupon',
			content: pathSummary.use,
			couponData: {
				inProgress: pathSummary.apply,
				finished: pathSummary.completion,
			},
		},
		{
			title: '미사용 쿠폰',
			tooltipContent: '아직 사용되지 않았거나 회수되어 앞으로 사용이 가능한 쿠폰입니다.',
			key: 'reservedCoupon',
			content: pathSummary.remain,
		},
	];

	return (
		<section>
			<StyledSectionTitle>{'요약정보'}</StyledSectionTitle>
			<StyledInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.THREE_COLUMN}>
				{data &&
					data.map(({ title, tooltipContent, content, couponData }: InfoBoxWithTitleArrayItemOmitKey) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={content}
							key={title}
							couponData={couponData}
							isLoading={isLoading}
						/>
					))}
			</StyledInfoBoxContainer>
		</section>
	);
};

export default ReportCampCouponSummarySection;
