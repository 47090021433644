export const EDU_CAMP_JUMP_UP_SUMMARY_INITIAL_DATA = {
	applicant: {
		finish: 0,
		finish_ratio: 0,
		ongoing: 0,
		ready: 0,
		total: 0,
	},
	camp: {
		finish: 0,
		finish_ratio: 0,
		ongoing: 0,
		ready: 0,
		total: 0,
	},
};

export const EDU_CAMP_PRIVATE_SUMMARY_INITIAL_DATA = {
	applicant: {
		finish: 0,
		finish_ratio: 0,
		ongoing: 0,
		ready: 0,
		total: 0,
	},
	camp: {
		finish: 0,
		finish_ratio: 0,
		ongoing: 0,
		ready: 0,
		total: 0,
	},
};

export const EDU_CAMP_SATISFACTION_INITIAL_DATA = {
	all: 0,
	avg: 0,
	grade: { one: 0, two: 0, three: 0, four: 0, five: 0 },
	list: [],
};

export const EDU_CAMP_APPLICANT_LIST_INITIAL_DATA = {
	count: 0,
	list: [],
};

export const EDU_CAMP_APPLICANT_INITIAL_DATA = {
	applicant: { attendances_detail: [], assignments_detail: [] },
	assignment: [],
	attendance: [],
};

export const EDU_CAMP_ASSIGNMENT_INITIAL_DATA = {
	assignment: {
		content: '',
		no: 0,
		name: '',
		created_at: '',
		mentorName: '',
		menteeName: '',
		applicant: {
			name: '',
		},
		assignment_files: [],
		assignment_links: [],
	},
	assignment_replies: [],
};

export const EDU_CAMP_INITIAL_DATA = {
	end_date: '',
	start_date: '',
};

export const EDU_CAMP_DETAIL_INITIAL_DATA = {
	...EDU_CAMP_APPLICANT_INITIAL_DATA,
	camp: EDU_CAMP_INITIAL_DATA,
};
