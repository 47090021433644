const theme = {
	white: '#ffffff',
	'red-2': '#FFCCC7',
	'red-5': '#FF4D4F',
	'volcano-6': '#FA541C',
	'gray-1': '#FFFFFF',
	'gray-2': '#FAFAFA',
	'gray-3': '#F5F5F5',
	'gray-4': '#F0F0F0',
	'gray-6': '#BFBFBF',
	'gray-7': '#8C8C8C',
	'gray-8': '#595959',
	'gray-9': '#262626',
	gray100: '#F4F5F7',
	gray500: '#949BA0',
	gray850: '#202325',
	'blue-1': '#E6F7FF',
	'blue-5': '#6EA7EB',
	'blue-7': '#2A7DE1',
	yellow600: '#FFD700',
	link: '#006BEB',
	success: '#04CD66',
	secondary: '#EE7E41',
	primary: '#2A7DE1',
};

export const mixin = {
	// flex
	flexBox: () => `
		display: -webkit-box !important;
		display: -moz-box !important;
		display: -ms-flexbox !important;
		display: -webkit-flex !important;
		display: flex !important;
  `,
	flexDirection: ($direction = 'row') => `
		-webkit-flex-direction: ${$direction};
		-ms-flex-direction: ${$direction};
		flex-direction: ${$direction};
	}`,
	alignItems: ($value = 'stretch') => `
		-webkit-align-items: ${$value};
		align-items: ${$value};
	}`,
	justifyContent: ($value = 'flex-start') => `
		-webkit-justify-content: ${$value};
		justify-content: ${$value};
	}`,
	ellipsis: ($clamp = 1, $wordBreak = 'keep-all') => `
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: ${$clamp};
		-webkit-box-orient: vertical;
		word-wrap: break-word;
		word-break: ${$wordBreak};
	`,
};

export default theme;
