export const VOD_SUMMARY_INITIAL_DATA = {
	registeredUserCount: 0,
	studyUserCount: 0,
	playedVodCount: 0,
	vodPlayTimeCount: 0,
};

export const VOD_SATISFACTION_INITIAL_DATA = {
	avg: 0,
	satisfaction: [],
};

export const VOD_LOG_INITIAL_DATA = {
	total: 0,
	list: {
		total: 0,
		data: [],
	},
};

export const VOD_USER_LIST_INITIAL_DATA = {
	total: 0,
	data: [],
};

export const defaultSummary = [
	{
		title: '등록자 수',
		content: 0,
	},
	{
		title: '학습자 수',
		content: 0,
	},
	{
		title: '재생 VOD 수',
		content: 0,
	},
	{
		title: '재생 횟수',
		content: 0,
	},
];
