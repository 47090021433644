import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Col, Row, Button, ConfigProvider } from 'antd';
import theme, { mixin } from '@/styles/theme';
import { createColumns, StyledUserCountContainer } from '@/components/UserList/UserList';
import Text from '@/components/common/atoms/Text';
import { numberToLocalString } from '@/utils/number';
import { StyledBasicTable } from '@/components/common/styledComponents';
import styled from 'styled-components';

import { getEduPathCouponDownload, postEduPathCouponRevoke } from '@/api/report/eduPath';

import useContractId from '@/hooks/useContractId';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import usePartnerName from '@/hooks/usePartnerName';
import { downloadExcelFile } from '@/utils/download';
import { useEduCampPathCouponList } from '@/queries/report/eduCampPath/eduCampPathQuery';
import { EDU_COUPON_UNUSED_INITIAL_DATA } from '@/utils/constants/common';
const { confirm } = Modal;

const StyledContentTitle = styled(Text)`
	${mixin.ellipsis(2)};
`;

const StyledAction = styled(Button)``;

const StyledText = styled(Text)`
	color: ${props => props.theme['gray-7']};
	font-weight: 400;
	font-size: 12px;
	line-height: 22px;
`;

const StyledSubText = styled(StyledText)`
	color: ${props => props.theme['gray-4']};
	line-height: 20px;
	margin-left: 12px;
`;

const StyledCaption = styled.p`
	margin-bottom: 16px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-7']};
`;

const EduPathCampCouponCampList = () => {
	const partnerName = usePartnerName();
	const contractId = useContractId();
	const dispatch = useDispatch();
	const { data, isLoading } = useEduCampPathCouponList(contractId);
	const unusedCoupon = data?.data ?? EDU_COUPON_UNUSED_INITIAL_DATA;
	const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
	const list = [
		{
			title: '쿠폰 번호',
			key: 'coupon_code',
			width: '24%',
			align: 'center' as const,
			render: (title: string) => (
				<>{!title.includes('coupon_init_code') && <StyledContentTitle>{title}</StyledContentTitle>}</>
			),
		},
		{
			title: '소유자',
			key: 'user_name',
			width: '19%',
			align: 'center' as const,
		},
		{
			title: '이메일',
			key: 'user_email',
			width: '19%',
			align: 'center' as const,
		},
		{
			title: '휴대폰 번호',
			key: 'user_mobile',
			width: '19%',
			align: 'center' as const,
		},
		{
			title: '쿠폰회수',
			key: 'user_coupon_id',
			align: 'center' as const,
			width: '19%',
			render: (_: string, record: any) => (
				<>
					{record.user_coupon_id && (
						<StyledAction
							color={theme['primary']}
							type={'link'}
							onClick={() => redeemUserCoupon(record.coupon_code, record.user_coupon_id)}
						>
							회수하기
						</StyledAction>
					)}
				</>
			),
		},
	];

	const redeemUserCoupon = (coupon_code: string, user_coupon_id: string | number | null) => {
		// 디자인의 차이로 인해 ok, cancel을 변경합니다
		// ok는 취소시에 사용 되고, cancel은 confirm시에 사용 됩니다.
		if (!user_coupon_id) return;
		confirm({
			title: '사용자의 쿠폰을 회수할까요?',
			icon: <ExclamationCircleOutlined />,
			style: { whiteSpace: 'pre-wrap' },
			content: `회수된 쿠폰은 다른 사용자에게 지급 할 수 있습니다.\n별도 알림이 발송 되지 않으니, 회수전 쿠폰 소유자와\n협의가 필요합니다`,
			okText: '취소',
			okButtonProps: { style: { background: theme['primary'], border: theme['primary'] } },
			cancelText: '회수하기',
			onCancel: async () => {
				try {
					await postEduPathCouponRevoke({ contractId, coupon_code });
					window.location.reload();
				} catch (error) {
					console.error(error);
				}
			},
		});
	};

	const downloadCouponList = async () => {
		setDownloadLoading(true);
		try {
			const { data } = await getEduPathCouponDownload(contractId);
			downloadExcelFile(data, `${partnerName}-미사용 쿠폰 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setDownloadLoading(false);
		}
	};

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('미사용 쿠폰 리스트')));
	}, []);

	return (
		<>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<StyledText>
							미사용 쿠폰 :{' '}
							<Text color={theme['blue-7']}>{numberToLocalString(unusedCoupon.count)} </Text> 개
							<StyledSubText>
								{`(${formatDate(unusedCoupon.expire_date, DATE_FORMAT.FULL_DATE)})`}
							</StyledSubText>
						</StyledText>
						<DownloadButton loading={downloadLoading} onClick={downloadCouponList}>
							미사용 쿠폰 현황 다운로드
						</DownloadButton>
					</StyledUserCountContainer>
				</Col>
			</Row>
			{/*TODO: 임시 적용 - 디자이너 확인 필요*/}
			<StyledCaption>쿠폰이 등록되지 않았거나, 등록은 되었으나 신청 되지 않은 목록이 노출 됩니다.</StyledCaption>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledBasicTable
							dataSource={unusedCoupon.list}
							columns={createColumns(list)}
							pagination={false}
							loading={isLoading}
							rowKey="coupon_code"
							isLoading={isLoading}
						/>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};

export default EduPathCampCouponCampList;
