import React from 'react';
import styled from 'styled-components';
import { Rate, Typography } from 'antd';
import { flexAlignCenter } from '@/styles/styles';
import StyledSkeleton from '@/components/common/atoms/StyledSkeleton';
import { toFixedWithoutTrailingZeroes } from '@/utils/number';

const { Text } = Typography;

export interface PropTypes {
	score: number;
	isLoading: boolean;
	className?: string;
}

const StyledScore = styled(Text)`
	color: ${props => props.theme['gray-9']};
	font-size: 24px;
	margin-bottom: 10px;
	line-height: 32px;
`;
const StyledScoreContainer = styled.div`
	flex-direction: column;
	${flexAlignCenter}
`;

const ScoreWithRateSkeleton = styled(StyledSkeleton)`
	width: 50% !important;
	margin-bottom: 10px;
`;

const StyledRate = styled(Rate)`
	.ant-rate-star:not(:last-child) {
		margin-right: 4.15px;
	}
`;

const ScoreWithRate = ({ score, className, isLoading }: PropTypes) => {
	return (
		<StyledScoreContainer>
			{isLoading ? (
				<ScoreWithRateSkeleton height={'32px'} className={className} />
			) : (
				<StyledScore>{toFixedWithoutTrailingZeroes(score, 1)}</StyledScore>
			)}
			<StyledRate value={score} allowHalf disabled style={{ fontSize: '20px' }} />
		</StyledScoreContainer>
	);
};

export default ScoreWithRate;
