import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import styled from 'styled-components';
import { LinkListType } from '@/types/eduCamp/campCommon';

export const StyledAttachmentLinkSection = styled.article`
	margin-top: 20px;
`;

export const StyledAttachmentSummaryContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledAttachmentSummary = styled.p`
	margin-left: 4px;
	font-size: 12px;
	font-weight: 400;
	color: ${props => props.theme['gray-7']};
	line-height: 18px;
`;

const StyledAttachmentLink = styled.a`
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
	color: ${props => props.theme['link']};
`;

const AttachmentLinkSection = ({ linkList }: { linkList?: LinkListType[] }) => {
	return (
		<StyledAttachmentLinkSection>
			<StyledAttachmentSummaryContainer>
				<LinkOutlined style={{ fontSize: '12px', color: '#8C8C8C' }} />
				<StyledAttachmentSummary>링크</StyledAttachmentSummary>
				<StyledAttachmentSummary>{`(${linkList ? linkList.length : 0}개)`}</StyledAttachmentSummary>
			</StyledAttachmentSummaryContainer>
			{linkList && (
				<>
					<Divider style={{ margin: '12px 0' }} />
					{linkList.map(link => (
						<StyledAttachmentLink href={link.link} target={'_blank'} key={`attachment-link-${link.id}`}>
							{link.link}
						</StyledAttachmentLink>
					))}
				</>
			)}
		</StyledAttachmentLinkSection>
	);
};

export default AttachmentLinkSection;
