import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { convertParamsIntoNumber } from '@/utils/params';
import { useAiMonthlyUsage } from '@/queries/report/AI/useAiReportQuery';
import { USAGE_DEFAULT_VALUE } from '@/utils/constants/common';

const ReportAIMonthlyUsageContext = createContext({
	reportAIMonthlyUsage: { ...USAGE_DEFAULT_VALUE },
	isLoading: true,
});

const ReportAIMonthlyUsageProvider = ({ children }: { children: React.ReactNode }) => {
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	const { data, isLoading } = useAiMonthlyUsage(contractId);
	const reportAIMonthlyUsage = data?.data ?? USAGE_DEFAULT_VALUE;

	return (
		<ReportAIMonthlyUsageContext.Provider value={{ reportAIMonthlyUsage, isLoading }}>
			{children}
		</ReportAIMonthlyUsageContext.Provider>
	);
};

export { ReportAIMonthlyUsageProvider, ReportAIMonthlyUsageContext };
