import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { grey } from '@ant-design/colors';

const { Text } = Typography;

export interface propTypes {
	title: string;
	className?: string;
}

const StyledText = styled(Text)`
	color: ${grey[2]};
	font-size: 14px;
`;

const InfoTitle = ({ title, className }: propTypes) => {
	return <StyledText className={className}>{title}</StyledText>;
};

export default InfoTitle;
