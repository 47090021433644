import React from 'react';
import styled from 'styled-components';
import { Progress } from 'antd';
import theme from '@/styles/theme';
import StyledSkeleton from '@/components/common/atoms/StyledSkeleton';

export interface propTypes {
	className?: string;
	percent: number;
	size?: 'default' | 'small';
	isLoading: boolean;
}

const defaultProps: propTypes = {
	percent: 0,
	size: 'small',
	isLoading: false,
};

const StyledProgress = styled(Progress)`
	.ant-progress-text {
		color: ${props => props.theme['gray-7']};
		line-height: 20px;
	}
`;

const SatisfactionProgressBar = ({ className, percent, size, isLoading }: propTypes) => {
	const renderComponent = () => {
		if (isLoading) {
			return <StyledSkeleton height={'20px'} margin={'2px'} />;
		}
		return <>{`${percent}%`}</>;
	};
	return (
		<StyledProgress
			className={className}
			percent={percent}
			size={size}
			strokeColor={theme['gray-6']}
			trailColor={theme['gray-3']}
			format={() => renderComponent()}
		/>
	);
};

SatisfactionProgressBar.defaultProps = defaultProps;

export default SatisfactionProgressBar;
