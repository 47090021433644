import { ContractInfo } from '@/store/contractSlice';
import dayjs from 'dayjs';

export const getHomeHeaderTitle = (contractInfo: ContractInfo) => {
	return contractInfo.partnerName ? `${contractInfo.partnerName} 제휴 보고서` : '';
};

export const getHomeHeaderSubTitle = (contractInfo: ContractInfo) => {
	if (contractInfo.allowProduct && contractInfo.startDate && contractInfo.endDate) {
		// 진로부트캠프는 day까지 표시되도록
		if (contractInfo.allowProduct.toString().replace(/,/gi, ', ') === '진로부트캠프') {
			return `${contractInfo.allowProduct.toString().replace(/,/gi, ', ')} | ${dayjs(
				contractInfo.startDate,
			).format('YYYY.MM.DD')} ~ ${dayjs(contractInfo.endDate).format('YYYY.MM.DD')}`;
		}
		// ex) 컨텐츠, 멘토링 | 2021.09 ~ 2022.02
		return `${contractInfo.allowProduct.toString().replace(/,/gi, ', ')} | ${dayjs(contractInfo.startDate).format(
			'YYYY.MM',
		)} ~ ${dayjs(contractInfo.endDate).format('YYYY.MM')}`;
	} else return '';
};

export const getListHeaderTitle = () => '상세 이력 조회';

export const getListHeaderSubTitle = (subTitle: string) =>
	`${subTitle} | ${dayjs(new Date()).format('YYYY.MM.DD HH:mm')} 기준`;
