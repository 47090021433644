import React from 'react';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import DataTable from '@/components/common/organisms/DataTable';
import styled from 'styled-components';

export interface Assignment {
	no: number;
	created_at?: string | null;
}

export interface Attendance {
	apply_at: string;
	assignment: boolean;
	attendance: boolean;
	camp_no: number;
	camp_title: string;
	classroom_assignments: Assignment[];
	coupon_code: string;
	expire_at: string;
	feedback: boolean;
	mobile: string;
	name: string;
	no: number;
	paid_at: string;
	play_percent: number;
	user_no: number;
}

const StyledTitle = styled.p`
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: ${props => props.theme['gray-9']};
`;

const StyledSection = styled.section`
	margin-bottom: 60px;
`;

const ReportCampCouponDetailSummary = ({
	applicantData,
	isLoading,
}: {
	applicantData: Attendance[] | null;
	isLoading: boolean;
}) => {
	const getApplicantPlayPercent = (array: Attendance[] | undefined | null, type: string, index: number) => {
		if (!array || array?.length < 3) return '';

		if (type === 'VOD') {
			if (array[index].play_percent >= 100) return `O (100%)`;
			return array[index].play_percent >= 70
				? `O (${array[index].play_percent}%)`
				: `X (${array[index].play_percent}%)`;
		}
		if (type === 'assignment') {
			return array[index].assignment ? 'O' : 'X';
		}
		if (type === 'feedback') {
			return array[index].feedback ? 'O' : 'X';
		}
		return '';
	};

	const data = [
		{
			xAxisTitle: 'VOD 시청',
			'1차': getApplicantPlayPercent(applicantData, 'VOD', 0),
			'2차': getApplicantPlayPercent(applicantData, 'VOD', 1),
			'3차': getApplicantPlayPercent(applicantData, 'VOD', 2),
		},
		{
			xAxisTitle: '과제',
			'1차': getApplicantPlayPercent(applicantData, 'assignment', 0),
			'2차': getApplicantPlayPercent(applicantData, 'assignment', 1),
			'3차': getApplicantPlayPercent(applicantData, 'assignment', 2),
		},
		{
			xAxisTitle: '피드백',
			'1차': getApplicantPlayPercent(applicantData, 'feedback', 0),
			'2차': getApplicantPlayPercent(applicantData, 'feedback', 1),
			'3차': getApplicantPlayPercent(applicantData, 'feedback', 2),
		},
	];
	// 둘 중 하나
	// 모든 아이템에 key가 빠지지 않고 일정하게 들어간다면 노이슈
	const keyList = Object.keys(data[0]);
	return (
		<StyledSection>
			<StyledTitleRowContainer>
				<StyledTitle>요약정보</StyledTitle>
			</StyledTitleRowContainer>
			<DataTable data={data} keyList={keyList} isLoading={isLoading} width={'178px'} />
		</StyledSection>
	);
};

export default ReportCampCouponDetailSummary;
