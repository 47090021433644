import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';
import { getContractInfo } from '@/store/contractSlice';

export const useUpdateHomeHeader = () => {
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo, dispatch]);
};
