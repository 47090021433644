import ReportCampCouponSatisfactionSection from '@/components/report/eduPathCampCoupon/organisms/ReportCampCouponSatisfactionSection';
import { useCallback, useState } from 'react';
import { SatisfactionData } from '@/types/eduCamp/campCommon';
import { SATISFACTION_INITIAL_DATA } from '@/utils/constants/common';
import { debounce } from 'lodash';

const EduPathV2SatisfactionSection = () => {
	const [page, setPage] = useState<number>(1);
	// EduCampPath - 만족도 관련은 0으로 박아둠
	// 진로캠은 만족도 조사가 없어서.
	const [satisfactionData] = useState<SatisfactionData>(SATISFACTION_INITIAL_DATA);

	const changePage = useCallback(
		debounce((page: number) => {
			setPage(page);
		}, 3000),
		[],
	);

	return (
		<ReportCampCouponSatisfactionSection
			isLoading={false}
			satisfactionData={satisfactionData}
			page={page}
			changePage={changePage}
		/>
	);
};

export default EduPathV2SatisfactionSection;
