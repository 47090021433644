import { useQuery } from '@tanstack/react-query';
import {
	AIInsightRequest,
	AILogRequest,
	AIMonthlyUsageRequest,
	AISatisfactionRequest,
	AISummaryRequest,
} from '@/api/report/AI';
import { AI_INSIGHT_INITIAL_DATA } from '@/utils/constants/reportAi';
import { MonthlyUsageResult, ReportSatisfactionResult } from '@/types/common';
import { InsightRequestParams, RegistrantsRequestParams, SatisfactionRequestParams } from '@/api/report/content';
import { AIInsightDataResult, AIReportLogResult, AISummaryDataResult } from '@/types/AI/ai';

export const useAiReportSummary = (contractId: number) => {
	return useQuery<AISummaryDataResult>(['report', 'ai', 'summary', contractId], () => AISummaryRequest(contractId), {
		onError: error => console.error(error),
	});
};

export const useAiMonthlyUsage = (contractId: number) => {
	return useQuery<MonthlyUsageResult>(
		['ai', 'report', 'monthly', contractId],
		() => AIMonthlyUsageRequest(contractId),
		{ onError: error => console.error(error) },
	);
};

export const useAiInsightData = (contractId: number, params: InsightRequestParams) => {
	return useQuery<AIInsightDataResult>(
		['ai', 'report', 'insight', contractId, params.period],
		() => AIInsightRequest(contractId, params),
		{ initialData: AI_INSIGHT_INITIAL_DATA, onError: error => console.error(error) },
	);
};

export const useAiSatisfaction = (contractId: number, params: SatisfactionRequestParams) => {
	return useQuery<ReportSatisfactionResult>(
		['ai', 'report', 'satisfaction', contractId],
		() => AISatisfactionRequest(contractId, params),
		{ onError: error => console.error(error) },
	);
};

export const useAiLogDetail = (contractId: number, params: RegistrantsRequestParams) => {
	return useQuery<AIReportLogResult>(
		['ai', 'report', 'log', contractId, params.startDate, params.endDate, params.page],
		() => AILogRequest(contractId, params),
		{ onError: error => console.error(error) },
	);
};
