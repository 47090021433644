import React, { useEffect } from 'react';
import ReportContentSummarySection from '@/components/report/content/organisms/ReportContentSummarySection';
import ReportContentMonthlyUsageGraphSection from '@/components/report/content/organisms/ReportContentMonthlyUsageGraphSection';
import ReportContentMonthlyUsageTableSection from '@/components/report/content/organisms/ReportContentMonthlyUsageTableSection';
import ReportContentCheckDetailSection from '@/components/report/content/organisms/ReportContentCheckDetailSection';
import ReportContentInsightSection from '@/components/report/content/organisms/ReportContentInsightSection';
import ReportContentSatisfactionSection from '@/components/report/content/organisms/ReportContentSatisfactionSection';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import { ReportContentMonthlyUsageProvider } from '@/contexts/ReportContentMonthlyUsage';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getContractInfo } from '@/store/contractSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';

const ContentHome = () => {
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportContentSummarySection />
			<ReportContentMonthlyUsageProvider>
				<ReportContentMonthlyUsageGraphSection />
				<ReportContentMonthlyUsageTableSection />
			</ReportContentMonthlyUsageProvider>
			<ReportContentCheckDetailSection />
			<ReportContentInsightSection />
			<ReportContentSatisfactionSection />
		</DetailSectionContainer>
	);
};
export default ContentHome;
