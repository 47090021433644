import React from 'react';
import { StyledText } from '@/components/common/styled';
import { TitleProps } from '@/components/common/Typography/Title/types';

const Title = ({ children, className }: TitleProps) => {
	return (
		<div className={className}>
			<StyledText>{children}</StyledText>
		</div>
	);
};

export default Title;
