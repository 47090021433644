import React, { useEffect } from 'react';
import DetailSectionContainer from '@/components/common/atoms/DetailSectionContainer';
import ReportVODSummarySection from '@/components/report/vod/organisms/ReportVODSummarySection';
import ReportVODMonthlyUsageTableSection from '@/components/report/vod/organisms/ReportVODMonthlyUsageTableSection';
import ReportVODCheckDetailSection from '@/components/report/vod/organisms/ReportVODCheckDetailSection';
import ReportVODSatisfactionSection from '@/components/report/vod/organisms/ReportVODSatisfactionSection';
import { useDispatch, useSelector } from 'react-redux';
import { getContractInfo } from '@/store/contractSlice';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getHomeHeaderSubTitle, getHomeHeaderTitle } from '@/utils/header';

const VODHome = () => {
	const contractInfo = useSelector(getContractInfo);
	const dispatch = useDispatch();

	useEffect(() => {
		if (contractInfo) {
			dispatch(setHeaderTitle(getHomeHeaderTitle(contractInfo)));
			dispatch(setHeaderSubTitle(getHomeHeaderSubTitle(contractInfo)));
		}
	}, [contractInfo]);

	return (
		<DetailSectionContainer>
			<ReportVODSummarySection />
			<ReportVODMonthlyUsageTableSection />
			<ReportVODCheckDetailSection />
			<ReportVODSatisfactionSection />
		</DetailSectionContainer>
	);
};

export default VODHome;
