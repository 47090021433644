import React from 'react';
import ScoreWithRate from '@/components/common/molecules/ScoreWithRate';
import { StyledCollapse, StyledScoreContainer } from '@/components/common/styledComponents';
import RateWithProgressBar from '@/components/common/organisms/RateWithProgressBar';
import { Collapse } from 'antd';
import styled from 'styled-components';
const { Panel } = Collapse;
import Text from '@/components/common/atoms/Text';
import { mixin } from '@/styles/theme';
import SatisfactionHeader from '@/components/common/SatisfactionHeader';

const StyledScoreContainerWithMargin = styled(StyledScoreContainer)`
	margin-right: 32px;
`;

const StyledSatisfactionEmptyContainer = styled.div`
	${mixin.flexBox()}
	${mixin.flexDirection('column')}
`;

const StyledSatisfactionTitleWrapper = styled.div`
	margin-bottom: 4px;
`;

export const defaultSatisfactionData = [
	{
		value: 5,
		percent: 0,
	},
	{
		value: 4,
		percent: 0,
	},
	{
		value: 3,
		percent: 0,
	},
	{
		value: 2,
		percent: 0,
	},
	{
		value: 1,
		percent: 0,
	},
];
export interface SatisfactionData {
	value: number;
	percent: number;
}

interface SatisfactionContentProps {
	total: number;
	score: number;
	satisfactionData: SatisfactionData[];
	satisfactionType: SatisfactionType;
	isLoading: boolean;
}

// readCase: 콘텐츠
// readAnswer: 멘토링
// analyticsResult: AI
// vodList: VOD
export type SatisfactionType = 'readCase' | 'readAnswer' | 'analyticsResult' | 'vodList';

const SatisfactionContent = ({
	total,
	score,
	satisfactionData,
	satisfactionType,
	isLoading,
}: SatisfactionContentProps) => {
	/* 빈 데이터
	[{value: 5,percent: 0,},{value: 4,percent: 0,},{value: 3,percent: 0,},{value: 2,percent: 0,},{value: 1,percent: 0,}]
	*/
	const isEmpty = satisfactionData.every(item => item.percent === 0);

	return (
		<StyledCollapse defaultActiveKey={['1']}>
			<Panel key="1" header={<SatisfactionHeader type={satisfactionType} total={total} />}>
				<div className="d-flex align-center">
					{isEmpty && !isLoading ? (
						<StyledSatisfactionEmptyContainer>
							<StyledSatisfactionTitleWrapper>
								<Text fontSize="14px" fontWeight={600}>
									응답자가 없습니다.
								</Text>
							</StyledSatisfactionTitleWrapper>
							<Text fontSize="12px">데이터가 수집될 때까지 기다려주세요.</Text>
						</StyledSatisfactionEmptyContainer>
					) : (
						<>
							<StyledScoreContainerWithMargin>
								<ScoreWithRate score={score} isLoading={isLoading} />
							</StyledScoreContainerWithMargin>
							<StyledScoreContainer>
								{satisfactionData &&
									satisfactionData.map((item: SatisfactionData, index: number) => (
										<RateWithProgressBar
											isLoading={isLoading}
											value={item.value}
											percent={item.percent}
											key={`rate-with-progress-bar-${index}`}
										/>
									))}
							</StyledScoreContainer>
						</>
					)}
				</div>
			</Panel>
		</StyledCollapse>
	);
};

export default SatisfactionContent;
