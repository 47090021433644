import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import Logo from '@/components/common/molecules/Logo';

export const HomeHeader = () => {
	return (
		<>
			<Logo />
			<HeaderMenu theme="dark" mode="horizontal" />
		</>
	);
};

export default HomeHeader;

const HeaderMenu = styled(Menu)`
	width: 80%;
`;
