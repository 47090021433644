import React from 'react';
import styled from 'styled-components';
import ProfileSection from '@/components/common/atoms/ProfileSection';
import AttachmentLinkSection from '@/components/common/atoms/AttachmentLinkSection';
import AttachmentFileSection from '@/components/common/atoms/AttachmentFileSection';
import AssignmentContentSection from '@/components/common/atoms/AssignmentContentSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { useLocation } from 'react-router-dom';
import { AssignmentData } from '@/types/eduCamp/campCommon';

const StyledAssignmentCardSection = styled.section`
	padding: 20px;
	border: 1px solid ${props => props.theme['gray-4']};
	margin: 24px 0;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
`;

const StyledAttachmentContainer = styled.div<{ noItem: boolean }>`
	margin-top: ${props => (props.noItem ? '0' : '4px')};
`;

const ReportCampPrivateAssignmentCard = ({
	reply,
	isAssignment = true,
	assignment,
}: {
	reply: boolean;
	isAssignment: boolean;
	assignment: AssignmentData;
}) => {
	const getApplicantName = () => {
		if (isAssignment) return assignment.applicant.name;
		return assignment.mentorName ? `${assignment.mentorName} 멘토` : assignment.menteeName;
	};
	const getNoItem = (assignment: AssignmentData) => {
		if (!assignment) return { hasLink: false, hasFile: false, noItem: false };
		const hasLink = !!assignment.assignment_links?.length;
		const hasFile = isAssignment ? !!assignment.assignment_files.length : !!assignment.reply?.length;
		const noItem = !hasLink && !hasFile;
		return { hasLink, hasFile, noItem };
	};
	const isEduPathType = useLocation().pathname.includes('edu-path');
	return (
		<StyledAssignmentCardSection>
			<ProfileSection
				name={getApplicantName()}
				isMentor={!!assignment.mentorName}
				createdAt={formatDate(assignment.created_at, DATE_FORMAT.FULL_DATE)}
			/>
			<AssignmentContentSection content={assignment.content} />
			<StyledAttachmentContainer noItem={getNoItem(assignment).noItem}>
				{getNoItem(assignment).hasLink && <AttachmentLinkSection linkList={assignment.assignment_links} />}
				{getNoItem(assignment).hasFile && (
					<AttachmentFileSection
						reply={reply}
						fileList={isAssignment ? assignment.assignment_files : assignment.reply}
						applicant={isEduPathType ? assignment : assignment.applicant}
					/>
				)}
			</StyledAttachmentContainer>
		</StyledAssignmentCardSection>
	);
};

export default ReportCampPrivateAssignmentCard;
