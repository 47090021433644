import React, { ReactChild, ReactChildren } from 'react';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

interface childrenTypes {
	children: ReactChild | ReactChildren;
}

const ContentLayout = ({ children }: childrenTypes) => {
	return (
		<StyledContent>
			<ContentWrapper>{children}</ContentWrapper>
		</StyledContent>
	);
};

export default ContentLayout;

const ContentWrapper = styled.div`
	padding: 40px 0;
	min-height: 360px;
	background-color: ${props => props.theme.white};
`;

const StyledContent = styled(Content)`
	margin: 88px 24px 88px 24px;
`;
