import React from 'react';
import ReportInfoSection from '@/components/common/organisms/ReportInfoSection';
import useContractId from '@/hooks/useContractId';
import { useEduCampPathV2SummaryData } from '@/hooks/useEduCampPathV2Data';

const EduPathV2CampCouponSummarySection = () => {
	const contractId = useContractId();
	const { eduPathSummary, isFetching } = useEduCampPathV2SummaryData(contractId);

	const data = [
		{
			title: '발급된 쿠폰',
			key: 'publishedCoupon',
			content: eduPathSummary.all,
		},
		{
			title: '사용된 쿠폰',
			key: 'usedCoupon',
			content: eduPathSummary.use,
			couponData: {
				inProgress: eduPathSummary.apply,
				finished: eduPathSummary.completion,
			},
		},
		{
			title: '미사용 쿠폰',
			tooltipContent: '아직 사용되지 않았거나 회수되어 앞으로 사용이 가능한 쿠폰입니다.',
			key: 'reservedCoupon',
			content: eduPathSummary.remain,
		},
	];

	return <ReportInfoSection title={'요약 정보'} data={data} isLoading={isFetching} />;
};

export default EduPathV2CampCouponSummarySection;
