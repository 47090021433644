import React from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';

interface StyleProps {
	height: string;
	margin?: string;
}

const StyledSkeletonComponent = styled(Skeleton)<StyleProps>`
	.ant-skeleton-title {
		height: ${props => props.height};
		margin-top: ${props => props.margin};
	}
`;

const StyledSkeleton = ({
	height,
	margin = '0',
	className,
}: {
	height: string;
	margin?: string;
	className?: string;
}) => {
	return <StyledSkeletonComponent height={height} margin={margin} active paragraph={false} className={className} />;
};

export default StyledSkeleton;
