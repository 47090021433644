import React from 'react';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';
import { formatDate } from '@/utils/date';

// export interface propTypes {};
interface DateCaptionProps {
	date: string;
}
const DateCaption = ({ date }: DateCaptionProps) => {
	return <Text color={theme['gray-6']}>{formatDate(date, 'YYYY. MM. DD')}</Text>;
};

export default DateCaption;
