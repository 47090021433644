import { EduPathV2SummaryResult } from '@/types/eduCamp/path';

export const EDU_PATH_SUMMARY_INITIAL_DATA = {
	coupon: {
		all: 0,
		apply: 0,
		completion: 0,
		remain: 0,
		use: 0,
	},
	certificated: {
		assignment_only: 0,
		all: 0,
	},
	certificatedNumber: [0, 0, 0, 0],
};

export const EDU_PATH_V2_REPORT_INITIAL_DATA: EduPathV2SummaryResult = {
	coupon: {
		all: 0,
		apply: 0,
		completion: 0,
		remain: 0,
		use: 0,
	},
	graduates: {
		vod_users_count: 0,
		applicants_assignment_count: 0,
		completed_count: 0,
	},
};

export const EDU_PATH_ATTENDANCE_INITIAL_DATA = [
	{
		apply_at: '',
		assignment: false,
		attendance: false,
		camp_no: 0,
		camp_title: '',
		classroom_assignments: [],
		coupon_code: '',
		expire_at: '',
		feedback: false,
		mobile: '',
		name: '',
		no: 0,
		paid_at: '',
		play_percent: 0,
		user_no: 0,
	},
];

export const EDU_PATH_ASSIGNMENT_INITIAL_DATA = {
	assignment: {
		content: '',
		no: 0,
		name: '',
		created_at: '',
		mentorName: '',
		menteeName: '',
		applicant: {
			name: '',
		},
		assignment_files: [],
		assignment_links: [],
	},
	assignment_replies: [],
};
