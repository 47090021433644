import { logoutRequest } from '@/api/common';
import { COMENTO_TOKEN } from '@/utils/constants/cookies';
import { authCookieOption } from '@/utils/auth';
import { ROUTER_PATH } from '@/utils/constants/paths';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useContext } from 'react';
import { AuthContext } from '@/contexts/AuthProvider';

const useLogout = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [, , removeCookie] = useCookies([COMENTO_TOKEN]);
	const { isLogin } = useContext(AuthContext);
	const userId = searchParams.get('userId');

	const logout = async () => {
		try {
			if (isLogin) {
				await logoutRequest();
			}
		} catch (error) {
			console.error(error);
		} finally {
			removeCookie(COMENTO_TOKEN, authCookieOption);
			navigate(ROUTER_PATH.LOGIN, { state: { userId } });
		}
	};

	return logout;
};

export default useLogout;
