import { useQuery } from '@tanstack/react-query';
import {
	mentoringDataByCategoryRequest,
	mentoringInsightRequest,
	mentoringLogDetailRequest,
	mentoringLogRequest,
	mentoringMonthlyUsageRequest,
	mentoringSatisfactionRequest,
	mentoringSummaryRequest,
} from '@/api/report/mentoring';
import {
	DataByCategoryItemResult,
	MentoringChartResult,
	MentoringDetailQueryResult,
	MentoringLogDataResult,
	MentoringMonthlyUsageResult,
	MentoringSatisfaction,
	MentoringSummaryResultData,
} from '@/types/mentoring/mentoring';
import { MENTORING_CHART_INITIAL_DATA } from '@/utils/constants/mentoring';
import { InsightRequestParams, RegistrantsRequestParams, SatisfactionRequestParams } from '@/api/report/content';

export const useMentoringSummaryQuery = (contractId: number) => {
	return useQuery<MentoringSummaryResultData>(['mentoring', 'summary', contractId], () =>
		mentoringSummaryRequest(contractId),
	);
};

export const useMentoringSituationQuery = (contractId: number) => {
	return useQuery<DataByCategoryItemResult>(['mentoring', 'situation', contractId], () =>
		mentoringDataByCategoryRequest(contractId),
	);
};

export const useMentoringGraphDataQuery = (contractId: number) => {
	return useQuery<MentoringMonthlyUsageResult>(['mentoring', 'monthly', contractId], () =>
		mentoringMonthlyUsageRequest(contractId),
	);
};

// insight는 initial data가 있는게 로딩중이란걸 보여주기 쉬운 이유로 유지
export const useMentoringInsightDataQuery = (contractId: number, params: InsightRequestParams) => {
	return useQuery<MentoringChartResult>(
		['mentoring', 'insight', contractId, params.period],
		() => mentoringInsightRequest(contractId, params),
		{ initialData: MENTORING_CHART_INITIAL_DATA },
	);
};

export const useMentoringSatisfaction = (contractId: number, params: SatisfactionRequestParams) => {
	return useQuery<MentoringSatisfaction>(['mentoring', 'satisfaction', contractId], () =>
		mentoringSatisfactionRequest(contractId, params),
	);
};

export const useMentoringLogQuery = (contractId: number, params: RegistrantsRequestParams) => {
	return useQuery<MentoringLogDataResult>(
		['mentoring', 'log', contractId, params?.startDate, params?.endDate, params?.page],
		() => mentoringLogRequest(contractId, params),
	);
};

export const useMentoringDetailQuery = (contractId: number, requestNo: number) => {
	return useQuery<MentoringDetailQueryResult>(['mentoring', 'log', 'detail', contractId, requestNo], () =>
		mentoringLogDetailRequest(contractId, requestNo),
	);
};
