import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderTitle, getListHeaderSubTitle } from '@/utils/header';
import { Col, ConfigProvider, DatePicker, Row } from 'antd';
import { createColumns, StyledDatePickerContainer, StyledUserCountContainer } from '@/components/UserList/UserList';
import ResetButton from '@/components/common/molecules/ResetButton';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { numberToLocalString } from '@/utils/number';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import { StyledBasicTable } from '@/components/common/styledComponents';
import useContractId from '@/hooks/useContractId';
import useRangeInput, { emptyRangeDate } from '@/hooks/useRangeInput';
import { animateScroll } from 'react-scroll';
import { AILogExcelRequest } from '@/api/report/AI';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { downloadExcelFile } from '@/utils/download';
import usePartnerName from '@/hooks/usePartnerName';
import { defaultTablePaginationConfig } from '@/utils/constants/table';
import { PRODUCTS } from '@/utils/constants/products';
import styled from 'styled-components';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { useAiLogDetail } from '@/queries/report/AI/useAiReportQuery';
import { REPORT_LOG_INITIAL } from '@/utils/constants/common';
const { RangePicker } = DatePicker;

const StyledContentTitle = styled(Text)`
	${mixin.ellipsis(2)};
`;

const AILog = () => {
	const contractId = useContractId();
	const partnerName = usePartnerName();
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [isLoadingDownload, setIsLoadingDownload] = useState(false);
	const { rangeDate, rangeDateString, onChange, resetRangeDate, isResetButtonDisabled } =
		useRangeInput(emptyRangeDate);
	const { data, isLoading } = useAiLogDetail(contractId, {
		...(rangeDateString[0] && { startDate: rangeDateString[0] }),
		...(rangeDateString[1] && { endDate: rangeDateString[1] }),
		page,
	});
	const { data: registrants, total: totalRegistrant } = data?.data ?? REPORT_LOG_INITIAL;

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('AI 분석기 이용 현황')));
	}, []);

	useEffect(() => {
		animateScroll.scrollToTop({
			delay: 100,
			duration: 100,
			smooth: true,
		});
	}, [contractId, rangeDateString, page]);

	const handleClickExcelDownloadButton = async () => {
		try {
			setIsLoadingDownload(true);
			const { data } = await AILogExcelRequest(contractId, {
				...(rangeDateString[0] && { startDate: rangeDateString[0] }),
				...(rangeDateString[1] && { endDate: rangeDateString[1] }),
			});
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.AI}-이용 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoadingDownload(false);
		}
	};

	const list = [
		{
			title: '분석일',
			key: 'AnalysisDate',
			width: '143px',
			render: (AnalysisDate: string) => <>{formatDate(AnalysisDate, DATE_FORMAT.FULL_DATE)}</>,
		},
		{
			title: '분석번호',
			key: 'AnalysisNumber',
			width: '143px',
			render: (AnalysisNumber: string) => <>{Number(AnalysisNumber) > 0 && <>{AnalysisNumber}</>}</>,
		},
		{
			title: '학번/ID',
			key: 'studentNumber',
			width: '143px',
		},
		{
			title: '전공',
			key: 'major',
			width: '100px',
		},
		{
			title: '이름',
			key: 'name',
			width: '100px',
		},
		{
			title: '분석직무',
			key: 'AnalysisJob',
			align: 'left' as const,
			width: '100px',
		},
		{
			title: '분석한 글',
			width: '240px',
			key: 'AnalysisContent',
			align: 'left' as const,
			render: (content_title: string) => <StyledContentTitle>{content_title}</StyledContentTitle>,
		},
		{
			title: '분석결과',
			tooltipContent: 'AI 자기소개서 분석 결과 해당 자소서에 나타난 상위 3가지 강점역량',
			width: '143px',
			key: 'result',
		},
	];

	const onTableChange = (pagination: TablePaginationConfig) => {
		// pagination이 없을 수 있다.
		setPage(pagination?.current || 1);
	};

	return (
		<>
			<Row>
				<StyledDatePickerContainer>
					<RangePicker
						value={rangeDate}
						onChange={onChange}
						allowClear={false}
						placeholder={['시작 기간', '종료 기간']}
					/>
					<ResetButton onClick={resetRangeDate} disabled={isResetButtonDisabled} />
				</StyledDatePickerContainer>
			</Row>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							총 분석 횟수 : <Text color={theme['blue-7']}>{numberToLocalString(totalRegistrant)} </Text>{' '}
							건
						</Text>
						<DownloadButton loading={isLoadingDownload} onClick={handleClickExcelDownloadButton} />
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledBasicTable
							dataSource={registrants}
							columns={createColumns(list)}
							pagination={{ ...defaultTablePaginationConfig, total: totalRegistrant }}
							onChange={onTableChange}
							loading={isLoading}
							isLoading={isLoading}
							rowKey="AnalysisNumber"
						/>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};

export default AILog;
