import { useQuery } from '@tanstack/react-query';
import {
	PrivateCampListDataQueryResult,
	PrivateCampListDetailQueryResult,
	PrivateCampSummaryQueryResult,
	PrivateCampUserListDataQueryResult,
} from '@/types/eduCamp/private';
import {
	getPrivateApplicantList,
	getPrivateAttendanceAndAssignment,
	getPrivateCampAssignment,
	getPrivateCampList,
	getPrivateCampSchedule,
	getPrivateSatisfactionRequest,
	getPrivateSummarySectionRequest,
} from '@/api/report/campPrivate';
import {
	AssignmentDataQueryResult,
	SatisfactionQueryResult,
	ScheduleDataQueryResult,
} from '@/types/eduCamp/campCommon';

export const useEduCampPrivateSummaryQuery = (contractId: number) => {
	return useQuery<PrivateCampSummaryQueryResult>(
		['edu', 'camp', 'private', 'summary', contractId],
		() => getPrivateSummarySectionRequest({ contractNo: contractId }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPrivateSatisfactionQuery = (contractId: number, page: number) => {
	return useQuery<SatisfactionQueryResult>(
		['edu', 'camp', 'private', 'satisfaction', contractId],
		() => getPrivateSatisfactionRequest({ contractNo: contractId, page }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPrivateUserListQuery = (contractId: number) => {
	return useQuery<PrivateCampUserListDataQueryResult>(
		['edu', 'camp', 'private', 'user', contractId],
		() => getPrivateApplicantList({ contractNo: contractId }),
		{
			onError: error => console.error(error),
			select: response => {
				response = {
					...response,
					data: {
						...response.data,
						list: response.data.list.map(data => {
							return {
								...data,
								state_detail: data.state_detail_custom ? data.state_detail_custom : data.state_detail,
							};
						}),
					},
				};
				return response;
			},
		},
	);
};

export const useEduCampPrivateCampListQuery = (contractId: number) => {
	return useQuery<PrivateCampListDataQueryResult>(
		['edu', 'camp', 'private', 'list', contractId],
		() => getPrivateCampList({ contractNo: contractId }),
		{
			onError: error => console.error(error),
		},
	);
};

export const useEduCampPrivateListDetailQuery = (applicantNo: number) => {
	return useQuery<PrivateCampListDetailQueryResult>(
		['edu', 'camp', 'private', 'detail', 'applicant', applicantNo],
		() => getPrivateAttendanceAndAssignment({ applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPrivateListScheduleQuery = (applicantNo: number) => {
	return useQuery<ScheduleDataQueryResult>(
		['edu', 'camp', 'private', 'detail', 'schedule', applicantNo],
		() => getPrivateCampSchedule({ applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampPrivateAssignmentQuery = (assignmentNo: number, applicantNo: number) => {
	return useQuery<AssignmentDataQueryResult>(
		['edu', 'camp', 'private', 'assignment', assignmentNo, applicantNo],
		() => getPrivateCampAssignment({ assignment_no: assignmentNo, applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};
