import dayjs from 'dayjs';

export const DATE_FORMAT = {
	YEAR_MONTH: 'YYYY.MM',
	FULL_DATE: 'YYYY.MM.DD',
	DATE_WITH_TIME: 'YYYY.MM.DD HH:mm',
} as const;

export const formatDate = (date?: Date | string | null, format: string = DATE_FORMAT.FULL_DATE) => {
	if (!date) return '';
	return dayjs(date).format(format);
};

export const getIsFreeSchedule = (startDate?: string, endDate?: string) => {
	if (!startDate || !endDate) return;
	return dayjs(startDate).year() === 9999 || dayjs(endDate).year() === 9999;
};

export const getWeekText = (week: number) => {
	if (week === 0) return '시작하기';
	return `${week}주차`;
};
