import React from 'react';
import SECTION_TITLE from '@/utils/constants/section-title';
import { useParams } from 'react-router-dom';
import { convertParamsIntoNumber } from '@/utils/params';
import SatisfactionContent from '@/components/common/SatisfactionContent';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import { useMentoringSatisfaction } from '@/queries/report/mentoring/useMentoringQuery';
import { MENTORING_SATISFACTION_INITIAL_DATA } from '@/utils/constants/mentoring';

const ReportMentoringSatisfactionSection = () => {
	const params = useParams();
	const contractId = convertParamsIntoNumber(params.contractId);
	const satisfactionType = 'readAnswer' as const;
	const { data, isLoading } = useMentoringSatisfaction(contractId, { type: satisfactionType });
	const { avg, total, satisfaction } = data?.data.result.satisfaction.values ?? MENTORING_SATISFACTION_INITIAL_DATA;

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SATISFACTION_SECTION_TITLE}</StyledSectionTitle>
			<SatisfactionContent
				isLoading={isLoading}
				total={total}
				score={avg}
				satisfactionData={satisfaction}
				satisfactionType={satisfactionType}
			/>
		</section>
	);
};

export default ReportMentoringSatisfactionSection;
