import React from 'react';
import { useParams } from 'react-router-dom';
import CheckDetailSection from '@/components/common/organisms/CheckDetailSection/CheckDetailSection';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';

const ReportCampJumpUpCheckDetailSection = () => {
	const params = useParams();
	const dataSource = [
		{
			key: '1',
			title: '개설 캠프 목록',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.EDU_CAMP_JUMP_UP.CAMP_LIST, params),
		},
		{
			key: '2',
			title: '캠프 신청자 현황',
			to: dynamicRoutePath(ROUTER_PATH.REPORT.EDU_CAMP_JUMP_UP.USER_LIST, params),
		},
	];
	return <CheckDetailSection title={'상세 이력 조회'} dataSource={dataSource} />;
};

export default ReportCampJumpUpCheckDetailSection;
