import React from 'react';
import { Bar } from '@ant-design/charts';
import { blue } from '@ant-design/colors';
import styled from 'styled-components';
import EmptyWithOverlay from '@/components/common/EmptyWithOverlay';
import SkeletonBarChart from '@/components/common/HorizontalBarChart/SkeletonBarChart';

export interface propTypes {
	data: IChartItem[];
	isLoading?: boolean;
}

export interface IChartItem {
	label: string;
	value: number;
}

const StyledHorizontalBarChartContainer = styled.div`
	position: relative;
`;

// TODO : 오른쪽에 항목 떠다니는거 없애기
// TODO : 밑에 가로축 value 없애기
const HorizontalBarChart = ({ data, isLoading }: propTypes) => {
	// 예시 blue : ['0':'#aaaaaa','1':'#bbbbbb','primary':'#cccccc']
	const isEmpty = data.length === 0;
	const config = {
		data,
		xField: 'value',
		yField: 'label',
		// blue가 object 배열이므로 값만 추출
		color: Object.values(blue).reverse(),
		tooltip: {
			// Record<string, any>가 이미 정의된 타입.
			formatter: (data: Record<string, any>) => {
				return { name: '비율', value: `${data.value}%` };
			},
		},
		label: {
			position: 'left' as const,
			style: {
				fill: '#FFFFFF',
			},
		},
	};
	return (
		<StyledHorizontalBarChartContainer>
			{isLoading ? (
				<SkeletonBarChart />
			) : (
				<EmptyWithOverlay isEmpty={isEmpty}>
					<Bar {...config} />
				</EmptyWithOverlay>
			)}
		</StyledHorizontalBarChartContainer>
	);
};

export default HorizontalBarChart;
