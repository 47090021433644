import React from 'react';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';
import { CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledChosenBadgeContainer = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	background-color: ${props => props.theme['blue-1']};
	border-radius: 0 0 0 2px;
	padding: 4px 4px 4px 6px;
`;

const ChosenBadge = () => {
	return (
		<StyledChosenBadgeContainer>
			<Text color={theme['blue-7']} fontSize="12px">
				채택
			</Text>
			<CheckOutlined style={{ color: theme['blue-7'], marginLeft: '2px', fontSize: '12px' }} />
		</StyledChosenBadgeContainer>
	);
};

export default ChosenBadge;
