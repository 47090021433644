import React from 'react';
import { StyledSectionTitle } from '@/components/common/Typography/Title/styled';
import InfoBoxWithTitle from '@/components/common/organisms/InfoBoxWithTitle';
import { StyledInfoBoxContainer } from '@/components/common/styledComponents';
import SECTION_TITLE from '@/utils/constants/section-title';
import useContractId from '@/hooks/useContractId';
import styled from 'styled-components';
import { mixin } from '@/styles/theme';
import { MAXIMUM_ITEM_COLUMN } from '@/utils/constants/table';
import { InfoBoxWithTitleArrayItemOmitKey } from '@/components/common/organisms/InfoBoxWithTitle/types';
import { useVodSummary } from '@/queries/report/vod/useVodQuery';
import { defaultSummary, VOD_SUMMARY_INITIAL_DATA } from '@/utils/constants/vod';

const StyledVODInfoBoxContainer = styled(StyledInfoBoxContainer)`
	${mixin.justifyContent('space-between')}
	flex-wrap: nowrap;
`;

const ReportVODSummarySection = () => {
	const contractId = useContractId();
	const { data, isLoading } = useVodSummary(contractId);
	const { registeredUserCount, studyUserCount, playedVodCount, vodPlayTimeCount } = data ?? VOD_SUMMARY_INITIAL_DATA;
	const summaryData = [registeredUserCount, studyUserCount, playedVodCount, vodPlayTimeCount];
	const summary = defaultSummary.map((item, index) => {
		return {
			...item,
			content: summaryData[index],
		};
	});

	return (
		<section>
			<StyledSectionTitle>{SECTION_TITLE.SUMMARY_SECTION_TITLE}</StyledSectionTitle>
			<StyledVODInfoBoxContainer column={MAXIMUM_ITEM_COLUMN.FOUR_COLUMN}>
				{summary &&
					summary.map(({ title, tooltipContent, content }: InfoBoxWithTitleArrayItemOmitKey) => (
						<InfoBoxWithTitle
							title={title}
							tooltipContent={tooltipContent}
							content={content}
							key={title}
							isLoading={isLoading}
						/>
					))}
			</StyledVODInfoBoxContainer>
		</section>
	);
};

export default ReportVODSummarySection;
