import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import commonReducer from '@/store/commonSlice';
import partnerReducer from '@/store/partnerSlice';
import headerReducer from '@/store/headerSlice';
import loadingReducer from '@/store/loadingSlice';
import contractReducer from '@/store/contractSlice';

export const store = configureStore({
	reducer: {
		common: commonReducer,
		partner: partnerReducer,
		header: headerReducer,
		loading: loadingReducer,
		contract: contractReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
