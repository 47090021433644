import React, { useEffect } from 'react';
import CampAssignmentCard from '@/components/common/CampAssignmentCard';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { flexAlignCenter, flexCenter } from '@/styles/styles';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import Loading from '@/components/common/Loading';
import useContractId from '@/hooks/useContractId';
import { AssignmentData } from '@/types/eduCamp/campCommon';
import { useEduCampAssignment } from '@/queries/report/eduCampPath/eduCampPathQuery';
import { EDU_PATH_ASSIGNMENT_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampPath';
import { getSignedCookie } from '@/api/common';

const StyledFeedbackCountSection = styled.section`
	${flexAlignCenter}
`;

const StyledFeedbackText = styled.p`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: ${props => props.theme['gray-7']};
`;

const StyledFeedbackTextCount = styled(StyledFeedbackText)`
	margin-left: 4px;
`;

const StyledLoadingSection = styled.div`
	${flexCenter};
	height: 619px;
`;

const EduPathCampCouponAssignment = () => {
	const dispatch = useDispatch();
	const contractId = useContractId();
	const { assignmentNo } = useParams();

	const { data, isLoading } = useEduCampAssignment(contractId, assignmentNo);
	const assignment = data?.data.assignment ?? EDU_PATH_ASSIGNMENT_INITIAL_DATA.assignment;
	const fetchedAssignmentNo = assignment.no;
	const assignment_replies = data?.data.assignment_replies ?? EDU_PATH_ASSIGNMENT_INITIAL_DATA.assignment_replies;

	const fetchSignedCookie = async (assignmentNo: number) => {
		if (!assignmentNo) return;
		try {
			await getSignedCookie(assignmentNo);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('과제 조회')));
	}, []);

	useEffect(() => {
		const cookieFetch = async () => await fetchSignedCookie(fetchedAssignmentNo);
		cookieFetch();
	}, [fetchedAssignmentNo]);

	return (
		<>
			{!isLoading ? (
				<>
					<CampAssignmentCard isAssignment assignment={assignment} reply={false} />
					<StyledFeedbackCountSection>
						<StyledFeedbackText>피드백</StyledFeedbackText>
						<StyledFeedbackTextCount>{assignment_replies.length}건</StyledFeedbackTextCount>
					</StyledFeedbackCountSection>
					{assignment_replies.map((assignment: AssignmentData) => (
						<CampAssignmentCard
							reply={true}
							isAssignment={false}
							assignment={assignment}
							key={`assignment-replies-${assignment.no}`}
						/>
					))}
				</>
			) : (
				<StyledLoadingSection>
					<Loading />
				</StyledLoadingSection>
			)}
		</>
	);
};

export default EduPathCampCouponAssignment;
