import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export interface propTypes {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
}

const ResetButton = ({ onClick, disabled }: propTypes) => {
	return (
		<Button icon={<ReloadOutlined />} type="text" onClick={onClick} disabled={disabled}>
			필터 초기화
		</Button>
	);
};

export default ResetButton;
