import {
	getCampCouponApplicantListInfo,
	getCampCouponAttendanceStatus,
	getCampCouponListInfo,
	getCampCouponSatisfactionRequest,
	getCampCouponSchedule,
	getCampCouponSummaryInfo,
} from '@/api/report/eduCampCoupon';
import { useQuery } from '@tanstack/react-query';
import {
	CouponApplicantQueryResult,
	CouponSummaryResultType,
	EduCampCouponUserListQueryResult,
	unUsedCouponListDataResult,
} from '@/types/eduCamp/coupon';
import {
	AssignmentDataQueryResult,
	SatisfactionQueryResult,
	ScheduleDataQueryResult,
} from '@/types/eduCamp/campCommon';
import { getPrivateCampAssignment } from '@/api/report/campPrivate';

export const useEduCampCouponQuery = (contractId: number) => {
	return useQuery<EduCampCouponUserListQueryResult>(
		['edu', 'camp', 'coupon', 'user', contractId],
		() => getCampCouponApplicantListInfo({ contractNo: contractId }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampCouponListQuery = (contractId: number) => {
	return useQuery<unUsedCouponListDataResult>(
		['edu', 'camp', 'coupon', 'unused', contractId],
		() => getCampCouponListInfo({ contractNo: contractId }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampSummaryQuery = (contractId: number) => {
	return useQuery<CouponSummaryResultType>(
		['edu', 'camp', 'coupon', 'summary', contractId],
		() => getCampCouponSummaryInfo({ contractNo: contractId }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCampSatisfaction = (contractId: number, page: number) => {
	return useQuery<SatisfactionQueryResult>(
		['edu', 'camp', 'coupon', 'satisfaction', contractId, page],
		() => getCampCouponSatisfactionRequest({ contractNo: contractId, page }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCouponApplicantDetailQuery = (applicantNo: number) => {
	return useQuery<CouponApplicantQueryResult>(
		['edu', 'camp', 'coupon', 'list', 'detail', applicantNo],
		() => getCampCouponAttendanceStatus({ applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCouponScheduleDetailQuery = (applicantNo: number) => {
	return useQuery<ScheduleDataQueryResult>(
		['edu', 'camp', 'coupon', 'list', 'schedule', applicantNo],
		() => getCampCouponSchedule({ applicant_no: applicantNo }),
		{ onError: error => console.error(error) },
	);
};

export const useEduCouponDetailAssignment = (assignment_no: number, applicant_no: number) => {
	return useQuery<AssignmentDataQueryResult>(
		['edu', 'camp', 'coupon', 'assignment', assignment_no, applicant_no],
		() => getPrivateCampAssignment({ assignment_no, applicant_no }),
		{ onError: error => console.error(error) },
	);
};
