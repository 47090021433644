import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import ReportCampExternshipDetailAssignment from '@/components/report/eduCampExternship/organisms/ReportCampExternshipDetailAssignment';
import ReportCampExternshipSchedule from '@/components/report/eduCampExternship/organisms/ReportCampExternshipSchedule';
import ReportCampExternshipDetailSummary from '@/components/report/eduCampExternship/organisms/ReportCampExternshipDetailSummary';
import {
	useEduCampPrivateListDetailQuery,
	useEduCampPrivateListScheduleQuery,
} from '@/queries/report/eduCampPrivate/useEduCampPrivateQuery';
import { EDU_CAMP_APPLICANT_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCommon';

const EduCampExternshipUserListDetail = () => {
	const { applicantNo } = useParams();
	const dispatch = useDispatch();
	const { data: applicantData, isLoading: applicantLoading } = useEduCampPrivateListDetailQuery(Number(applicantNo));
	const applicant = applicantData?.data ?? EDU_CAMP_APPLICANT_INITIAL_DATA;
	const { data: campScheduleData, isLoading: scheduleLoading } = useEduCampPrivateListScheduleQuery(
		Number(applicantNo),
	);
	const campSchedule = campScheduleData?.data;

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('수강 현황')));
	}, []);

	return (
		<>
			<ReportCampExternshipDetailSummary applicantData={applicant} isLoading={applicantLoading} />
			<ReportCampExternshipDetailAssignment applicantData={applicant} isLoading={applicantLoading} />
			<ReportCampExternshipSchedule campSchedule={campSchedule} isLoading={scheduleLoading} />
		</>
	);
};

export default EduCampExternshipUserListDetail;
