import React from 'react';
import { StyledScoreContainer } from '@/components/common/styledComponents';
import ScoreWithRate from '@/components/common/molecules/ScoreWithRate';
import styled from 'styled-components';
import { mixin } from '@/styles/theme';
import RateWithProgressBar from '@/components/common/organisms/RateWithProgressBar';

const StyledSatisfactionSummaryContainer = styled.div`
	background-color: ${props => props.theme['gray-2']};
	padding: 24px;
	${mixin.flexBox()};
	${mixin.alignItems('center')};
	${mixin.justifyContent('center')};

	${StyledScoreContainer} {
		margin-right: 32px;
	}
`;

interface dataTypes {
	value: number;
	percent: number;
}

const SatisfactionSummary = (props: { score: number; isLoading: boolean; data: dataTypes[] }) => {
	return (
		<StyledSatisfactionSummaryContainer>
			<StyledScoreContainer>
				<ScoreWithRate score={props.score} isLoading={props.isLoading} />
			</StyledScoreContainer>
			<StyledScoreContainer>
				{props.data &&
					props.data.map(item => (
						<RateWithProgressBar
							key={item.value}
							value={item.value}
							percent={item.percent}
							isLoading={props.isLoading}
						/>
					))}
			</StyledScoreContainer>
		</StyledSatisfactionSummaryContainer>
	);
};

export default SatisfactionSummary;
